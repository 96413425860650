import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import axiosInstance from 'utils/axiosInstance';
import playerTransfert from 'reducers/schemas/playerTransfert';

export const playerTransfertsSelector = {
    getCurrentUserPendingTransferts: (state) =>
        NormalizedSelector.getByIds(
            state.playerTransferts.playerTransferts,
            state.playerTransferts.currentUserPendingTransferts
        ),
    getPlayerTransfertState: (state) => state.playerTransferts.updatePlayerTransfert,
};

const CURRENT_USER_PLAYER_TRANSFERTS = 'PLAYER_TRANSFERTS';
const CURRENT_USER_PLAYER_TRANSFERTS_SUCCESS = 'PLAYER_TRANSFERTS_SUCCESS';
const CURRENT_USER_PLAYER_TRANSFERTS_ERROR = 'PLAYER_TRANSFERTS_ERROR';

const CREATE_PLAYER_TRANSFERT = 'CREATE_PLAYER_TRANSFERT';
export const CREATE_PLAYER_TRANSFERT_SUCCESS = 'CREATE_PLAYER_TRANSFERT_SUCCESS';
const CREATE_PLAYER_TRANSFERT_ERROR = 'CREATE_PLAYER_TRANSFERT_ERROR';

const UPDATE_PLAYER_TRANSFERT = 'UPDATE_PLAYER_TRANSFERT';
export const UPDATE_PLAYER_TRANSFERT_SUCCESS = 'UPDATE_PLAYER_TRANSFERT_SUCCESS';
const UPDATE_PLAYER_TRANSFERT_ERROR = 'UPDATE_PLAYER_TRANSFERT_ERROR';

export const fetchUserPlayerPendingTransferts = (userId) => (dispatch) => {
    dispatch({ type: CURRENT_USER_PLAYER_TRANSFERTS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/users/${userId}/pending_player_transferts`),
        [playerTransfert],
        [CURRENT_USER_PLAYER_TRANSFERTS_SUCCESS, CURRENT_USER_PLAYER_TRANSFERTS_ERROR],
        dispatch
    );
};

export const createPlayerTransfert = (data) => (dispatch) => {
    dispatch({ type: CREATE_PLAYER_TRANSFERT });
    return dispatchNormalizedPromise(
        axiosInstance.post(`/pending_player_transferts`, data),
        playerTransfert,
        [CREATE_PLAYER_TRANSFERT_SUCCESS, CREATE_PLAYER_TRANSFERT_ERROR],
        dispatch
    );
};

export const updatePlayerPendingTransfert = (transfertId, data) => (dispatch) => {
    dispatch({ type: UPDATE_PLAYER_TRANSFERT });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/pending_player_transferts/${transfertId}`, data),
        playerTransfert,
        [UPDATE_PLAYER_TRANSFERT_SUCCESS, UPDATE_PLAYER_TRANSFERT_ERROR],
        dispatch
    );
};

export default combineReducers({
    playerTransferts: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('playerTransferts'),
        defaultStrategy: Strategies.merge,
    }),
    currentUserPendingTransferts: createRequestReducer(
        [
            null,
            [
                CURRENT_USER_PLAYER_TRANSFERTS_SUCCESS,
                {
                    type: UPDATE_PLAYER_TRANSFERT_SUCCESS,
                    strategy: Strategies.remove,
                    mapResponse: NormalizedActionMap.result,
                },
            ],
            null,
        ],
        { mapResponse: NormalizedActionMap.result }
    ),
    updatePlayerTransfert: createRequestReducer(
        [UPDATE_PLAYER_TRANSFERT, UPDATE_PLAYER_TRANSFERT_SUCCESS, UPDATE_PLAYER_TRANSFERT_ERROR],
        {
            ignoreData: true,
        }
    ),
});
