import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserShareStatus from 'utils/UserShareStatus';
import { connect } from 'react-redux';
import { playersSelector } from 'reducers/players';
import { updateUserPlayer, userPlayersSelector } from 'reducers/userPlayers';
import PlayerCardDisplay from 'containers/players/PlayerCardDisplay';
import PlayerCardPending from 'containers/players/PlayerCardPending';

const styles = (theme) => ({});

class PlayerCard extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleAcceptClick = (event) => {
        const { userPlayer } = this.props;
        this.props.update({ ...userPlayer, status: UserShareStatus.ACCEPTED });
    };

    handleRejectClick = (event) => {
        const { userPlayer } = this.props;
        this.props.update({ ...userPlayer, status: UserShareStatus.REJECTED });
    };

    renderPending() {
        const { player } = this.props;

        return (
            <PlayerCardPending
                player={player}
                onAcceptClick={this.handleAcceptClick}
                onRejectClick={this.handleRejectClick}
            />
        );
    }

    renderCard() {
        const { player, userPlayer, onClick } = this.props;

        return <PlayerCardDisplay player={player} userPlayer={userPlayer} onClick={onClick} />;
    }

    /**
     * Render component
     */
    render() {
        const { userPlayer } = this.props;

        const isPending = userPlayer.status === UserShareStatus.PENDING;
        return (
            <div style={{ width: isPending ? 350 : 250 }}>{isPending ? this.renderPending() : this.renderCard()}</div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const player = playersSelector.getPlayer(state, ownProps.playerId);
    return {
        player,
        userPlayer: userPlayersSelector.getUserPlayer(state, player.related_user),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (userPlayer) => dispatch(updateUserPlayer(userPlayer)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PlayerCard));
