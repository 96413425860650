import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserRForm from 'containers/users/UserRForm';
import { reduxForm, SubmissionError } from 'redux-form';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { getUser, updateUser, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import AccountCancelModal from 'containers/users/AccountCancelModal';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';
import { displayAlert } from 'reducers/alerts';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {};

const Form = reduxForm({
    form: 'user',
    validate: UserRForm.validate,
    enableReinitialize: true, //Avoid sending two times the token
    onSubmit: (values, dispatch, props) => {
        return dispatch(updateUser(values))
            .then(() => {
                const message =
                    'Account updated !' +
                    (props.initialValues.email !== values.email
                        ? ' You will receive a confirmation email on ' + values.email
                        : '');
                dispatch(displayAlert(message));
            })
            .catch((error) => {
                if (error.isException(Exceptions.ValidationException)) {
                    const errors = validationErrorToSubmissionError(error.getData());
                    throw new SubmissionError(errors);
                }
                return Promise.reject(error);
            });
    },
})(UserRForm);

class AccountPage extends React.Component {
    /**
     * Component state
     */
    state = {
        cancelSubscriptionOpen: false,
        cancelOpen: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getUser(this.props.user.id);
    }

    handleCancelClick = () => {
        this.setState({ cancelOpen: true });
    };

    handleCancelClose = () => {
        this.setState({ cancelOpen: false });
    };

    handleCancelSubscriptionClick = () => {
        this.setState({ cancelSubscriptionOpen: true });
    };

    handleCancelSubscriptionClose = () => {
        this.setState({ cancelSubscriptionOpen: false });
    };

    /**
     * Render component
     */
    render() {
        const { classes, user } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            <Link to={`/`}>
                                <Button className={classes.backButton}>
                                    <KeyboardArrowLeft /> {getTranslation("home")}
                                </Button>
                            </Link>
                        </div>
                        <Card>
                            {/*<SubscriptionCancelModal*/}
                            {/*    open={this.state.cancelSubscriptionOpen}*/}
                            {/*    onRequestClose={this.handleCancelSubscriptionClose}*/}
                            {/*/>*/}
                            <AccountCancelModal open={this.state.cancelOpen} onRequestClose={this.handleCancelClose} />
                            <CardContent>
                                {/*<Form initialValues={user} paymentForm={user.is_free === false} />*/}
                                <Form initialValues={user} paymentForm={false} buttonText={getTranslation("update")}/>
                                <br />
                                <Typography variant="h6" gutterBottom>
                                    {getTranslation("user_cancel_account")}
                                </Typography>
                                <Typography color="textSecondary">
                                    {getTranslation("user_cancel_account_desc")}
                                </Typography>
                                <br />
                                <Button onClick={this.handleCancelClick}>{getTranslation("user_cancel_my_account")}</Button>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (userId) => dispatch(getUser(userId)),
    };
};

export default withStyles(styles)(connect(mapStateTopProps, mapDispatchToProps)(AccountPage));
