import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchUserPlayers, playersSelector } from 'reducers/players';
import { connect } from 'react-redux';
import { usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { ContentComponent } from 'containers/ContentComponents';
import FullWidthLoader from 'ui/base/FullWidthLoader';

const styles = {};

class SubscriptionCancelMessage extends React.Component {
    /**
     * Component state
     */
    state = {};

    componentDidMount() {
        const {
            currentUser: { id },
        } = this.props;
        this.props.fetchPlayers(id);
    }

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, currentUser, players, isFetching } = this.props;
        return (
            <div>
                {isFetching && <FullWidthLoader />}
                {!isFetching && (
                    <ContentComponent
                        componentName={'User.CancelAccount'}
                        players={players.filter((player) => player.main_user_id === currentUser.id)}
                    />
                )}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const playerState = playersSelector.getUserPlayersState(state);
    const players = playersSelector.getUserPlayers(state);
    return {
        currentUser: usersSelector.getCurrentUser(state),
        isFetching: !players.length && RequestStateHelper.isLoading(playerState),
        players,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlayers: (userId) => dispatch(fetchUserPlayers(userId)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SubscriptionCancelMessage));
