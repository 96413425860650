import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { connect } from 'react-redux';
import { updateUserPlayer, userPlayersSelector } from 'reducers/userPlayers';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class PlayerStarButton extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleClick = () => {
        const {
            userPlayer: { id, is_favorite },
        } = this.props;
        this.props.updateUserPlayer({ id, is_favorite: !is_favorite });
    };

    /**
     * Render component
     */
    render() {
        const {
            classes,
            favorite,
            userPlayer: { is_favorite },
            style,
        } = this.props;
        const StartComponent = is_favorite ? Star : StarBorder;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div style={style}>
                        <Button onClick={this.handleClick} color={is_favorite ? 'primary' : 'default'}>
                            <StartComponent style={{ marginBottom: 2 }} />
                            {is_favorite ? getTranslation("player_star_button_favorite") : getTranslation("player_star_button_mark_favorite")}
                        </Button>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    return {
        userPlayer: userPlayersSelector.getUserPlayer(state, ownProps.userPlayerId),
    };
};

const mapDispatchToProps = (dispatch) => {
    //TODO: update
    return {
        updateUserPlayer: (data) => dispatch(updateUserPlayer(data)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PlayerStarButton));
