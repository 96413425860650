import { combineReducers } from 'redux';

/**
 *  Selectors
 */

const ALERT_DISPLAY = 'ALERT_DISPLAY';

export const displayAlert = (message) => {
    return { type: ALERT_DISPLAY, message };
};

export const alertsSelector = {
    getCurrent: (state) => state.alerts.current,
};

export default combineReducers({
    current: (previousState, action) => {
        switch (action.type) {
            case ALERT_DISPLAY:
                return {
                    date: new Date(),
                    message: action.message,
                };
            default:
                return null;
        }
    },
});
