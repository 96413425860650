import React from 'react';
import { Button } from '@material-ui/core';
import mapError from 'ui/base/mapError';
import DefaultError from 'ui/base/DefaultError';

class FileField extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleFileChange = (event) => {
        event.preventDefault();
        this.props.input.onChange(event.target.files[0]);
    };

    /**
     * Because we can't control file input
     */
    componentDidUpdate() {
        if (!this.props.input.value && this.fileInput) this.fileInput.value = '';
    }

    /**
     * Render component
     */
    render() {
        const {
            input: { value, ...input },
            meta,
            label,
            ...otherProps
        } = this.props;

        const errorProps = mapError(this.props);

        return (
            <div>
                <input
                    id="file"
                    {...input}
                    {...otherProps}
                    ref={(input) => {
                        this.fileInput = input;
                    }}
                    onChange={this.handleFileChange}
                    style={{ display: 'none' }}
                />
                <label htmlFor="file">
                    <Button color="primary" variant="outlined" component="span">
                        {value ? value.name : label}
                    </Button>
                    {errorProps.error && <DefaultError>{errorProps.helperText}</DefaultError>}
                </label>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default FileField;
