import * as actions from 'redux-form';
import { reduxForm, SubmissionError } from 'redux-form';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { updatePlayerObjectAsset } from 'reducers/assets';
import { compose } from 'recompose';
import SessionRForm from 'containers/sessions/SessionRForm';
import { updateUserSession } from 'reducers/sessions';

export default compose(
    reduxForm({
        form: 'session_edit',
        validate: SessionRForm.validate,
        onSubmit: (values, dispatch, ownProps) => {
            const {
                initialValues: { id },
            } = ownProps;

            return dispatch(updateUserSession(id, values))
                .then((result) => {
                    ownProps.onEdited && ownProps.onEdited();
                    dispatch(actions.reset('session_edit'));
                    return result;
                })
                .catch((error) => {
                    if (error.isException(Exceptions.ValidationException)) {
                        const errors = validationErrorToSubmissionError(error.getData());
                        throw new SubmissionError(errors);
                    }
                    throw new SubmissionError({ _error: error.getMessage() });
                });
        },
    })
)(SessionRForm);
