import React, { useState } from 'react';
import { extractMeta, extractThumbnailUrl, getEmbedInfo } from 'utils/embedInfo';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import VideoPreview from 'containers/videoChannels/VideoPreview';
import { TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    base: {
        display: 'flex',
        marginBottom: 15,
    },
    input: {
        flex: 1,
        marginRight: 10,
    },
};

function VideoLinkInput({ classes, value: url, onChange: setUrl, onConfirm, error }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [embedError, setEmbedError] = useState(false);
    const meta = data && extractMeta(data);
    const thumbnailUrl = data && extractThumbnailUrl(data);
    const currentError = embedError || error;
    const onSubmitClick = () => {
        if (url && !loading) {
            setLoading(true);
            setEmbedError(null);
            getEmbedInfo(url)
                .then((v) => (v ? setData(v) : setEmbedError(true)))
                .finally(() => setLoading(false));
        }
    };
    const handleUrlChange = (e) => {
        setData(null);
        setUrl(e.target.value);
    };

    const handleCancel = (e) => {
        setData(null);
        setUrl('');
    };

    const handleConfirm = (e) => {
        onConfirm({
            title: meta.title,
            description: meta.description,
            thumbnailUrl,
        });
    };

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <div className={classes.base}>
                        <TextField
                            className={classes.input}
                            name="url"
                            type="text"
                            label={getTranslation("asset_editor_video_channel_link_label")}
                            helperText={
                                error || getTranslation("asset_editor_video_channel_link_desc")
                            }
                            variant="outlined"
                            error={currentError}
                            value={url}
                            onChange={handleUrlChange}
                        />
                        <div>
                            {!loading && !data && (
                                <Button variant="outlined" color="secondary" onClick={onSubmitClick}>
                                    {getTranslation("asset_editor_video_channel_link_preview")}
                                </Button>
                            )}
                            {!loading && data && (
                                <Button
                                    style={{ marginRight: 5 }}
                                    variant="contained"
                                    color="secondary"
                                    disabled={!!currentError}
                                    onClick={handleConfirm}>
                                    {getTranslation("asset_editor_video_channel_add")}
                                </Button>
                            )}
                            {!loading && data && (
                                <Button variant="contained" color="default" onClick={handleCancel}>
                                    {getTranslation("cancel")}
                                </Button>
                            )}
                            {loading && <FullWidthLoader />}
                        </div>
                    </div>
                    {data && <VideoPreview title={meta.title} description={meta.description} thumbnailUrl={thumbnailUrl} />}
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}

VideoLinkInput.propTypes = {};

VideoLinkInput.defaultProps = {};
export default compose(withStyles(styles))(VideoLinkInput);
