import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchPlayerSessions, sessionsSelector } from 'reducers/sessions';
import { connect } from 'react-redux';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import {
    Table,
    TableBody,
    TablePagination,
    CardContent,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import SessionDuration from 'containers/sessions/SessionDuration';
import moment from 'moment';
import SessionIcon from 'containers/sessions/SessionIcon';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import SessionEditForm from 'containers/sessions/SessionEditForm';
import EditIcon from '@material-ui/icons/Edit';
import { usersSelector } from 'reducers/users';
import { playersSelector } from 'reducers/players';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    dialog: {
        width: '60%',
    },
};

class PlayerSessionsList extends React.Component {
    /**
     * Component state
     */
    state = {
        editedSession: null,
        rowsPerPage: 5,
        page: 0,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { playerId } = this.props;
        this.props.fetchSessions(playerId);
    }

    handleSessionEdited = (event) => {
        this.setState({ editedSession: null });
    };

    renderEditModal() {
        const { editedSession } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    paper: classes.dialog,
                }}
                open={!!editedSession}
                onClose={this.handleSessionEdited}>
                <DialogTitle>Edit session</DialogTitle>
                <DialogContent>
                    <SessionEditForm onEdited={this.handleSessionEdited} initialValues={editedSession} />
                </DialogContent>
            </Dialog>
        );
    }

    handleEditClick = (session) => {
        this.setState({ editedSession: session });
    };

    renderSessions() {
        const { classes, sessions, currentUserId, isCurrentUserPlayerParent } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <React.Fragment>
                        {!!sessions.length && (
                            <div>
                                <Table className={classes.table}>
                                    <colgroup>
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="45%" />
                                        <col width="10%" />
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{getTranslation("player_sessions_date")}</TableCell>
                                            <TableCell>{getTranslation("player_sessions_duration")}</TableCell>
                                            <TableCell>{getTranslation("player_sessions_with")}</TableCell>
                                            <TableCell>{getTranslation("player_sessions_comment")}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sessions.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((session) => {
                                            return (
                                                <TableRow key={session.id}>
                                                    <TableCell>
                                                        {moment.parseZone(session.local_end).format('L')}
                                                        {getTranslation("player_sessions_at")}
                                                        {moment.parseZone(session.local_end).format('LT')}
                                                    </TableCell>
                                                    <TableCell>
                                                        <SessionDuration duration={session.duration} /> {getTranslation("minutes")}
                                                    </TableCell>
                                                    <TableCell>{session.user.display_name}</TableCell>
                                                    <TableCell>
                                                        <SessionIcon name={session.rating} /> {session.comment}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(session.user.id === currentUserId || isCurrentUserPlayerParent) && (
                                                            <IconButton onClick={() => this.handleEditClick(session)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    labelRowsPerPage={getTranslation("player_sessions_row_per_page")}
                                    labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} ${getTranslation("player_sessions_row_of")} ${count !== -1 ? count : `${getTranslation("player_sessions_row_more_than")} ${to}`}` }}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={sessions.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={(_, page) => this.setState({ page })}
                                    onChangeRowsPerPage={(e) => this.setState({ rowsPerPage: parseInt(e.target.value, 10) })}
                                />
                            </div>
                        )}
                        {!sessions.length && (
                            <CardContent>
                                <Typography align="center">{getTranslation("player_sessions_list_empty")}</Typography>
                            </CardContent>
                        )}
                    </React.Fragment>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>
                {this.renderEditModal()}
                {isLoading && <FullWidthLoader />}
                {!isLoading && this.renderSessions()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        playerId: PropTypes.number.isRequired,
    };
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const sessionsState = sessionsSelector.getCurrentPlayerSessionState(state);
    const currentUser = usersSelector.getCurrentUser(state);
    const player = playersSelector.getPlayer(state, ownProps.playerId);

    return {
        sessions: sessionsSelector.getCurrentPlayerSessions(state),
        isCurrentUserPlayerParent: player.main_user_id === currentUser.id,
        isLoading: RequestStateHelper.isLoading(sessionsState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSessions: (playerId) => {
            dispatch(fetchPlayerSessions(playerId));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PlayerSessionsList));
