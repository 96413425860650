import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { logout } from 'reducers/users';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {};

class WelcomeMessage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, logout } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Button color="inherit" onClick={logout}>
                        {getTranslation("menu_sign_out")}
                    </Button>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapDispatchToProps = (displatch) => {
    return {
        logout: () => {
            displatch(logout());
        },
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(WelcomeMessage));
