/* 
Authors: Gaetan Senelle, Niklaas Cotta
Last modified: 10/13/2023
Desc: Object titles
*/

function ObjectTypeTitle({ classes, type, ...otherProps }) {
    switch (type) {
        case 'image':
            return 'image';
        case 'audio':
            return 'audio';
        case 'video_channel':
            return 'video channel';
        case 'paintings':
            return 'painting';
        case 'music_tracks':
            return 'music track';
        default:
            return null;
    }
}

export default ObjectTypeTitle;