import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchInstitutionUsers, usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { connect } from 'react-redux';
import { Typography, Button, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import UserSessionsList from 'containers/sessions/UserSessionsList';
import InstitutionUserDeleteButton from 'containers/users/InstitutionUserDeleteButton';
import DeleteIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {};

class InstitutionUsersList extends React.Component {
    /**
     * Component state
     */
    state = {
        sessionListModalUserId: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchUsers(this.props.institutionId);
    }

    handleUserSessionsClick = (userId) => {
        this.setState({ sessionListModalUserId: userId });
    };

    handleUserSessionsModalRequestClose = (event) => {
        this.setState({ sessionListModalUserId: null });
    };

    renderList() {
        const { users, institutionId, noDataText } = this.props;
        const { sessionListModalUserId } = this.state;
        if (!users.length) {
            return (
                <div style={{ padding: '20px 0px' }}>
                    <Typography align="center">{noDataText}</Typography>
                </div>
            );
        }
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            onClose={this.handleUserSessionsModalRequestClose}
                            open={sessionListModalUserId !== null}>
                            <DialogTitle>{getTranslation("user_sessions_title")}</DialogTitle>
                            <DialogContent>
                                <UserSessionsList userId={sessionListModalUserId} institutionId={institutionId} />
                            </DialogContent>
                        </Dialog>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{getTranslation("email")}</TableCell>
                                    <TableCell>{getTranslation("first_name")}</TableCell>
                                    <TableCell>{getTranslation("last_name")}</TableCell>
                                    <TableCell style={{ width: 150 }} />
                                    <TableCell style={{ width: 40 }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => {
                                    return (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.first_name}</TableCell>
                                            <TableCell>{user.last_name}</TableCell>
                                            <TableCell>
                                                <Button onClick={() => this.handleUserSessionsClick(user.id)}>
                                                    {getTranslation("manage_institution_care_givers_sessions", `(${user.sessions_stat ? user.sessions_stat.count : 0})` )}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                {user.id !== institutionId && (
                                                    <InstitutionUserDeleteButton
                                                        component={IconButton}
                                                        institutionId={institutionId}
                                                        userId={user.id}>
                                                        <DeleteIcon />
                                                    </InstitutionUserDeleteButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>
                {isLoading && <FullWidthLoader />}
                {!isLoading && this.renderList()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        noDataText: PropTypes.string,
    };
    static defaultProps = {
        noDataText: 'Institution empty',
    };
}

const getContain = ({ institutionId }) => {
    return {
        sessions_stat: {
            contain: 'SessionsStat',
            institutionId,
        },
    };
};
const mapStateToProps = (state, ownProps) => {
    const usersState = usersSelector.getInstitutionUsersState(state);

    return {
        users: usersSelector.getInstitutionUsers(state, getContain(ownProps)),
        isLoading: RequestStateHelper.isLoading(usersState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: (institutionId) => {
            dispatch(fetchInstitutionUsers(institutionId, getContain({ institutionId })));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstitutionUsersList));
