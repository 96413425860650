import React from 'react';
import { replacePlayerObjectAsset } from 'reducers/assets';
import { connect } from 'react-redux';
import BookSelect from "./BookSelect";

function BookUpdateContainer({
    initialBookIds ,
    replacePlayerObjectAsset,
    player: { id: playerId },
    object: { unity_id: unityId },
}) {
    const handleConfirm = (ids) => {
        const assets = ids.map((id) => ({ type: 'book', book_id: id }));
        replacePlayerObjectAsset(playerId, unityId, assets);
    };
    return <BookSelect playerId={playerId} initialBookIds={initialBookIds} onConfirm={handleConfirm} />;
}

BookUpdateContainer.propTypes = {};
BookUpdateContainer.defaultProps = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        replacePlayerObjectAsset: (playerId, unityId, assets) => {
            dispatch(replacePlayerObjectAsset(playerId, unityId, assets));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookUpdateContainer);
