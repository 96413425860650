import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { reduxForm, SubmissionError } from 'redux-form';
import { updatePlayer } from 'reducers/players';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import { pick } from 'lodash-es';
import { Typography } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { CardContent } from '@material-ui/core';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'
import PlayerNextSessionDisplayRForm from "containers/players/PlayerNextSessionDisplayRForm";

const styles = {
    profile: {
        '& > *': {
            marginBottom: 25,
        },
    },
};

const PlayerNextSessionEditForm = reduxForm({
    form: 'editSession',
    /**
     * Allow to get updated values from the store from initialValues
     */
    enableReinitialize: true,
    onSubmit: (values, dispatch) => {
        values = pick(values, [
            'id',
            'avatar_gender',
            'lang'
        ]);

        return dispatch(updatePlayer(values)).catch((error) => {
            if (error.isException(Exceptions.ValidationException)) {
                const errors = validationErrorToSubmissionError(error.getData());
                throw new SubmissionError(errors);
            }
            throw new SubmissionError({ _error: error.getMessage() });
        });
    },
})(PlayerNextSessionDisplayRForm);

class PlayerNextSessionPage extends React.Component {
    /**
     * Properties initialization
     */
    static propTypes = {};
    static defaultProps = {};
    /**
     * Component state
     */
    state = {
        addCaregiverModalOpen: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderPlayerNextSession() {
        const { classes, player } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.profile}>
                        <Typography variant="h6">{getTranslation("player_next_session_title")}</Typography>
                        <PlayerNextSessionEditForm initialValues={player} />
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        return (
            <CardContent>
                <Row>
                    <Col style={{ padding: '0 15px', width: 400 }}>{this.renderPlayerNextSession()}</Col>
                </Row>
            </CardContent>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: usersSelector.getCurrentUser(state),
        playerUpdatedByUser: usersSelector.getUser(state, ownProps.player.updated_by),
    };
};

export default withStyles(styles)(connect(mapStateToProps)(PlayerNextSessionPage));
