import React from 'react';
import { Link } from 'react-router-dom';

function AppLink(props) {
    const { styled, to, ...otherProps } = props;
    const style = styled ? { textDecoration: 'none', color: '#11abff' } : { textDecoration: 'none', color: 'inherit' };
    if (to.startsWith('http')) {
        return <a href={to} style={style} {...otherProps} />;
    } else {
        return <Link to={to} style={style} {...otherProps} />;
    }
}

AppLink.propTypes = {};

AppLink.defaultProps = {};
export default AppLink;
