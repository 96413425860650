import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import StripeCheckout from 'react-stripe-checkout';
import MentiaLogo from 'images/logo.svg';

const styles = {};

class AddTokenButton extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, ...otherProps } = this.props;

        return (
            <StripeCheckout
                panelLabel={'Enter'}
                image={MentiaLogo}
                // eslint-disable-next-line no-undef
                stripeKey={STRIPE_KEY}
                allowRememberMe={false}
                {...otherProps}
            />
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(AddTokenButton);
