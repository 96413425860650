import React, { Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { deleteUserPlayer, userPlayersSelector } from 'reducers/userPlayers';
import { usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { push } from 'connected-react-router';
import { fetchPlayerUsers } from '../../reducers/users';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 10px',
    },
    removeBtn: {
        fontSize: '0.7rem',
        padding: '0 4px 0 0',
    },
};

class ExitCareCircleButton extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleClick = (event) => {
        this.setState({ open: true });
    };

    handleRequestClose = (event) => {
        if (this.props.isDeleting) return;
        this.setState({ open: false });
    };

    handleAccept = (event) => {
        const userPlayerId = this.props.user.id + '_' + this.props.player.id;
        this.props.exitCareCircle(userPlayerId).then(() => {
            this.props.fetchPlayerUsers(this.props.player.id);
            this.props.isCurrentUser ? this.props.redirect('/') : this.setState({ open: false });
        });
    };

    renderDialog() {
        const { isDeleting, classes, isCurrentUser, user } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Dialog open={this.state.open} onClose={this.handleRequestClose}>
                        <DialogTitle>{isCurrentUser ? getTranslation("player_modal_exit_the_care_circle") : getTranslation("player_modal_remove_from_care_circle")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography component="span">
                                    {isCurrentUser ? (
                                        <Fragment>
                                            {getTranslation("player_modal_confirm_exit_message")} <br />
                                            {getTranslation("player_modal_confirm_exit_warning_message")} <br />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {getTranslation("player_modal_confirm_remove_message", user.email)}{' '}
                                            <br />
                                            {getTranslation("player_modal_confirm_remove_warning_message")} <br />
                                        </Fragment>
                                    )}
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button onClick={this.handleRequestClose} color="primary" disabled={isDeleting}>
                                {getTranslation("cancel")}
                            </Button>
                            <Button onClick={this.handleAccept} color="primary" disabled={isDeleting}>
                                {isCurrentUser ? getTranslation("player_modal_confirm_exit") : getTranslation("player_modal_confirm_remove")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { isCurrentUser, classes } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <span>
                        {this.renderDialog()}
                        <Button onClick={this.handleClick} className={classes.removeBtn}>
                            <ExitIcon /> {isCurrentUser ? getTranslation("exit_care_circle") : getTranslation("remove_from_care_circle")}
                        </Button>
                    </span>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const deleteState = userPlayersSelector.getUserPlayerDeleteState(state);
    return {
        isDeleting: RequestStateHelper.isLoading(deleteState),
        isCurrentUser: usersSelector.getCurrentUser(state).id === ownProps.user.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        exitCareCircle: (userPlayerId) => dispatch(deleteUserPlayer(userPlayerId)),
        redirect: (location) => dispatch(push(location)),
        fetchPlayerUsers: (playerId) => {
            dispatch(fetchPlayerUsers(playerId));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ExitCareCircleButton));
