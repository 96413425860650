import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Field } from 'redux-form';
import { Button } from '@material-ui/core';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class AssetRFormDescription extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div style={{ padding: '0 10px' }}>
                            <Field
                                type="text"
                                label={getTranslation("description")}
                                name="description"
                                helperText={getTranslation("asset_form_description_helper")}
                                variant="outlined"
                                autoComplete="off"
                                autoFocus
                                component={ValidatedTextField}
                                disabled={submitting}
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                            />
                        </div>
                        <br />
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} />
                            <Button color="primary" onClick={handleSubmit} disabled={submitting}>
                                {getTranslation("save")}
                            </Button>
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(AssetRFormDescription);
