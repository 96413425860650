import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import player from 'reducers/schemas/player';
import { USERPLAYER_UPDATE_SUCCESS } from 'reducers/userPlayers';
import { values, without } from 'lodash-es';
import UserShareStatus from 'utils/UserShareStatus';
import { objectToFormData } from 'utils/FormUtils';

/**
 *  Selectors
 */

export const playersSelector = {
    getPlayer: (state, id) => NormalizedSelector.getById(state.players.players, id),

    getUserPlayers: (state) => NormalizedSelector.getByIds(state.players.players, state.players.currentUserPlayers),
    getUserPlayersState: (state) => state.players.currentUserPlayers,
    getInstitutionPlayers: (state) =>
        NormalizedSelector.getByIds(state.players.players, state.players.institutionPlayers),
    getInstitutionPlayersState: (state) => state.players.institutionPlayers,
    getInstitutionPlayersWithStats: (state, parameterizedContain) =>
        NormalizedSelector.getByIds(state.players.players, state.players.institutionPlayers, parameterizedContain),

    getCurrentPlayer: (state) => NormalizedSelector.getById(state.players.players, state.players.currentPlayer),
    getCurrentPlayerState: (state) => state.players.currentPlayer,
    getPlayerDeleteState: (state) => state.players.playerDelete,

    getCurrentPlayerPhotoState: (state) => state.players.currentPlayerPhoto,
};

/**
 * Actions
 */

const USER_PLAYERS = 'USER_PLAYERS';
const USER_PLAYERS_SUCCESS = 'USER_PLAYERS_SUCCESS';
const USER_PLAYERS_ERROR = 'USER_PLAYERS_ERROR';

const PLAYER = 'PLAYER';
const PLAYER_SUCCESS = 'PLAYER_SUCCESS';
const PLAYER_ERROR = 'PLAYER_ERROR';

const PLAYER_CREATE = 'PLAYER_CREATE';
export const PLAYER_CREATE_SUCCESS = 'PLAYER_CREATE_SUCCESS';
const PLAYER_CREATE_ERROR = 'PLAYER_CREATE_ERROR';

const PLAYER_DELETE = 'PLAYER_DELETE';
export const PLAYER_DELETE_SUCCESS = 'PLAYER_DELETE_SUCCESS';
const PLAYER_DELETE_ERROR = 'PLAYER_DELETE_ERROR';

const PLAYER_SHARE = 'PLAYER_SHARE';
export const PLAYER_SHARE_SUCCESS = 'PLAYER_SHARE_SUCCESS';
const PLAYER_SHARE_ERROR = 'PLAYER_SHARE_ERROR';

const PLAYER_UPDATE = 'PLAYER_UPDATE';
export const PLAYER_UPDATE_SUCCESS = 'PLAYER_UPDATE_SUCCESS';
const PLAYER_UPDATE_ERROR = 'PLAYER_UPDATE_ERROR';

const PLAYER_UPLOAD_PHOTO = 'PLAYER_UPLOAD_PHOTO';
const PLAYER_UPLOAD_PHOTO_SUCCESS = 'PLAYER_UPLOAD_PHOTO_SUCCESS';
const PLAYER_UPLOAD_PHOTO_ERROR = 'PLAYER_UPLOAD_PHOTO_ERROR';

const PLAYER_FAVORITE = 'PLAYER_FAVORITE';
const PLAYER_FAVORITE_SUCCESS = 'PLAYER_FAVORITE_SUCCESS';
const PLAYER_FAVORITE_ERROR = 'PLAYER_FAVORITE_ERROR';

const FETCH_INSTITUTION_PLAYERS = 'FETCH_INSTITUTION_PLAYERS';
const FETCH_INSTITUTION_PLAYERS_SUCCESS = 'FETCH_INSTITUTION_PLAYERS_SUCCESS';
const FETCH_INSTITUTION_PLAYERS_ERROR = 'FETCH_INSTITUTION_PLAYERS_ERROR';

const FETCH_INSTITUTION_PLAYERS_WITH_STATS = 'FETCH_INSTITUTION_PLAYERS_WITH_STATS';
const FETCH_INSTITUTION_PLAYERS_WITH_STATS_SUCCESS = 'FETCH_INSTITUTION_PLAYERS_WITH_STATS_SUCCESS';
const FETCH_INSTITUTION_PLAYERS_WITH_STATS_ERROR = 'FETCH_INSTITUTION_PLAYERS_WITH_STATS_ERROR';

/**
 * Action creators
 */

export const fetchUserPlayers = (userId) => (dispatch) => {
    dispatch({ type: USER_PLAYERS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/users/${userId}/players`, { params: { all: true } }),
        [player],
        [USER_PLAYERS_SUCCESS, USER_PLAYERS_ERROR],
        dispatch
    );
};

export const fetchUserPlayer = (userId, playerId) => (dispatch) => {
    dispatch({ type: PLAYER });
    return dispatchNormalizedPromise(
        axiosInstance.get(`users/${userId}/players/${playerId}`, {
            params: {
                'contain[]': 'UpdatedBy',
            },
        }),
        player,
        [PLAYER_SUCCESS, PLAYER_ERROR],
        dispatch
    );
};

export const updatePlayer = (data, playerId) => (dispatch) => {
    playerId = playerId || data.id;

    dispatch({ type: PLAYER_UPDATE });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/players/${playerId}`, data),
        player,
        [PLAYER_UPDATE_SUCCESS, PLAYER_UPDATE_ERROR],
        dispatch
    );
};

export const deletePlayer = (playerId) => (dispatch) => {
    dispatch({
        type: PLAYER_DELETE,
        id: playerId,
    });
    return dispatchNormalizedPromise(
        axiosInstance.delete(`/players/${playerId}`),
        null,
        [PLAYER_DELETE_SUCCESS, PLAYER_DELETE_ERROR],
        dispatch
    );
};

export const sharePlayer = (data, playerId) => (dispatch) => {
    dispatch({ type: PLAYER_SHARE });
    return dispatchNormalizedPromise(
        axiosInstance.post(`/players/${playerId}/share`, data),
        player,
        [PLAYER_SHARE_SUCCESS, PLAYER_SHARE_ERROR],
        dispatch,
        {
            playerId,
        }
    );
};

export const createUserPlayer = (userId, data) => (dispatch) => {
    dispatch({ type: PLAYER_CREATE });

    data = objectToFormData(data);
    const url = window.location.href;
    // If on french cluster, language === 2 (FR) else language === 0 (EN)
    const language = url.toLowerCase().includes("fr") ? 2 : 0;
    data.append("lang", JSON.stringify(language));

    return dispatchNormalizedPromise(
        axiosInstance.post(`/users/${userId}/players`, data),
        player,
        [PLAYER_CREATE_SUCCESS, PLAYER_CREATE_ERROR],
        dispatch
    );
};

export const updatePlayerPhoto = (file, playerId) => (dispatch) => {
    dispatch({ type: PLAYER_UPLOAD_PHOTO });

    const data = new FormData();
    data.append('photo', file);
    data.append('_method', 'PUT');

    return dispatchNormalizedPromise(
        axiosInstance.post(`/players/${playerId}`, data),
        player,
        [PLAYER_UPLOAD_PHOTO_SUCCESS, PLAYER_UPLOAD_PHOTO_ERROR],
        dispatch
    );
};

export const fetchInstitutionPlayers = (userId) => (dispatch) => {
    dispatch({ type: FETCH_INSTITUTION_PLAYERS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/institutions/${userId}/players`, { params: { all: true, own: true } }),
        [player],
        [FETCH_INSTITUTION_PLAYERS_SUCCESS, FETCH_INSTITUTION_PLAYERS_ERROR],
        dispatch
    );
};

export const fetchInstitutionPlayersWithStats = (userId, parameterizedContain) => (dispatch) => {
    const operationId = Math.random();
    dispatch({ type: FETCH_INSTITUTION_PLAYERS_WITH_STATS, operationId });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/institutions/${userId}/players`, {
            params: {
                all: true,
                own: true,
                parameterizedContain,
            },
        }),
        [player],
        [FETCH_INSTITUTION_PLAYERS_WITH_STATS_SUCCESS, FETCH_INSTITUTION_PLAYERS_WITH_STATS_ERROR],
        dispatch,
        { operationId },
        parameterizedContain
    );
};

export default combineReducers({
    players: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('players'),
        defaultStrategy: Strategies.merge,
    }),
    currentUserPlayers: createRequestReducer(
        [
            USER_PLAYERS,
            [
                USER_PLAYERS_SUCCESS,
                {
                    type: PLAYER_CREATE_SUCCESS,
                    strategy: Strategies.append,
                },
                {
                    type: USERPLAYER_UPDATE_SUCCESS,
                    mapResponse: NormalizedActionMap.entities('userPlayers'),
                    strategy: (data, nextData) => {
                        const userPlayer = values(nextData)[0];
                        if (userPlayer.status === UserShareStatus.ACCEPTED) return data;
                        return without(data, userPlayer.player_id);
                    },
                },
            ],
            USER_PLAYERS_ERROR,
        ],
        { mapResponse: NormalizedActionMap.result }
    ),
    institutionPlayers: createRequestReducer(
        [
            [FETCH_INSTITUTION_PLAYERS, FETCH_INSTITUTION_PLAYERS_WITH_STATS],
            [
                FETCH_INSTITUTION_PLAYERS_SUCCESS,
                FETCH_INSTITUTION_PLAYERS_WITH_STATS_SUCCESS,
                {
                    type: PLAYER_CREATE_SUCCESS,
                    strategy: Strategies.append,
                },
            ],
            [FETCH_INSTITUTION_PLAYERS_ERROR, FETCH_INSTITUTION_PLAYERS_WITH_STATS_ERROR],
        ],
        {
            mapResponse: NormalizedActionMap.result,
            useOperationId: true,
        }
    ),
    currentPlayer: createRequestReducer([PLAYER, PLAYER_SUCCESS, PLAYER_ERROR], {
        mapResponse: NormalizedActionMap.result,
    }),
    currentPlayerPhoto: createRequestReducer(
        [PLAYER_UPLOAD_PHOTO, PLAYER_UPLOAD_PHOTO_SUCCESS, PLAYER_UPLOAD_PHOTO_ERROR],
        { ignoreData: true }
    ),
    playerDelete: createRequestReducer([PLAYER_DELETE, PLAYER_DELETE_SUCCESS, PLAYER_DELETE_ERROR], {
        ignoreData: true,
    }),
});
