import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button, FormControl, Grid, Input, InputLabel, MenuItem, Typography } from '@material-ui/core';
import ValidatedSelectField from 'ui/base/ValidatedSelectField';
import DefaultError from 'ui/base/DefaultError';
import FileField from 'ui/base/FileField';
import ValidatedDateField from 'ui/base/ValidatedDateField';
import MultiSelectField from '../../ui/base/MultiSelectField';
import { fetchInstitutionUsers, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    form: {
        ' & > *': {
            marginBottom: 15,
        },
    },
    title: {
        marginTop: 20,
    },
};

class PlayerEditRForm extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { user } = this.props;
        if (user.is_institution) {
            this.props.fetchInstitutionCaregivers(this.props.user.id);
        }
    }

    /**
     * Render component
     */
    render() {
        const {
            classes,
            handleSubmit,
            submitting,
            buttonText,
            withPhoto,
            termsOfService,
            message,
            error,
            invalid,
            institutionCaregivers,
            user,
        } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div className={classes.form}>
                            <DefaultError>{error}</DefaultError>
                            <Field
                                type="text"
                                name="nickname"
                                label={getTranslation("player_edit_display_informal_name_eg")}
                                component={ValidatedTextField}
                                autoFocus
                                required
                                fullWidth
                                helperText={getTranslation("player_edit_display_informal_name_desc")}
                                inputProps={{ maxLength: 30 }}
                            />
                            <Field type="text" name="first_name" label={getTranslation("player_edit_display_first_name")} component={ValidatedTextField} fullWidth />
                            <Field
                                type="text"
                                name="middle_name"
                                label={getTranslation("player_edit_display_middle_name")}
                                component={ValidatedTextField}
                                fullWidth
                            />
                            <Field type="text" name="last_name" label={getTranslation("player_edit_display_last_name")} component={ValidatedTextField} fullWidth />
                            <FormControl fullWidth>
                                <InputLabel htmlFor="gender">{getTranslation("player_edit_display_gender")}</InputLabel>
                                <Field
                                    type="text"
                                    name="gender"
                                    label={getTranslation("player_edit_display_gender")}
                                    component={ValidatedSelectField}
                                    muiInput={<Input id="gender" />}>
                                    <MenuItem value={2}>{getTranslation("player_edit_display_gender_female")}</MenuItem>
                                    <MenuItem value={1}>{getTranslation("player_edit_display_gender_male")}</MenuItem>
                                    <MenuItem value={0}>{getTranslation("player_edit_display_gender_other")}</MenuItem>
                                </Field>
                            </FormControl>
                            <Field name="birthday" label={getTranslation("player_edit_display_birth_date")} component={ValidatedDateField} fullWidth />
                            {user.is_institution && (
                                <FormControl fullWidth>
                                    <Field
                                        name="caregivers"
                                        label={getTranslation("player_create_display_caregivers")}
                                        component={MultiSelectField}
                                        options={institutionCaregivers
                                            .filter((v) => v.id !== user.id)
                                            .map((caregiver) => ({
                                                value: caregiver.id,
                                                label: caregiver.email,
                                            }))}
                                    />
                                </FormControl>
                            )}
                            {withPhoto && (
                                <div style={{ margin: '20px 0' }}>
                                    <Grid container spacing={0} alignItems="center">
                                        <Grid item style={{ marginBottom: 10 }}>
                                            <Field type="file" name="photo" label={getTranslation("player_create_select_photo")} component={FileField} />
                                        </Grid>
                                        <Grid item xs={12} sm>
                                            <Typography style={{ paddingLeft: 10 }}>{getTranslation("player_create_select_later")}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </div>
                        {message && (
                            <div>
                                {message}
                                <br />
                            </div>
                        )}
                        {termsOfService && <div>{termsOfService}</div>}
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} />
                            <Button color="secondary" onClick={handleSubmit} disabled={submitting || invalid}>
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
        paymentForm: false,
    };
}

const mapStateTopProps = (state, ownProps) => {
    const institutionCaregivers = usersSelector.getInstitutionUsers(state);
    return {
        institutionCaregivers: institutionCaregivers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInstitutionCaregivers: (institutionId) => dispatch(fetchInstitutionUsers(institutionId)),
    };
};

export default compose(connect(mapStateTopProps, mapDispatchToProps), withStyles(styles))(PlayerEditRForm);
