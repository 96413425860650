import { MenuItem, Select, Checkbox } from '@material-ui/core';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

export default class MultiSelectField extends React.Component {
    state = {};

    render() {
        const { input, options, label } = this.props;
        return (
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                    fullWidth
                    multiple
                    required
                    renderValue={(selected) =>
                        selected.map((id) => {
                            const option = options.find((o) => o.value === id);
                            if (!option) return null;
                            return <Chip key={id} style={{ marginRight: '6px' }} label={option.label} />;
                        })
                    }
                    {...input}
                    value={input.value || []}>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={input.value.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    static propTypes = {
        options: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
    };
}
