import axios from 'axios';
import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import videoLinkSchema from 'reducers/schemas/videoLink';

export const videoLinksSelector = {
    getCurrentChannelVideoLinksState: (state) => state.videoLinks.currentChannelVideoLinks,
    getCurrentChannelVideoLinks: (state) =>
        NormalizedSelector.getByIds(state.videoLinks.videoLinks, state.videoLinks.currentChannelVideoLinks),
};

const CURRENT_CHANNEL_VIDEO_LINKS = 'CURRENT_CHANNEL_VIDEO_LINKS';
const CURRENT_CHANNEL_VIDEO_LINKS_SUCCESS = 'CURRENT_CHANNEL_VIDEO_LINKS_SUCCESS';
const CURRENT_CHANNEL_VIDEO_LINKS_ERROR = 'CURRENT_CHANNEL_VIDEO_LINKS_ERROR';

const CREATE_CHANNEL_VIDEO_LINK = 'CREATE_CHANNEL_VIDEO_LINK';
const CREATE_CHANNEL_VIDEO_LINK_SUCCESS = 'CREATE_CHANNEL_VIDEO_LINK_SUCCESS';
const CREATE_CHANNEL_VIDEO_LINK_ERROR = 'CREATE_CHANNEL_VIDEO_LINK_ERROR';

const DELETE_VIDEO_LINK = 'DELETE_VIDEO_LINK';
const DELETE_VIDEO_LINK_SUCCESS = 'DELETE_VIDEO_LINK_SUCCESS';
const DELETE_VIDEO_LINK_ERROR = 'DELETE_VIDEO_LINK_ERROR';

export const fetchChannelLinks = (videoChannelId) => (dispatch) => {
    dispatch({ type: CURRENT_CHANNEL_VIDEO_LINKS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/video_channels/${videoChannelId}/video_links`),
        [videoLinkSchema],
        [CURRENT_CHANNEL_VIDEO_LINKS_SUCCESS, CURRENT_CHANNEL_VIDEO_LINKS_ERROR],
        dispatch
    );
};

export const deleteLink = (linkId) => (dispatch) => {
    dispatch({ type: DELETE_VIDEO_LINK });
    return dispatchNormalizedPromise(
        axiosInstance.delete(`/video_links/${linkId}`),
        null,
        [DELETE_VIDEO_LINK_SUCCESS, DELETE_VIDEO_LINK_ERROR],
        dispatch,
        { linkId }
    );
};

export const createVideoLink = (videoChannelId, { title, description, url, customVideoId, thumbnailUrl }) => (
    dispatch
) => {
    dispatch({ type: CREATE_CHANNEL_VIDEO_LINK });

    return dispatchNormalizedPromise(
        axiosInstance.post(`/video_channels/${videoChannelId}/video_links`, {
            title,
            description,
            url,
            custom_video_id: customVideoId,
            custom_cover_url: thumbnailUrl || null,
        }),
        videoLinkSchema,
        [CREATE_CHANNEL_VIDEO_LINK_SUCCESS, CREATE_CHANNEL_VIDEO_LINK_ERROR],
        dispatch
    );
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const uploadVideo = async (file) => {
    const result = await axiosInstance.post(`/video_links/create_upload`);
    const { token } = result.data.data;

    const data = new FormData();
    data.append('source', file);

    const {
        data: {
            videoId,
            assets: { thumbnail },
        },
    } = await axios.post('https://ws.api.video/upload', data, {
        params: {
            token,
        },
    });
    await sleep(5000);  // need to pause briefly to allow thumbnail to generate
    return { videoId, thumbnailUrl: thumbnail };
};

export default combineReducers({
    videoLinks: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('videoLinks'),
        defaultStrategy: Strategies.merge,
    }),
    currentChannelVideoLinks: createRequestReducer(
        [
            CURRENT_CHANNEL_VIDEO_LINKS,
            [
                CURRENT_CHANNEL_VIDEO_LINKS_SUCCESS,
                { type: CREATE_CHANNEL_VIDEO_LINK_SUCCESS, strategy: Strategies.append },
                {
                    type: DELETE_VIDEO_LINK_SUCCESS,
                    strategy: Strategies.remove,
                    mapResponse: (action) => action.linkId,
                },
            ],
            CURRENT_CHANNEL_VIDEO_LINKS_ERROR,
        ],

        {
            mapResponse: NormalizedActionMap.result,
        }
    ),
});
