import React from 'react';
import Typography from '@material-ui/core/Typography';
import { truncate } from 'lodash-es';

function VideoPreview({ title, description, thumbnailUrl }) {
    return (
        <div>
            <img style={{ maxHeight: 200, maxWidth: '100%' }} src={thumbnailUrl} alt="" />
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">{truncate(description, { length: 100 })}</Typography>
        </div>
    );
}

VideoPreview.propTypes = {};

VideoPreview.defaultProps = {};
export default VideoPreview;
