import * as actions from 'redux-form';
import { reduxForm, SubmissionError } from 'redux-form';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { updatePlayerObjectAsset } from 'reducers/assets';
import AssetRFormDescription from 'containers/assets/AssetRFormDescription';
import { compose } from 'recompose';

export default compose(
    reduxForm({
        form: 'asset_edit',
        validate: (values) => {
            const errors = {};
            return errors;
        },
        onSubmit: (values, dispatch, ownProps) => {
            const {
                object: { unity_id: unityId },
                initialValues: { id: assetId, player_id: playerId },
            } = ownProps;

            return dispatch(updatePlayerObjectAsset(playerId, unityId, assetId, values))
                .then((result) => {
                    ownProps.onEdited && ownProps.onEdited();
                    dispatch(actions.reset('asset_edit'));
                    return result;
                })
                .catch((error) => {
                    if (error.isException(Exceptions.ValidationException)) {
                        const errors = validationErrorToSubmissionError(error.getData());
                        throw new SubmissionError(errors);
                    }
                    throw new SubmissionError({ _error: error.getMessage() });
                });
        },
    })
)(AssetRFormDescription);
