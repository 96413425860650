/* Authors: Gaetan Senelle, Victor Neuret, Sébastien Le Maire, Niklaas Cotta
   Last modified: 7/25/2023
   Desc: Base prop cards for unity asset objects
*/


import React from 'react';

import { LocaleConsumer } from 'containers/locale/LocaleProvider';

import withStyles from '@material-ui/core/styles/withStyles';
import { 
    Card, CardContent, CardMedia, 
    IconButton, 
    Typography 
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { truncate } from 'lodash-es';


// CSS styles in JSX format
const styles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
    },
    media: {
        height: 240
    },
    content: {
        overflow: 'hidden',
    },
    descriptionContainer: {
        flex: 1,
        alignItems: 'center',
    },
    description: {
        maxHeight: ' 3em',
        overflow: 'hidden'
    },
};



// Main class logic
class AssetCard extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleDeleteClick = (event) => {
        const { asset } = this.props;
        this.props.onDelete && this.props.onDelete(asset);
    };

    handleEditClick = (event) => {
        const { asset } = this.props;
        this.props.onEdit && this.props.onEdit(asset);
    };

    /**
     * Render photo album
     */
    renderImageAsset() {
        const { classes, asset, onDelete, onEdit, ...otherProps } = this.props;

        // React Component
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Card {...otherProps} className={classes.card}>
                        <CardMedia className={classes.media} image={asset.file_url} />
                        <div className={classes.content}>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.descriptionContainer}>
                                    <CardContent>
                                        <Typography>{getTranslation("description")}</Typography>
                                        <Typography color="textSecondary" className={classes.description}>
                                            {asset.description || getTranslation("asset_card_no_description")}
                                        </Typography>
                                    </CardContent>
                                </div>
                                <div>
                                    <IconButton aria-label="Edit" onClick={this.handleEditClick}>
                                        <EditIcon style={{ width: 28, height: 28 }} />
                                    </IconButton>
                                    <IconButton aria-label="Delete" onClick={this.handleDeleteClick}>
                                        <DeleteIcon style={{ width: 28, height: 28 }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Card>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render video channel
     */
    renderVideoChannelAsset() {
        const {
            classes,
            asset: { video_channel },
            onDelete,
            onEdit,
            ...otherProps
        } = this.props;

        // React Component
        return (
            <Card {...otherProps} className={classes.card}>
                <CardMedia className={classes.media} image={video_channel.cover_url} />
                <div className={classes.content}>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.descriptionContainer}>
                            <CardContent>
                                <Typography>{video_channel.title}</Typography>
                                <Typography color="textSecondary" className={classes.description}>
                                    {video_channel.description || 'No description'}
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <IconButton aria-label="Edit" onClick={this.handleEditClick}>
                                <VisibilityIcon style={{ width: 28, height: 28 }} />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton aria-label="Delete" onClick={this.handleDeleteClick}>
                                <DeleteIcon style={{ width: 28, height: 28 }} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    /**
     * Render books
     */
    renderBookAsset() {
        const {
            classes,
            asset: {
                book: {
                    name,
                    category_name,
                    cover
                }
            },
            onDelete,
            onEdit,
            ...otherProps
        } = this.props;

        // React Component
        return (
            <Card {...otherProps} className={classes.card}>
                {cover && <CardMedia className={classes.media} image={cover.file_url} />}
                <div className={classes.content}>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.descriptionContainer}>
                            <CardContent>
                                <Typography>{name}</Typography>
                                <Typography color="textSecondary" className={classes.description}>
                                    {category_name || 'No category'}
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <IconButton aria-label="Edit" onClick={this.handleEditClick}>
                                <VisibilityIcon style={{ width: 28, height: 28 }} />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton aria-label="Delete" onClick={this.handleDeleteClick}>
                                <DeleteIcon style={{ width: 28, height: 28 }} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    /**
     * Render paintings
     */
    renderPaintingAsset() {
        const {
            classes,
            asset: { painting },
            onDelete,
            onEdit,
            ...otherProps
        } = this.props;

        return (
            <Card {...otherProps} className={classes.card}>
                <CardMedia className={classes.media} image={painting.file_url} />
                <div className={classes.content}>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.descriptionContainer}>
                            <CardContent>
                                <Typography>{painting.description}</Typography>
                                <Typography color="textSecondary" className={classes.description}>
                                    {painting.description || 'No description'}
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <IconButton aria-label="Edit" onClick={this.handleEditClick}>
                                <VisibilityIcon style={{ width: 28, height: 28 }} />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton aria-label="Delete" onClick={this.handleDeleteClick}>
                                <DeleteIcon style={{ width: 28, height: 28 }} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    renderMusicTrackAsset() {
        const {
            classes,
            asset: { music_track },
            onDelete,
            onEdit,
            ...otherProps
        } = this.props;

        return (
            <Card {...otherProps} className={classes.card}>
                <CardMedia className={classes.media} image={music_track.thumbnail} />
                <div className={classes.content}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%' }} className={classes.descriptionContainer}>
                            <CardContent style={{paddingLeft: 7, paddingTop: 5, paddingBottom: 5, paddingRight: 0}}>
                                <Typography variant="caption" >{truncate(music_track.title, { length: 30 })}</Typography>
                                <Typography variant="caption" color="textSecondary" className={classes.description}>
                                    {truncate(music_track.description, { length: 50 }) || 'No description'}
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <IconButton aria-label="Delete" onClick={this.handleDeleteClick}>
                                <DeleteIcon style={{ width: 20, height: 20 }} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }


    // render base
    render() {
        const { asset } = this.props;
        switch (asset.type) {
            case 'file':
                return this.renderImageAsset();
            case 'video_channel':
                return this.renderVideoChannelAsset();
            case 'book':
                return this.renderBookAsset();
            case 'painting':
                return this.renderPaintingAsset();
            case 'music_track':
                return this.renderMusicTrackAsset();
        }
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}



export default withStyles(styles)(AssetCard);
