import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, CardContent, Typography } from '@material-ui/core';
import { usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import InstitutionUsersStatsList from 'containers/users/InstitutionUsersStatsList';
import InstitutionPlayersStatsList from 'containers/players/InstitutionPlayersStatsList';
import { DateRangePicker } from 'react-dates';
import ResetIcon from '@material-ui/icons/Refresh';
import DateRangeIcon from '@material-ui/icons/DateRange';
import StatsIcon from '@material-ui/icons/Timeline';
import InstitutionHistograms from 'containers/users/InstitutionHistograms';
import InstitutionStatsSummary from 'containers/users/InstitutionStatsSummary';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { debounce } from 'lodash-es';
import moment from 'moment';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {
    main: {
        '& > *': {
            marginBottom: 20,
        },
    },
};

class InstitutionStatsPage extends React.Component {
    /**
     * Component state
     */
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            location: { search },
        } = this.props;
        this.updateStateFromSearchParams(search);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) this.updateStateFromSearchParams(this.props.location.search);
    }

    updateStateFromSearchParams(search) {
        const parsed = qs.parse(search, { ignoreQueryPrefix: true });
        const newParams = {
            startDate: parsed.startDate ? moment(parsed.startDate) : null,
            endDate: parsed.endDate ? moment(parsed.endDate) : null,
        };
        this.setState({ ...this.state, ...newParams });
    }

    updateUrlSearchParams = debounce(() => {
        const { history } = this.props;
        const { startDate, endDate } = this.state;
        const params = { startDate: startDate && new Date(startDate), endDate: endDate && new Date(endDate) };
        !startDate && !endDate ? history.replace(`/institution/stats`) : history.replace(`?${qs.stringify(params)}`);
    }, 100);

    handleResetClick = (event) => {
        this.setState({ startDate: null, endDate: null }, () => this.updateUrlSearchParams());
    };

    handleDateChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.updateUrlSearchParams());
    };

    /**
     * Render component
     */
    render() {
        const { classes, user } = this.props;
        const { startDate, endDate } = this.state;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <CardContent>
                            <div style={{ paddingBottom: 10 }}>
                                <InstitutionStatsSummary />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <StatsIcon />
                                <Typography variant="h5">{getTranslation("institution_stats_title")}</Typography>
                                <div style={{ flex: '1' }} />
                                <div>
                                    <DateRangeIcon />
                                </div>
                                <div>
                                    <DateRangePicker
                                        noBorder
                                        startDate={startDate}
                                        startDateId="stats_start_date"
                                        startDatePlaceholderText={getTranslation("institution_stats_start_date")}
                                        endDate={endDate}
                                        endDateId="stats_start_date"
                                        endDatePlaceholderText={getTranslation("institution_stats_end_date")}
                                        onDatesChange={this.handleDateChange}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                                        isOutsideRange={() => false}
                                    />
                                    {(startDate || endDate) && (
                                        <Button onClick={this.handleResetClick}>
                                            <ResetIcon /> {getTranslation("reset")}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </CardContent>{' '}
                        <CardContent>
                            <InstitutionHistograms institutionId={user.id} from={startDate} to={endDate} />
                        </CardContent>
                        <CardContent>
                            <Typography variant="h6">{getTranslation("institution_stats_how_players_going")}</Typography>
                        </CardContent>
                        <div>
                            <InstitutionPlayersStatsList institutionId={user.id} from={startDate} to={endDate} />
                        </div>
                        <CardContent>
                            <Typography variant="h6">{getTranslation("institution_stats_how_caregivers_going")}</Typography>
                        </CardContent>
                        <div>
                            <InstitutionUsersStatsList institutionId={user.id} from={startDate} to={endDate} />
                        </div>
                        <br />
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default compose(withStyles(styles), connect(mapStateTopProps), withRouter)(InstitutionStatsPage);
