import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button, FormControl, IconButton, Input, InputLabel, MenuItem, Typography } from '@material-ui/core';
import ValidatedSelectField from 'ui/base/ValidatedSelectField';
import DefaultError from 'ui/base/DefaultError';
import PlayerGender from 'containers/players/PlayerGender';
import CancelIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { Col, Row } from 'react-flexbox-grid';
import ValidatedDateField from '../../ui/base/ValidatedDateField';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'
import moment from "moment";

const styles = {
    form: {},
    title: {
        marginTop: 20,
    },
};

class PlayerEditDisplayRForm extends React.Component {
    /**
     * Component state
     */
    state = {
        isEditing: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleEditClick = () => {
        const { reset } = this.props;
        reset();
        this.setState({ isEditing: true });
    };

    handleCancelClick = () => {
        const { reset } = this.props;
        reset();
        this.setState({ isEditing: false });
    };

    handleConfirmClick = (event) => {
        const { handleSubmit, pristine } = this.props;
        if (pristine) {
            this.setState({ isEditing: false });
        } else {
            handleSubmit && handleSubmit(event);
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.pristine === false && this.props.pristine === true) {
            this.setState({ isEditing: false });
        }
    }

    renderTextField(name, displayName, field, previewCallback) {
        const { initialValues } = this.props;
        const { isEditing } = this.state;
        previewCallback =
            previewCallback ||
            ((value) => <Typography color={value ? 'default' : 'textSecondary'}>{value || <i>-</i>}</Typography>);
        return (
            <Row middle="xs" style={{ margin: '10px 0' }}>
                <Col style={{ width: 125 }}>
                    <Typography>
                        <b>{displayName}</b>
                    </Typography>
                </Col>
                <Col xs>{isEditing ? field : previewCallback(initialValues[name])}</Col>
            </Row>
        );
    }

    renderDateField(name, displayName, field, previewCallback) {
        const { initialValues } = this.props;
        const { isEditing } = this.state;
        previewCallback =
            previewCallback ||
            ((value) => (
                <Typography>
                    {value ? moment(value).format("LL") : '-'}
                </Typography>
            ));
        return (
            <Row middle="xs" style={{ margin: '10px 0' }}>
                <Col style={{ width: 125 }}>
                    <Typography>
                        <b>{displayName}</b>
                    </Typography>
                </Col>
                <Col xs>{isEditing ? field : previewCallback(initialValues[name])}</Col>
            </Row>
        );
    }

    renderUpdate() {
        const { submitting } = this.props;
        const { isEditing } = this.state;

        return (
            <LocaleConsumer>
                {({ getTranslation}) => (
                    <Row>
                        <Col xs />
                        <Col>
                            {isEditing ? (
                                <div>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        disabled={submitting}
                                        onClick={this.handleConfirmClick}>
                                        {getTranslation("save")}
                                    </Button>
                                    <IconButton disabled={submitting} onClick={this.handleCancelClick}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ) : (
                                <div>
                                    <IconButton disabled={submitting} onClick={this.handleEditClick}>
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, error, submitting } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div className={classes.form}>
                            <DefaultError>{error}</DefaultError>
                            {this.renderUpdate()}
                            {this.renderTextField(
                                'nickname',
                                getTranslation("player_edit_display_informal_name"),
                                <Field
                                    type="text"
                                    name="nickname"
                                    label={getTranslation("player_edit_display_informal_name_eg")}
                                    disabled={submitting}
                                    component={ValidatedTextField}
                                    autoFocus
                                    required
                                    fullWidth
                                    helperText={getTranslation("player_edit_display_informal_name_desc")}
                                    inputProps={{ maxLength: 30 }}
                                />
                            )}
                            {this.renderTextField(
                                'first_name',
                                getTranslation("player_edit_display_first_name"),
                                <Field
                                    type="text"
                                    name="first_name"
                                    label={getTranslation("player_edit_display_first_name")}
                                    disabled={submitting}
                                    component={ValidatedTextField}
                                    fullWidth
                                />
                            )}
                            {this.renderTextField(
                                'middle_name',
                                getTranslation("player_edit_display_middle_name"),

                                <Field
                                    type="text"
                                    name="middle_name"
                                    label={getTranslation("player_edit_display_middle_name")}
                                    disabled={submitting}
                                    component={ValidatedTextField}
                                    fullWidth
                                />
                            )}
                            {this.renderTextField(
                                'last_name',
                                getTranslation("player_edit_display_last_name"),
                                <Field
                                    type="text"
                                    name="last_name"
                                    label={getTranslation("player_edit_display_last_name")}
                                    disabled={submitting}
                                    component={ValidatedTextField}
                                    fullWidth
                                />
                            )}
                            {this.renderTextField(
                                'gender',
                                getTranslation("player_edit_display_gender"),
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="gender">{getTranslation("player_edit_display_gender")}</InputLabel>
                                    <Field
                                        type="text"
                                        name="gender"
                                        label={getTranslation("player_edit_display_gender")}
                                        disabled={submitting}
                                        component={ValidatedSelectField}
                                        muiInput={<Input id="gender" />}>
                                        <MenuItem value={2}>{getTranslation("player_edit_display_gender_female")}</MenuItem>
                                        <MenuItem value={1}>{getTranslation("player_edit_display_gender_male")}</MenuItem>
                                        <MenuItem value={0}>{getTranslation("player_edit_display_gender_other")}</MenuItem>
                                    </Field>
                                </FormControl>,
                                (value) => (
                                    <PlayerGender gender={value} />
                                )
                            )}
                            {this.renderDateField(
                                'birthday',
                                getTranslation("player_edit_display_birth_date"),
                                <Field
                                    name="birthday"
                                    label={getTranslation("player_edit_display_birth_date")}
                                    disabled={submitting}
                                    component={ValidatedDateField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            )}
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
        paymentForm: false,
    };
}

export default withStyles(styles)(PlayerEditDisplayRForm);
