import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {};

class DefaultError extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { children, gutter, gutterBottom, gutterTop } = this.props;
        const style = {
            marginBottom: gutterBottom && gutter,
            marginTop: gutterTop && gutter,
        };

        if (!children) return null;

        return (
            <FormHelperText error style={style}>
                <ErrorOutlineIcon style={{ verticalAlign: 'middle' }} /> {children}
            </FormHelperText>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        gutter: PropTypes.number,
        gutterTop: PropTypes.bool,
        gutterBottom: PropTypes.bool,
    };
    static defaultProps = {
        gutter: 15,
    };
}

export default withStyles(styles)(DefaultError);
