export default ({ target }, tag) => {
    let node = target;
    while (node.tagName) {
        if (node.tagName.toLowerCase() === tag) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
};
