import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import PlayerCard from 'containers/players/PlayerCard';
import { connect } from 'react-redux';
import { fetchInstitutionPlayers, fetchUserPlayers, playersSelector } from 'reducers/players';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import Link from 'ui/base/Link';
import FullWidthLoader from 'ui/base/FullWidthLoader';

const styles = {
    main: {
        '& > *': {
            marginBottom: 20,
        },
    },
    playerCard: {
        marginBottom: 15,
    },
};

class InstitutionPlayersList extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { institutionId } = this.props;
        this.props.fetchPlayer(institutionId);
    }

    renderPlayersList() {
        const { classes, players } = this.props;
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {players.map((player) => {
                    return (
                        <Link key={player.id} to={`/player/${player.id}/update-profile`}>
                            <div style={{ marginRight: 20, marginBottom: 20 }}>
                                <PlayerCard playerId={player.id} className={classes.playerCard} />
                            </div>
                        </Link>
                    );
                })}
            </div>
        );
    }

    /**
     * Render component
     */
    render() {
        const { isFetching } = this.props;
        return (
            <div>
                {isFetching && <FullWidthLoader />}
                {!isFetching && this.renderPlayersList()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const playerState = playersSelector.getInstitutionPlayersState(state);
    const players = playersSelector.getInstitutionPlayers(state);
    return {
        isFetching: !players.length && RequestStateHelper.isLoading(playerState),
        players,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlayer: (institutionId) => dispatch(fetchInstitutionPlayers(institutionId)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstitutionPlayersList));
