import React from 'react';
import VideoChannelSelect from 'containers/videoChannels/VideoChannelSelect';
import { replacePlayerObjectAsset } from 'reducers/assets';
import { connect } from 'react-redux';

function AssetVideoChannelUpdateContainer({
    initialChannelIds,
    replacePlayerObjectAsset,
    player: { id: playerId },
    object: { unity_id: unityId },
}) {
    const handleConfirm = (ids) => {
        const assets = ids.map((id) => ({ type: 'video_channel', video_channel_id: id }));
        replacePlayerObjectAsset(playerId, unityId, assets);
    };
    return <VideoChannelSelect playerId={playerId} initialChannelIds={initialChannelIds} onConfirm={handleConfirm} />;
}

AssetVideoChannelUpdateContainer.propTypes = {};
AssetVideoChannelUpdateContainer.defaultProps = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        replacePlayerObjectAsset: (playerId, unityId, assets) => {
            dispatch(replacePlayerObjectAsset(playerId, unityId, assets));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetVideoChannelUpdateContainer);
