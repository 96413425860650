/*
Authors: Gaetan Senelle, Alexandre Tillet, Victor Neuret, Niklaas Cotta
Last Modified: 8/28/2023
Description: Chart displays for institution's Sessions tab
*/

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { getUser, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { Chart, TimeScale, LinearScale, BarElement, PointElement, LineElement } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

import 'chartjs-adapter-moment';
Chart.register(TimeScale, LinearScale, BarElement, PointElement, LineElement);

const styles = {};

class InstitutionHistograms extends React.Component {
    /**
     * Component state
     */
    state = {
        sessionListModalUserId: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchInstitutionWithHistograms(this.props);
    }

    componentDidUpdate(prevProps) {
        const { institutionId, from, to } = prevProps;
        const { institutionId: nextinstitutionId, from: nextFrom, to: nextTo } = this.props;
        if (institutionId !== nextinstitutionId || from !== nextFrom || to !== nextTo) {
            this.props.fetchInstitutionWithHistograms(this.props);
        }
    }

    renderPlayersAndUsersHistogram() {
        const {
            from,
            to,
            institution: { institution_users_histogram, institution_players_histogram },
        } = this.props;

        if (!institution_users_histogram) return null;

        const usersData = institution_users_histogram.map((data) => ({
            y: Number.parseInt(data.count),
            x: new Date(data.date),
        }));

        const playersData = institution_players_histogram.map((data) => ({
            y: Number.parseInt(data.count),
            x: new Date(data.date),
        }));

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Line
                        data={{
                            datasets: [
                                {
                                    fill: false,
                                    label: getTranslation("institution_stats_cumulated_caregivers"),
                                    data: usersData,
                                    tension: 0,
                                    borderColor: 'rgba(255, 152, 0, 1)',
                                },
                                {
                                    fill: false,
                                    label: getTranslation("institution_stats_cumulated_players"),
                                    data: playersData,
                                    tension: 0,
                                    borderColor: 'rgba(0, 165, 255, 1)',
                                },
                            ],
                        }}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    type: 'time',
                                    display: true,
                                    time: {
                                        unit: 'day',
                                        min: from,
                                        max: to,
                                        tooltipFormat: 'LL',
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Date',
                                    }
                                },
                                y: {
                                    type: 'linear',
                                    ticks: {
                                        beginAtZero: true,
                                        suggestedMax: 10,
                                        stepSize: 1,
                                    }
                                }
                            }
                        }}
                    />
                )}
            </LocaleConsumer>
        );
    }

    renderSessionsHistogram() {
        const {
            from,
            to,
            institution: { sessions_histogram },
        } = this.props;

        if (!sessions_histogram) return null;

        const data = sessions_histogram.map((data) => ({
            y: Number.parseInt(data.count),
            x: new Date(data.date),
        }));

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Bar
                        data={{
                            datasets: [
                                {
                                    label: getTranslation("institution_stats_sessions_per_day"),
                                    data,
                                    backgroundColor: 'rgba(255, 152, 0, 0.5)',
                                    borderColor: 'rgba(255, 152, 0, 1)',
                                    spanGaps: true,
                                },
                            ],
                        }}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    type: 'time',
                                    display: true,
                                    time: {
                                        unit: 'day',
                                        min: from,
                                        max: to,
                                        tooltipFormat: 'LL',
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Date',
                                    },
                                },
                                y: {
                                    type: 'linear',
                                    ticks: {
                                        beginAtZero: true,
                                        suggestedMax: 5,
                                        stepSize: 1,
                                    }
                                }
                            }
                        }}
                    />
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>
                {isLoading && <FullWidthLoader />}
                <div style={{ display: 'flex', height: 250 }}>
                    <div style={{ flex: 1, minWidth: 0 }}>{!isLoading && this.renderSessionsHistogram()}</div>
                    <div style={{ flex: 1, minWidth: 0 }}>{!isLoading && this.renderPlayersAndUsersHistogram()}</div>
                </div>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const getContain = ({ from, to }) => {
    return {
        sessions_histogram: {
            contain: 'SessionsHistogram',
            from: from && from.toISOString(),
            to: to && to.toISOString(),
        },
        institution_users_histogram: {
            contain: 'InstitutionUsersHistogram',
            from: from && from.toISOString(),
            to: to && to.toISOString(),
        },
        institution_players_histogram: {
            contain: 'InstitutionPlayersHistogram',
            from: from && from.toISOString(),
            to: to && to.toISOString(),
        },
    };
};
const mapStateToProps = (state, ownProps) => {
    const userState = usersSelector.getCurrentUserState(state);
    const currentUser = usersSelector.getCurrentUser(state, getContain(ownProps));
    return {
        institution: currentUser,
        isLoading: RequestStateHelper.isLoading(userState) && !currentUser.sessions_histogram,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInstitutionWithHistograms: (props) => {
            dispatch(getUser(props.institutionId, getContain(props)));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstitutionHistograms));
