import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import MentiaLogo from 'images/MentiaLogo2024.jpg';
import { 
    Button, 
    Card, CardActions, CardContent, 
    Typography 
} from '@material-ui/core';
import { askResetPassword } from 'reducers/users';
import Link from 'ui/base/Link';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import DefaultError from 'ui/base/DefaultError';
import { LocaleConsumer } from 'containers/locale/LocaleProvider';

const styles = {
    row: {
        height: '100%'
    },
};

const onSubmit = async (values, dispatch, props) => {
    // const { location } = props;

    // const sourceMapping = {
    //     'careplay-client': process.env.CAREPLAY_DEV_CLIENT_URL,
    //     'careplay-ca': process.env.CAREPLAY_CA_CLIENT_URL,
    //     'careplay-au': process.env.CAREPLAY_AU_CLIENT_URL,
    //     'careplay-fr': process.env.CAREPLAY_FR_CLIENT_URL,
    // };

    // const source = new URLSearchParams(location.search).get('source');
    // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
        await dispatch(askResetPassword(values));
        dispatch(push('/thankyou_password'));
        // if (sourceMapping[source]) {
        //     window.location.href = sourceMapping[source];
        // } else {
        //     dispatch(push('/login', { email: values.email, isAfterAskResetPassword: true }));
        // }
    } catch (error) {
        throw new SubmissionError({ _error: error.message });
    }


};

const Form = () => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div>
                    <Field
                        type="text"
                        name="email"
                        label={getTranslation("email")}
                        component={ValidatedTextField}
                        autoFocus
                        required
                        fullWidth
                    />
                </div>
            )}
        </LocaleConsumer>
    );
}

const Actions = ({ handleSubmit, submitting }) => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <CardActions>
                    <Link to={`/login`} tabIndex={-1}>
                        <Button tabIndex={1}>
                            {getTranslation("back")}
                        </Button>
                    </Link>
                    <div style={{ flex: 1 }} />
                    <Button 
                        color="secondary" 
                        onClick={handleSubmit} 
                        disabled={submitting}
                    >
                        {getTranslation("send")}
                    </Button>
                </CardActions>
            )}
        </LocaleConsumer>
    );
}

const AskPasswordPage = ({ classes, error, handleSubmit, submitting }) => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Row className={classes.row} center="xs" middle="xs">
                    <Col xs={12} sm={6} lg={4}>
                        <div className={classes.cardContainer}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent>
                                    <Typography align="center">
                                        <img src={MentiaLogo} height={40} alt="" style={{ marginTop: 20, marginBottom: 30 }} />
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                        {getTranslation("lost_password_title")}
                                    </Typography>
                                    <br />
                                    <Typography color="textSecondary">
                                        {getTranslation("lost_password_enter_email")}
                                    </Typography>
                                    <DefaultError gutterTop>{error}</DefaultError>
                                    <br />
                                    <Form />
                                </CardContent>
                                <Actions handleSubmit={handleSubmit} submitting={submitting} />
                            </Card>
                        </div>
                    </Col>
                </Row>
            )}
        </LocaleConsumer>
    );
};

export default withStyles(styles)(
    reduxForm({
        form: 'lostPassword',
        onSubmit
    })(AskPasswordPage)
);