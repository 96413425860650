/*
Authors: Hugo Lavergne, Niklaas Cotta
Last Modified: 7/26/2023
Description: Main book asset page
*/

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { truncate } from 'lodash-es';

import { LocaleConsumer } from 'containers/locale/LocaleProvider'

import { RequestStateHelper } from 'reducers/createRequestReducer';
import { fetchBooks, bookSelector } from 'reducers/books';
import { fetchLanguages, languagesSelector } from 'reducers/languages';

import FullWidthLoader from 'ui/base/FullWidthLoader';
import SearchIcon from '@material-ui/icons/Search';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    List, ListItem, ListItemText, ListItemIcon,
    Dialog, DialogContent, DialogTitle, DialogActions,
    Checkbox, Button,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';



// CSS styles in JSX format
const styles = {
    list: {
        maxHeight: 400,
        overflow: 'auto',
    },
};



// Main function logic
function BookSelect({
    initialBookIds = [],
    maxItems = 3,
    classes,
    isFetching,
    books,
    fetchObjects,
    fetchLanguages,
    languages,
    onConfirm,
    playerId
}) {
    // State and other variables
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [search, setSearch] = useState('');
    const [language, setLanguage] = useState('');

    const hasUnchecked = selectedIds.length !== books.length;

    // Grab player unity objects and bookIds when page is opened
    useEffect(() => {
        if (!open) return;
        fetchObjects(playerId);
        fetchLanguages();
        setSelectedIds(initialBookIds);
    }, [open]);

    // Helper functions
    const handleBookClick = (bookId) => {
        selectedIds.includes(bookId)
            ? setSelectedIds(selectedIds.filter((v) => v !== bookId))
            : selectedIds.length < maxItems && setSelectedIds(selectedIds.concat([bookId]));
    };
    
    const transform = (v) => v.toLowerCase();
    const includes = (v, search) => transform(v).includes(transform(search));
    
    // Filter function for both search input and language selector
    const searchFilter = (book) => {
        if (!search && !language) return true;
        return (includes(book.name, search) && ((language === "all") ? true : includes(book.language_name, language)));
    };

    const handleCheckAll = () => {
        setSelectedIds(hasUnchecked ? books.map((v) => v.id) : []);
    };

    // React Component
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <Button onClick={() => setOpen(true)} variant="contained" color="secondary" style={{marginBottom: 20, marginTop: 10}}>
                        {getTranslation("book_select")}
                    </Button>
                    <Dialog fullWidth maxWidth="sm" onClose={() => setOpen(false)} open={open}>
                        <DialogTitle>{getTranslation("book_select_up_to", maxItems)}</DialogTitle>
                        {isFetching && !books.length && (
                            <DialogContent>
                                <FullWidthLoader />
                            </DialogContent>
                        )}
                        <DialogContent>
                            <TextField
                                placeholder={getTranslation("search")}
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button onClick={handleCheckAll}>{hasUnchecked ? getTranslation("asset_editor_books_tick_all") : getTranslation("asset_editor_books_untick_all")}</Button>
                            <FormControl style={{ float: "right", top: -12, width: 115 }}>
                                <InputLabel id="language_select_id">{getTranslation("language_select_placeholder")}</InputLabel>
                                <Select
                                    labelId="language_select_id"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                >
                                    {languages.map((language) => {
                                        return (
                                            <MenuItem key={language.id} value={language.code}>{language.name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <List className={classes.list}>
                            {books.filter(searchFilter).map((book) => {
                                const checked = selectedIds.includes(book.id);
                                return (
                                    <ListItem key={book.id} button onClick={() => handleBookClick(book.id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                disabled={!checked && selectedIds.length >= maxItems}
                                                checked={checked}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <img style={{ height: 50, maxWidth: 50 }} src={book.cover.file_url} alt="" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={book.name}
                                            secondary={truncate(book.language_name, { length: 50 })}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <DialogActions>
                            <Button autoFocus onClick={() => setOpen(false)}>
                                {getTranslation("cancel")}
                            </Button>
                            <Button
                                onClick={() => {
                                    onConfirm(selectedIds);
                                    setOpen(false);
                                }}
                                variant="contained"
                                color="secondary"
                                autoFocus>
                                {getTranslation("confirm")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}



// Reducer logic
BookSelect.propTypes = {};
BookSelect.defaultProps = {};

const mapStateToProps = (state) => {
    const bookState = bookSelector.getBookState(state);

    return {
        isFetching: RequestStateHelper.isLoading(bookState),
        books: bookSelector.getAll(state),
        languages: languagesSelector.getCurrentLanguages(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchObjects: () => dispatch(fetchBooks()),
        fetchLanguages: () => dispatch(fetchLanguages()),
    };
};



export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(BookSelect);
