/* 
Authors: Gaetan Senelle, SOOYOOS, Niklaas Cotta
Last modified: 9/18/2023
Description: Unity object assets main logic
*/

import React from 'react';
import { connect } from 'react-redux';

import {
    assetSelector,
    deletePlayerObjectAsset,
    fetchPlayerObjectAssets,
    sortPlayerObjectAsset,
} from 'reducers/assets';
import { RequestStateHelper } from 'reducers/createRequestReducer';

import { Dialog, DialogContent, DialogTitle, Typography, Card, List, Box } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import FullWidthLoader from 'ui/base/FullWidthLoader';

import SortableAssetCards from 'containers/assets/SortableAssetCards';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

import AssetRFormCreateContainer from 'containers/assets/AssetRFormCreateContainer';
import AssetRFormDescriptionUpdateContainer from 'containers/assets/AssetRFormDescriptionUpdateContainer';

import BookUpdateContainer from "../books/BookUpdateContainer";
import BookPagesDialog from 'containers/books/BookPagesDialog';

import AssetVideoChannelUpdateContainer from 'containers/assets/AssetVideoChannelUpdateContainer';
import VideoPersonalChannelItem from 'containers/videoChannels/VideoPersonalChannelItems';
import VideoChannelLinksDialog from 'containers/videoChannels/VideoChannelLinksDialog';

import PaintingRFormCreateContainer from "../paintings/PaintingRFormCreateContainer";
import PaintingUpdateContainer from "../paintings/PaintingUpdateContainer";

import MusicTrackUpdateContainer from '../musicTracks/MusicTrackUpdateContainer';
import SearchBar from '../musicTracks/SearchBar';

import sequenceMove from 'utils/sequenceMove';
import hasAncestorTag from 'utils/hasAncestorTag';



const styles = {
    assets: {
        marginBottom: 50,
    },
};

const musicLocationStyles = {
        marginLeft: 2, 
        marginTop: 15, 
        fontSize: 20, 
        fontWeight: 350
}

const musicPlaceholderStyles = {
    width: 110, 
    height: 160,
    backgroundColor: "#f5f5f5", 
    marginRight: 10,
}

const musicPlaceholderListStyles = {
    display: 'flex', 
    flexDirection: 'row',
    marginRight: 10,
    marginTop: 10
}

const musicContainerStyles = {
    display: 'flex',
}

class AssetsEditor extends React.Component {
    /**
     * Component state
     */
    state = {
        currentEditingAsset: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    playerIdFromProps({ player: { id: playerId } }) {
        return playerId;
    }

    unityIdFromProps({ object: { unity_id: unityId } }) {
        return unityId;
    }

    fetchAssets(props) {
        this.props.fetchAssets(this.playerIdFromProps(props), this.unityIdFromProps(props));
    }

    componentDidMount() {
        this.fetchAssets(this.props);
    }

    componentDidUpdate(prevProps) {
        if (
            this.playerIdFromProps(this.props) !== this.playerIdFromProps(prevProps) ||
            this.unityIdFromProps(this.props) !== this.unityIdFromProps(prevProps)
        ) {
            this.fetchAssets(this.props);
        }
    }

    handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        const { assetsOrder } = this.props;
        const playerId = this.playerIdFromProps(this.props);
        const unityId = this.unityIdFromProps(this.props);
        const newOrder = sequenceMove(assetsOrder, newIndex, oldIndex);

        this.props.sortAssets(playerId, unityId, {
            movedId: assetsOrder[oldIndex],
            newPosition: newIndex,
            newOrder,
        });
    };

    handleAssetDelete = (asset) => {
        const playerId = this.playerIdFromProps(this.props);
        const unityId = this.unityIdFromProps(this.props);
        this.props.deleteAsset(playerId, unityId, asset.id);
    };

    handleAssetEdit = (asset) => {
        this.setState({ currentEditingAsset: asset });
    };

    handleAssetEdited = (value) => {
        this.setState({ currentEditingAsset: null });
    };

    renderAssets() {
        const { object, orderedAssets } = this.props;

        switch (object.type) {
            case 'music_tracks':
                const recordPlayerAssets = orderedAssets.filter((v) => v?.music_location === 0);
                const radioGardenAssets = orderedAssets.filter((v) => v?.music_location === 1);
                const radioBedroomAssets = orderedAssets.filter((v) => v?.music_location === 2);
                const radioBathroomAssets = orderedAssets.filter((v) => v?.music_location === 3);

                return (
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            <React.Fragment>
                                <Typography style={musicLocationStyles}>
                                    {getTranslation("music_tracks_location_record_player")}
                                </Typography>
                                <Box style={musicContainerStyles}>
                                    <SortableAssetCards
                                        shouldCancelStart={(event) => hasAncestorTag(event, 'button')}
                                        onSortEnd={this.handleSortEnd}
                                        assets={recordPlayerAssets}
                                        onDelete={this.handleAssetDelete}
                                        onEdit={this.handleAssetEdit}
                                        axis="xy"
                                    />
                                    <List style={musicPlaceholderListStyles}>
                                        {[...Array(Math.max(0, 4 - recordPlayerAssets.length))].map(() => <Card elevation="0" style={musicPlaceholderStyles}></Card>)}
                                    </List>
                                </Box>
                                <Typography style={musicLocationStyles}>
                                    {getTranslation("music_tracks_location_radio_garden")}
                                </Typography>
                                <Box style={musicContainerStyles}>
                                    <SortableAssetCards
                                        shouldCancelStart={(event) => hasAncestorTag(event, 'button')}
                                        onSortEnd={this.handleSortEnd}
                                        assets={radioGardenAssets}
                                        onDelete={this.handleAssetDelete}
                                        onEdit={this.handleAssetEdit}
                                        axis="xy"
                                    />
                                    <List style={musicPlaceholderListStyles}>
                                        <Card elevation="0" style={musicPlaceholderStyles}></Card>
                                    </List>
                                </Box>
                                <Typography style={musicLocationStyles}>
                                    {getTranslation("music_tracks_location_radio_bedroom")}
                                </Typography>
                                <Box style={musicContainerStyles}>
                                    <SortableAssetCards
                                        shouldCancelStart={(event) => hasAncestorTag(event, 'button')}
                                        onSortEnd={this.handleSortEnd}
                                        assets={radioBedroomAssets}
                                        onDelete={this.handleAssetDelete}
                                        onEdit={this.handleAssetEdit}
                                        axis="xy"
                                    />
                                    <List style={musicPlaceholderListStyles}>
                                        <Card elevation="0" style={musicPlaceholderStyles}></Card>
                                    </List>
                                </Box>
                                <Typography style={musicLocationStyles}>
                                    {getTranslation("music_tracks_location_radio_bathroom")}
                                </Typography>
                                <Box style={musicContainerStyles}>
                                    <SortableAssetCards
                                        shouldCancelStart={(event) => hasAncestorTag(event, 'button')}
                                        onSortEnd={this.handleSortEnd}
                                        assets={radioBathroomAssets}
                                        onDelete={this.handleAssetDelete}
                                        onEdit={this.handleAssetEdit}
                                        axis="xy"
                                    />
                                    <List style={musicPlaceholderListStyles}>
                                        <Card elevation="0" style={musicPlaceholderStyles}></Card>
                                    </List>
                                </Box>
                            </React.Fragment>
                        )}
                    </LocaleConsumer>
                )

            default:
                if (!orderedAssets.length) {
                    return <Typography align="center" />;
                }

                return (
                    <SortableAssetCards
                        shouldCancelStart={(event) => hasAncestorTag(event, 'button')}
                        onSortEnd={this.handleSortEnd}
                        assets={orderedAssets}
                        onDelete={this.handleAssetDelete}
                        onEdit={this.handleAssetEdit}
                        axis="xy"
                    />
                );
        }
    }

    // Form selected to create new assets.
    renderAssetCreate() {
        const { object, orderedAssets, player } = this.props;

        switch (object.type) {
            case 'image':
                return (
                    <React.Fragment>
                        <AssetRFormCreateContainer player={player} object={object} />
                    </React.Fragment>
                );
            case 'video_channel':
                /* Because we don't store current fetching object id and player id, we can get out of sync channels*/
                const ids = orderedAssets.map((v) => v?.video_channel?.id).filter((v) => !!v);
                return (
                    <React.Fragment>
                        <AssetVideoChannelUpdateContainer player={player} object={object} initialChannelIds={ids} />
                    </React.Fragment>
                );
            case 'images':
                const bookIds = orderedAssets.map((v) => v?.book?.id).filter((v) => !!v);
                return (
                    <React.Fragment>
                        <BookUpdateContainer player={player} object={object} initialBookIds={bookIds} />
                    </React.Fragment>
                );
            case 'paintings':
                const paintingIds = orderedAssets.map((v) => v?.painting?.id).filter((v) => !!v);
                return (
                    <React.Fragment>
                        <PaintingUpdateContainer player={player} object={object} initialPaintingIds={paintingIds} />
                        <PaintingRFormCreateContainer player={player} object={object} />
                    </React.Fragment>
                );
            case 'music_tracks':
                const musicTrackIds = orderedAssets.map((v) => ({ type: 'music_track', music_track_id: v.music_track_id, music_location: v.music_location })).filter((v) => !!v);
                return (
                    <React.Fragment>
                        <MusicTrackUpdateContainer player={player} object={object} initialMusicTrackIds={musicTrackIds} />
                        <SearchBar playerId={player.id} />
                    </React.Fragment>
                );
        }
    }

    // Description's section
    renderAssetInfo() {
        const { object } = this.props;

        switch (object.unity_id) {
            case 'tv':
                return (
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    {getTranslation("assets_editor_create_tv_channel")}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    {getTranslation("assets_editor_create_tv_channel_desc")}
                                </Typography>
                            </React.Fragment>
                        )}
                    </LocaleConsumer>
                );
            case 'photo_album':
                return (
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    {getTranslation("assets_editor_create_photo_album")}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    {getTranslation("assets_editor_create_photo_album_desc")}
                                </Typography>
                            </React.Fragment>
                        )}
                    </LocaleConsumer>
                );
            case 'books':
                return (
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    {getTranslation("assets_editor_create_books")}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    {getTranslation("assets_editor_create_books_desc")}
                                </Typography>
                            </React.Fragment>
                        )}
                    </LocaleConsumer>
                );
            case 'paintings':
                return (
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    {getTranslation("assets_editor_create_painting")}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    {getTranslation("assets_editor_create_painting_desc")}
                                </Typography>
                            </React.Fragment>
                        )}
                    </LocaleConsumer>
                );
            case 'music_tracks':
                return (
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    {getTranslation("assets_editor_create_music_tracks")}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    {getTranslation("assets_editor_create_music_tracks_desc")}
                                </Typography>
                            </React.Fragment>
                        )}
                    </LocaleConsumer>
                )
        }
    }

    renderVideoCustomChannel() {
        const { object, player } = this.props;

        if (object.type !== 'video_channel') return null;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <React.Fragment>
                        <VideoPersonalChannelItem playerId={player.id} />
                        <Typography gutterBottom>{getTranslation("assets_editor_devaworld_tv_channel")}</Typography>
                    </React.Fragment>
                )}
            </LocaleConsumer>
        );
    }

    renderPainting() {
        const { object } = this.props

        if (object.type !== 'paintings') return null;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <React.Fragment>
                        <Typography gutterBottom>{getTranslation("paintings_list")}</Typography>
                    </React.Fragment>
                )}
            </LocaleConsumer>
        )
    }

    /**
     * Render component
     */
    render() {
        const { classes, isFetching, object } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        {this.renderAssetInfo()}
                        <div className={classes.assets}>
                            {this.renderVideoCustomChannel()}
                            {isFetching && <FullWidthLoader />}
                            {!isFetching && this.renderAssets()}
                        </div>
                        <div>
                            <Typography variant="h6">{getTranslation("customize")}</Typography>
                            {this.renderAssetCreate()}
                            {this.state.currentEditingAsset?.type === 'file' && (
                                <React.Fragment>
                                    <Dialog
                                        fullWidth
                                        maxWidth="sm"
                                        open={!!this.state.currentEditingAsset}
                                        onClose={this.handleAssetEdited}>
                                        <DialogTitle>{getTranslation("assets_editor_edit_asset")}</DialogTitle>
                                        <DialogContent>
                                            <AssetRFormDescriptionUpdateContainer
                                                object={object}
                                                onEdited={this.handleAssetEdited}
                                                initialValues={this.state.currentEditingAsset}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </React.Fragment>
                            )}
                            {this.state.currentEditingAsset?.type === 'video_channel' && (
                                <React.Fragment>
                                    <VideoChannelLinksDialog
                                        open={!!this.state.currentEditingAsset}
                                        title={this.state.currentEditingAsset?.video_channel?.title}
                                        channelId={this.state.currentEditingAsset?.video_channel_id}
                                        onClose={this.handleAssetEdited}
                                    />
                                </React.Fragment>
                            )}
                            {this.state.currentEditingAsset?.type === 'book' && (
                                <React.Fragment>
                                    <BookPagesDialog 
                                        open={!!this.state.currentEditingAsset}
                                        title={this.state.currentEditingAsset?.book?.name}
                                        bookId={this.state.currentEditingAsset?.book_id}
                                        onClose={this.handleAssetEdited}
                                    />
                                    {/*<Dialog
                                        fullWidth
                                        maxWidth="sm"
                                        open={!!this.state.currentEditingAsset}
                                        onClose={this.handleAssetEdited}>
                                        <DialogTitle>{getTranslation("assets_editor_edit_asset")}</DialogTitle>
                                        <DialogContent>
                                            {console.log(this.state.currentEditingAsset)}
                                        </DialogContent>
                                    </Dialog>*/}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const assetsState = assetSelector.getPlayerAssetsState(state);
    return {
        isFetching: RequestStateHelper.isLoading(assetsState),
        assetsOrder: assetSelector.getPlayerAssetsOrder(state),
        orderedAssets: assetSelector.getPlayerOrderedAssets(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAssets: (playerId, unityId) => dispatch(fetchPlayerObjectAssets(playerId, unityId)),
        deleteAsset: (playerId, unityId, assetId) => dispatch(deletePlayerObjectAsset(playerId, unityId, assetId)),
        sortAssets: (playerId, unityId, sortParams) => dispatch(sortPlayerObjectAsset(playerId, unityId, sortParams)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AssetsEditor));
