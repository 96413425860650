import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { reduxForm, SubmissionError } from 'redux-form';
import { Button, Card, CardContent } from '@material-ui/core';
import { updateUser, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';
import { displayAlert } from 'reducers/alerts';
import InstitutionRForm from 'containers/users/InstitutionRForm';

const styles = {};

const Form = reduxForm({
    form: 'institutionForm',
    validate: InstitutionRForm.validate,
    enableReinitialize: true, //Avoid sending two times the token
    onSubmit: (values, dispatch) => {
        return dispatch(updateUser(values))
            .then(() => {
                dispatch(displayAlert('Institution updated !'));
            })
            .catch((error) => {
                if (error.isException(Exceptions.ValidationException)) {
                    const errors = validationErrorToSubmissionError(error.getData());
                    throw new SubmissionError(errors);
                }
                return Promise.reject(error);
            });
    },
})(InstitutionRForm);

class InstitutionPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, user } = this.props;
        return (
            <CardContent>
                <Form initialValues={user} />
            </CardContent>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default withStyles(styles)(connect(mapStateTopProps)(InstitutionPage));
