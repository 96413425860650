import { hot } from 'react-hot-loader/root';
import React from 'react';
import { render } from 'react-dom';
import configureStore, { history } from './store/configureStore';
import Root from 'containers/Root';
import './styles/styles.scss';
import 'normalize.css';
import 'url-search-params-polyfill';
import './outdatedBrowser';
export const store = configureStore();
const App = () => <Root store={store} history={history} />;
const HotApp = hot(App);
render(<HotApp />, document.getElementById('app'));
