import React from 'react';
import mapError from 'ui/base/mapError';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

class ValidatedDateField extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { input, meta, ...otherProps } = this.props;
        const errorProps = mapError(this.props);
        return (
            <DatePicker
                disableFuture
                openTo="year"
                format="LL"
                views={['year', 'month', 'date']}
                {...input}
                {...otherProps}
                {...errorProps}
                value={input.value ? moment(input.value) : null}
                onChange={(val) => input.onChange(val.utc().toISOString())}
                onBlur={() => input.onBlur(input.value)}
            />
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default ValidatedDateField;
