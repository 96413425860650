import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { AppBar, Button, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import MentiaCompanionLogo from 'images/MentiaCompanion2024.png';
import WelcomeMessage from 'containers/users/WelcomeMessage';
import LogoutButton from 'containers/users/LogoutButton';
import Link from 'ui/base/Link';
import { usersSelector } from 'reducers/users';
import connect from 'react-redux/es/connect/connect';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    flex: {
        flex: 1,
    },
    logo: {
        marginRight: 20,
    },
    icon: {
        verticalAlign: 'middle',
    },
    avatar: {
        marginRight: 5,
    },
    drawerPaper: {
        width: '90%',
    },
};

class Header extends React.Component {
    /**
     * Component state
     */
    state = {
        videoAnchor: null,
        videoMobileMenuOpen: false,
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ open: false });
        }
    }

    handleVideoMenuClick = (event) => {
        this.setState({ videoAnchor: this.state.videoAnchor ? null : event.currentTarget });
    };

    handleVideoMobileMenuClick = (event) => {
        this.setState({ videoMobileMenuOpen: !this.state.videoMobileMenuOpen });
    };

    renderVideosMenu() {
        return [
            <Link to={`/video/setup-player`}>
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <MenuItem onClick={this.handleVideoMenuClick}>{getTranslation("howto_videos_setup_player")}</MenuItem>
                    )}
                </LocaleConsumer>
            </Link>,
            <Link to={`/video/start-session`}>
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <MenuItem onClick={this.handleVideoMenuClick}>{getTranslation("howto_videos_start_session")}</MenuItem>
                    )}
                </LocaleConsumer>
            </Link>,
            <Link to={`/video/play-deva`}>
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <MenuItem onClick={this.handleVideoMenuClick}>{getTranslation("howto_videos_play")}</MenuItem>
                    )}
                </LocaleConsumer>
            </Link>,
            <Link to={`/video/end-session`}>
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <MenuItem onClick={this.handleVideoMenuClick}>{getTranslation("howto_videos_end_session")}</MenuItem>
                    )}
                </LocaleConsumer>
            </Link>,
        ];
    }

    renderMainMenu() {
        const { user } = this.props;
        const { videoAnchor } = this.state;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <React.Fragment>
                        <Link to={`/`}>
                            <Button color="inherit">{user.is_institution ? getTranslation("menu_admin") : getTranslation("menu_home")}</Button>
                        </Link>
                        {/* {<Link to={`/quick-start`}>
                            <Button color="inherit">{getTranslation("menu_quick_start")}</Button>
                        </Link>} */}
                        {/* {<Button color="inherit" onClick={this.handleVideoMenuClick}>
                            {getTranslation("menu_how_to_videos")}
                        </Button>} */}
                        {/* {<Link to={`/tips`}>
                            <Button color="inherit">{getTranslation("menu_tips")}</Button>
                        </Link>} */}
                        <Link to={`/account`}>
                            <Button color="inherit">{getTranslation("menu_my_account")}</Button>
                        </Link>
                        <Menu anchorEl={videoAnchor} open={Boolean(videoAnchor)} onClose={this.handleVideoMenuClick}>
                            {this.renderVideosMenu()}
                        </Menu>
                    </React.Fragment>
                )}
            </LocaleConsumer>
        );
    }

    renderMobileMenu() {
        const { classes, user } = this.props;
        const { open, videoMobileMenuOpen } = this.state;
        return (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                    open={open}
                    onClose={() => this.setState({ open: false })}>
                    <div>
                        <IconButton onClick={() => this.setState({ open: false })}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <Link to={`/`}>
                            <ListItem button>
                                <ListItemText>{user.is_institution ? 'Administration' : 'Home'}</ListItemText>
                            </ListItem>
                        </Link>
                        {/* {<Link to={`/quick-start`}>
                            <ListItem button>
                                <ListItemText>Quick start</ListItemText>
                            </ListItem>
                        </Link>} */}
                        {/* {<ListItem button onClick={this.handleVideoMobileMenuClick}>
                            <ListItemText primary="How-to videos" />
                            {videoMobileMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>} */}
                        {/* {<Collapse in={videoMobileMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {this.renderVideosMenu()}
                            </List>
                        </Collapse>} */}
                        {/* {<Link to={`/tips`}>
                            <ListItem button>
                                <ListItemText>Hints and Tips</ListItemText>
                            </ListItem>
                        </Link>} */}
                        <Link to={`/account`}>
                            <ListItem button>
                                <ListItemText>My account</ListItemText>
                            </ListItem>
                        </Link>
                    </List>
                </Drawer>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => this.setState({ open: !open })}
                    edge="start">
                    <MenuIcon />
                </IconButton>
            </React.Fragment>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <AppBar position="static" color="secondary">
                <Toolbar>
                    <Hidden mdUp>{this.renderMobileMenu()}</Hidden>
                    <Typography variant="h6" color="inherit" className={classes.logo}>
                        <Link to={`/`}>
                            <img src={MentiaCompanionLogo} alt="" height={35} />
                        </Link>
                    </Typography>
                    <Hidden smDown>
                        <WelcomeMessage />
                    </Hidden>
                    <div className={classes.flex} />
                    <Hidden smDown>{this.renderMainMenu()}</Hidden>
                    <LogoutButton />
                </Toolbar>
            </AppBar>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default compose(withStyles(styles), withRouter, connect(mapStateTopProps))(Header);
