export default (array, newIndex, oldIndex) => {
    if (oldIndex === newIndex) return array.slice(0);

    const newArray = [];
    let direction = newIndex < oldIndex ? 1 : -1,
        offset = newIndex < oldIndex ? 1 : 0,
        min = Math.min(newIndex, oldIndex),
        max = Math.max(newIndex, oldIndex);

    for (let i = 0; i < array.length; i++) {
        if (i < min || i > max) newArray[i] = array[i];
        else if (i === newIndex) newArray[i] = array[oldIndex];
        else if (i >= min + offset && i < max + offset) newArray[i] = array[i - direction];
    }

    return newArray;
};
