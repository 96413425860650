import { UPDATE_PLAYER_TRANSFERT_SUCCESS } from 'reducers/playerTransferts';
import {
    fetchUserPlayers,
    PLAYER_CREATE_SUCCESS,
    PLAYER_DELETE_SUCCESS,
    PLAYER_UPDATE_SUCCESS,
    PLAYER_SHARE_SUCCESS,
} from 'reducers/players';
import {
    ADD_INSTITUTION_USER_SUCCESS,
    DELETE_CURRENT_USER_SUCCESS,
    fetchInstitutionUsers,
    getUser,
    logout,
    REMOVE_INSTITUTION_USER_SUCCESS,
    USER_CONFIRM_NEW_EMAIL_SUCCESS,
    usersSelector,
} from 'reducers/users';
import { combineEpics } from 'redux-observable';
import { push } from 'connected-react-router';
import { filter, map } from 'rxjs/operators';
import { USERPLAYER_DELETE_SUCCESS } from '../userPlayers';
import { fetchUserPlayer } from '../players';
import { fetchPlayerUsers } from '../users';

const transfertEpic = (action$, state$) =>
    action$
        .pipe(filter((action) => action.type === UPDATE_PLAYER_TRANSFERT_SUCCESS))
        .pipe(map((action) => fetchUserPlayers(usersSelector.getCurrentUser(state$.value).id)));

const refreshUser = (action$, state$) =>
    action$
        .pipe(
            filter(
                (action) =>
                    [
                        PLAYER_CREATE_SUCCESS,
                        ADD_INSTITUTION_USER_SUCCESS,
                        REMOVE_INSTITUTION_USER_SUCCESS,
                        USERPLAYER_DELETE_SUCCESS,
                        USER_CONFIRM_NEW_EMAIL_SUCCESS,
                    ].indexOf(action.type) > -1
            )
        )
        .pipe(map((action) => getUser(usersSelector.getCurrentUser(state$.value).id)));

const refreshPlayer = (action$, state$) =>
    action$
        .pipe(filter((action) => action.type === PLAYER_UPDATE_SUCCESS))
        .pipe(
            map((action) => fetchUserPlayer(usersSelector.getCurrentUser(state$.value).id, action.normalized.result))
        );

const refreshPlayerUser = (action$, state$) =>
    action$
        .pipe(filter((action) => action.type === PLAYER_SHARE_SUCCESS))
        .pipe(map((action) => fetchPlayerUsers(action.playerId)));

const refreshInsitutionList = (action$, state$) =>
    action$
        .pipe(
            filter(
                (action) => [ADD_INSTITUTION_USER_SUCCESS, REMOVE_INSTITUTION_USER_SUCCESS].indexOf(action.type) > -1
            )
        )
        .pipe(map((action) => fetchInstitutionUsers(usersSelector.getCurrentUser(state$.value).id)));

const cancelAccountEpic = (action$, state$) =>
    action$.pipe(filter((action) => action.type === DELETE_CURRENT_USER_SUCCESS)).pipe(map(() => logout()));

const playerDeleteEpic = (action$, state$) =>
    action$.pipe(filter((action) => action.type === PLAYER_DELETE_SUCCESS)).pipe(map(() => push('/')));

export default combineEpics(
    transfertEpic,
    cancelAccountEpic,
    playerDeleteEpic,
    refreshUser,
    refreshPlayer,
    refreshPlayerUser,
    refreshInsitutionList
);
