import 'outdatedbrowser/outdatedbrowser/outdatedbrowser.min.css';
import outdatedBrowser from 'exports-loader?outdatedBrowser!outdatedbrowser/outdatedbrowser/outdatedbrowser';
import outdatedBrowserLang from 'file-loader!outdatedbrowser/outdatedbrowser/lang/en.html';

function addLoadEvent(func) {
    window.addEventListener('load', () => func());
}

if (typeof Promise === 'undefined') {
    addLoadEvent(function () {
        outdatedBrowser({
            bgColor: '#f25648',
            color: '#ffffff',
            lowerThan: true,
            languagePath: outdatedBrowserLang,
        });
    });
}
