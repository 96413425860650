import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    RequestStateHelper,
    Strategies,
} from 'reducers/createRequestReducer';
import assetSchema from 'reducers/schemas/asset';
import { normalize } from 'normalizr';
import { objectToFormData } from 'utils/FormUtils';
import { map, keyBy, values, sortBy, concat, filter } from 'lodash-es';

export const assetSelector = {
    getPlayerAssetsState: (state) => state.assets.playerAssets,
    getPlayerAssetsOrder: (state) => {
        const { current, optimistic } = state.assets.playerAssetsSort;
        return optimistic || current;
    },
    getPlayerOrderedAssets: (state) => {
        const { current, optimistic } = state.assets.playerAssetsSort;
        const order = optimistic || current;

        const assetsById = keyBy(RequestStateHelper.getData(state.assets.assets), 'id');
        return map(order, (id) => assetsById[id]);
    },
};

const PLAYER_OBJECT_ASSETS = 'PLAYER_OBJECT_ASSETS';
const PLAYER_OBJECT_ASSETS_SUCCESS = 'PLAYER_OBJECT_ASSETS_SUCCESS';
const PLAYER_OBJECT_ASSETS_ERROR = 'PLAYER_OBJECT_ASSETS_ERROR';

const REPLACE_PLAYER_OBJECT_ASSETS = 'REPLACE_PLAYER_OBJECT_ASSETS';
const REPLACE_PLAYER_OBJECT_ASSETS_SUCCESS = 'REPLACE_PLAYER_OBJECT_ASSETS_SUCCESS';
const REPLACE_PLAYER_OBJECT_ASSETS_ERROR = 'REPLACE_PLAYER_OBJECT_ASSETS_ERROR';

const CREATE_PLAYER_OBJECT_ASSET = 'CREATE_PLAYER_OBJECT_ASSET';
const CREATE_PLAYER_OBJECT_ASSET_SUCCESS = 'CREATE_PLAYER_OBJECT_ASSET_SUCCESS';
const CREATE_PLAYER_OBJECT_ASSET_ERROR = 'CREATE_PLAYER_OBJECT_ASSET_ERROR';

const UPDATE_PLAYER_OBJECT_ASSET = 'UPDATE_PLAYER_OBJECT_ASSET';
const UPDATE_PLAYER_OBJECT_ASSET_SUCCESS = 'UPDATE_PLAYER_OBJECT_ASSET_SUCCESS';
const UPDATE_PLAYER_OBJECT_ASSET_ERROR = 'UPDATE_PLAYER_OBJECT_ASSET_ERROR';

const DELETE_PLAYER_OBJECT_ASSET = 'DELETE_PLAYER_OBJECT_ASSET';
const DELETE_PLAYER_OBJECT_ASSET_SUCCESS = 'DELETE_PLAYER_OBJECT_ASSET_SUCCESS';
const DELETE_PLAYER_OBJECT_ASSET_ERROR = 'DELETE_PLAYER_OBJECT_ASSET_ERROR';

const SORT_PLAYER_OBJECT_ASSET = 'SORT_PLAYER_OBJECT_ASSET';
const SORT_PLAYER_OBJECT_ASSET_SUCCESS = 'SORT_PLAYER_OBJECT_ASSET_SUCCESS';
const SORT_PLAYER_OBJECT_ASSET_ERROR = 'SORT_PLAYER_OBJECT_ASSET_ERROR';

let currentId = 0;

export const fetchPlayerObjectAssets = (playerId, unityId) => (dispatch) => {
    let operationId = currentId++;
    dispatch({ operationId, type: PLAYER_OBJECT_ASSETS });
    return axiosInstance
        .get(`/players/${playerId}/unity_objects/${unityId}/assets`)
        .then(function (response) {
            dispatch({
                operationId,
                type: PLAYER_OBJECT_ASSETS_SUCCESS,
                normalized: normalize(response.data.data, [assetSchema]),
            });
            return response;
        })
        .catch(function (error) {
            dispatch({ operationId, type: PLAYER_OBJECT_ASSETS_ERROR, error: error.toObject() });
            return Promise.reject(error);
        });
};

export const createPlayerObjectAsset = (playerId, unityId, values) => (dispatch) => {
    dispatch({ type: CREATE_PLAYER_OBJECT_ASSET });

    return axiosInstance
        .post(`/players/${playerId}/unity_objects/${unityId}/assets`, objectToFormData(values))
        .then(function (response) {
            const normalized = normalize(response.data.data, assetSchema);
            dispatch({
                type: CREATE_PLAYER_OBJECT_ASSET_SUCCESS,
                normalized,
                id: normalized.result,
            });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: CREATE_PLAYER_OBJECT_ASSET_ERROR, error: error.toObject() });
            return Promise.reject(error);
        });
};

export const replacePlayerObjectAsset = (playerId, unityId, assets) => (dispatch) => {
    dispatch({ type: REPLACE_PLAYER_OBJECT_ASSETS });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/players/${playerId}/unity_objects/${unityId}/assets`, assets),
        [assetSchema],
        [REPLACE_PLAYER_OBJECT_ASSETS_SUCCESS, REPLACE_PLAYER_OBJECT_ASSETS_ERROR],
        dispatch
    );
};

export const sortPlayerObjectAsset = (playerId, unityId, { movedId, newPosition, newOrder }) => (dispatch) => {
    dispatch({
        type: SORT_PLAYER_OBJECT_ASSET,
        data: {
            newOrder,
        },
    });

    return axiosInstance
        .put(`/players/${playerId}/unity_objects/${unityId}/assets/${movedId}`, { position: newPosition })
        .then(function (response) {
            dispatch({
                type: SORT_PLAYER_OBJECT_ASSET_SUCCESS,
            });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: SORT_PLAYER_OBJECT_ASSET_ERROR, error: error.toObject() });
            return Promise.reject(error);
        });
};

export const deletePlayerObjectAsset = (playerId, unityId, id) => (dispatch) => {
    dispatch({
        type: DELETE_PLAYER_OBJECT_ASSET,
        id,
    });
    return axiosInstance
        .delete(`/players/${playerId}/unity_objects/${unityId}/assets/${id}`)
        .then(function (response) {
            dispatch({
                type: DELETE_PLAYER_OBJECT_ASSET_SUCCESS,
                id,
            });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: DELETE_PLAYER_OBJECT_ASSET_ERROR, id, error: error.toObject() });
            return Promise.reject(error);
        });
};

export const updatePlayerObjectAsset = (playerId, unityId, id, values) => (dispatch) => {
    dispatch({
        type: UPDATE_PLAYER_OBJECT_ASSET,
        id,
    });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/players/${playerId}/unity_objects/${unityId}/assets/${id}`, values),
        assetSchema,
        [UPDATE_PLAYER_OBJECT_ASSET_SUCCESS, UPDATE_PLAYER_OBJECT_ASSET_ERROR],
        dispatch
    );
};

export default combineReducers({
    assets: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('assets'),
        defaultStrategy: Strategies.merge,
    }),
    playerAssets: createRequestReducer(
        [PLAYER_OBJECT_ASSETS, PLAYER_OBJECT_ASSETS_SUCCESS, PLAYER_OBJECT_ASSETS_ERROR],
        { ignoreData: true, useOperationId: true }
    ),
    playerAssetsSort: (
        state = {
            current: null,
            optimistic: null,
            isUpdating: false,
        },
        action
    ) => {
        const getOrdered = (entitiesByKey) => {
            return values(map(sortBy(values(entitiesByKey || {}), 'position'), 'id'));
        };

        switch (action.type) {
            case PLAYER_OBJECT_ASSETS: {
                return {
                    current: [],
                    optimistic: null,
                    isUpdating: false,
                };
            }
            case PLAYER_OBJECT_ASSETS_SUCCESS: {
                return {
                    current: getOrdered(action.normalized.entities.assets),
                    optimistic: null,
                    isUpdating: false,
                };
            }
            case CREATE_PLAYER_OBJECT_ASSET_SUCCESS: {
                return {
                    current: concat(state.current, action.id),
                    optimistic: null,
                    isUpdating: false,
                };
            }
            case REPLACE_PLAYER_OBJECT_ASSETS_SUCCESS: {
                return {
                    current: action.normalized.result,
                    optimistic: null,
                    isUpdating: false,
                };
            }
            case DELETE_PLAYER_OBJECT_ASSET: {
                return {
                    ...state,
                    isUpdating: true,
                    optimistic: filter(state.current, (value) => value !== action.id),
                };
            }
            case SORT_PLAYER_OBJECT_ASSET: {
                return {
                    ...state,
                    isUpdating: true,
                    optimistic: action.data.newOrder,
                };
            }
            //COMMIT
            case DELETE_PLAYER_OBJECT_ASSET_SUCCESS:
            case SORT_PLAYER_OBJECT_ASSET_SUCCESS: {
                return {
                    ...state,
                    current: state.optimistic || state.current,
                    isUpdating: false,
                    optimistic: null,
                };
            }
            //REVERT
            case DELETE_PLAYER_OBJECT_ASSET_ERROR:
            case SORT_PLAYER_OBJECT_ASSET_ERROR: {
                return {
                    ...state,
                    isUpdating: false,
                    optimistic: null,
                };
            }
        }

        return state;
    },
});
