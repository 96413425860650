/* 
Authors: Gaetan Senelle, Alexander Tillay, Sebastien Le Maire, Niklaas Cotta
Last modified: 10/13/2023
Desc: Object icons
*/

import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import BrushIcon from "@material-ui/icons/Brush";
import RadioIcon from "@material-ui/icons/Radio";


function ObjectTypeIcon({ classes, type, ...otherProps }){

    switch(type) {
        case "image":
            return <ImageIcon {...otherProps} />;
        case "images":
            return <LibraryBooks {...otherProps} />;
        case "audio":
            return <AudiotrackIcon {...otherProps} />;
        case "video_channel":
            return <VideoLibrary {...otherProps} />;
        case "paintings":
            return <BrushIcon {...otherProps} />;
        case "music_tracks":
            return <RadioIcon {...otherProps} />;
        default:
            return null;
    }   
}

export default ObjectTypeIcon;