/* 
Author: Niklaas Cotta
Last modified: 7/14/2023
Description: obtain book pages

| type    | column      | description |
|---------|-------------|-------------|
| int     | `id`        |
| int     | `book_id`   |
| string  | `file`      |
| string  | `file_type` |
| string  | `file_dir`  |
| boolean | `cover`     |
| string  | `file_url`  |
*/

import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import bookPageSchema from 'reducers/schemas/bookPage';


// Helper function
// Desc: get state of and pages of books
export const bookPagesSelector = {
    getCurrentBookPagesState: (state) => state.bookPages.currentBookPages,
    getCurrentBookPages: (state) => NormalizedSelector.getByIds(state.bookPages.bookPages, state.bookPages.currentBookPages),
};

const CURRENT_BOOK_PAGES = 'CURRENT_BOOK_PAGES';
const CURRENT_BOOK_PAGES_SUCCESS = 'CURRENT_BOOK_PAGES_SUCCESS';
const CURRENT_BOOK_PAGES_ERROR = 'CURRENT_BOOK_PAGES_ERROR';


// Helper function
// Connect to backend with axios and obtain book page objects
export const fetchBookPages = (bookPageId) => (dispatch) => {
    dispatch({ type: CURRENT_BOOK_PAGES });

    return dispatchNormalizedPromise(
        axiosInstance.get(`/books/${bookPageId}/book_pictures`),
        [bookPageSchema],
        [CURRENT_BOOK_PAGES_SUCCESS, CURRENT_BOOK_PAGES_ERROR],
        dispatch
    );
};

export default combineReducers({
    bookPages: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('bookPages'),
        defaultStrategy: Strategies.merge,
    }),
    currentBookPages: createRequestReducer([CURRENT_BOOK_PAGES, [CURRENT_BOOK_PAGES_SUCCESS], CURRENT_BOOK_PAGES_ERROR], { 
        mapResponse: NormalizedActionMap.result 
    })
});