/*
Author: Gaetan Senelle, Alexander Tillay, Niklaas Cotta
Last Modified: 8/9/2023
Description: New UI theme
*/

import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import { createTheme } from '@material-ui/core/styles';

export default createTheme({
    palette: {
        primary: orange,
        secondary: blue,
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 10,
            },
            elevation1: {
                boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            },
            elevation4: {
                boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: '#ffffff',
                boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 5,
                color: 'rgba(0, 0, 0, 0.87)',
            },
        },
        MuiCard: {
            root: {
                overflow: 'visible',
            },
        },
        MuiCardContent: {
            root: {
                paddingLeft: 24,
                paddingRight: 24,
            },
        },
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: '#ffffff',
                color: '#3f3f3f',
            },
        },
    },
    typography: {
        useNextVariants: true,
    },
});
