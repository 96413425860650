import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class PlayerGender extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    genders = {
        0: 'other',
        1: 'male',
        2: 'female',
    };

    /**
     * Render component
     */
    render() {
        const { gender } = this.props;
        return <LocaleConsumer>
            {({ getTranslation }) => (
                <Typography>{getTranslation("player_edit_display_gender_" + this.genders[gender])}</Typography>
            )}
        </LocaleConsumer>
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PlayerGender);
