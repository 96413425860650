import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { fetchInstitutionPlayersWithStats, playersSelector } from 'reducers/players';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import SessionsRatingMinistat from 'containers/sessions/SessionsRatingMinistat';
import SessionsPartOfDayMinistat from 'containers/sessions/SessionsPartOfDayMinistat';
import TimerIcon from '@material-ui/icons/Timer';
import moment from 'moment';
import { some } from 'lodash-es';
import Link from 'ui/base/Link';
import {FormattedNumber} from 'react-intl';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {
    main: {
        '& > *': {
            marginBottom: 20,
        },
    },
    playerCard: {
        marginBottom: 15,
    },
};

class InstitutionPlayersStatsList extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchPlayer(this.props);
    }

    componentDidUpdate(prevProps) {
        const { institutionId, from, to } = prevProps;
        const { institutionId: nextinstitutionId, from: nextFrom, to: nextTo } = this.props;
        if (institutionId !== nextinstitutionId || from !== nextFrom || to !== nextTo) {
            this.props.fetchPlayer(this.props);
        }
    }

    renderPlayersList() {
        const { classes, players, isRefetching } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Table style={{ opacity: isRefetching ? 0.5 : 1 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{getTranslation("institution_stats_players_name")}</TableCell>
                                <TableCell>{getTranslation("institution_stats_players_sessions_care")}</TableCell>
                                <TableCell>{getTranslation("institution_stats_players_sessions_family")}</TableCell>
                                <TableCell>{getTranslation("institution_stats_players_how")}</TableCell>
                                <TableCell>{getTranslation("institution_stats_players_time")}</TableCell>
                                <TableCell>{getTranslation("institution_stats_players_average_duration")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {players.map((player) => {
                                if (!player.stats_with_institution) {
                                    return (
                                        <TableRow key={player.id}>
                                            <TableCell>{player.display_name}</TableCell>
                                            <TableCell>0</TableCell>
                                            <TableCell>
                                                {player.stats_with_family ? player.stats_with_family.count : '0'}
                                            </TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                        </TableRow>
                                    );
                                }
                                const durationInMinutes = moment
                                    .duration(player.stats_with_institution.average_duration_second, 'seconds')
                                    .asMinutes()
                                return (
                                    <TableRow key={player.id}>
                                        <TableCell>{player.display_name}</TableCell>
                                        <TableCell>{player.stats_with_institution.count}</TableCell>
                                        <TableCell>{player.stats_with_family ? player.stats_with_family.count : '0'}</TableCell>
                                        <TableCell>
                                            <SessionsRatingMinistat
                                                r1Count={player.stats_with_institution.rating1_count}
                                                r2Count={player.stats_with_institution.rating2_count}
                                                r3Count={player.stats_with_institution.rating3_count}
                                                total={player.stats_with_institution.count}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <SessionsPartOfDayMinistat
                                                morningCount={player.stats_with_institution.morning_count}
                                                afternoonCount={player.stats_with_institution.afternoon_count}
                                                eveningCount={player.stats_with_institution.evening_count}
                                                total={player.stats_with_institution.count}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TimerIcon style={{ verticalAlign: 'middle' }} />
                                            <FormattedNumber maximumFractionDigits={0} value={durationInMinutes} /> {getTranslation("minutes")}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/player/${player.id}/sessions`}>
                                                <Button>{getTranslation("institution_stats_players_details")}</Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { isFetching } = this.props;
        return (
            <div>
                {isFetching && <FullWidthLoader />}
                {!isFetching && this.renderPlayersList()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const getContain = ({ from, to, institutionId }) => {
    return {
        stats_with_family: {
            contain: 'SessionsStat',
            from: from && from.toISOString(),
            to: to && to.toISOString(),
        },
        stats_with_institution: {
            contain: 'SessionsStat',
            from: from && from.toISOString(),
            to: to && to.toISOString(),
            institutionId: institutionId,
        },
    };
};

const mapStateToProps = (state, ownProps) => {
    const playerState = playersSelector.getInstitutionPlayersState(state);
    const players = playersSelector.getInstitutionPlayersWithStats(state, getContain(ownProps));
    return {
        isFetching: !players.length && RequestStateHelper.isLoading(playerState),
        isRefetching:
            players.length && !some(players, 'stats_with_institution') && RequestStateHelper.isLoading(playerState),
        players,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlayer: (props) => dispatch(fetchInstitutionPlayersWithStats(props.institutionId, getContain(props))),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstitutionPlayersStatsList));
