import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Card, CardHeader } from '@material-ui/core';
import PlayerAvatar from 'containers/players/PlayerAvatar';
import DefaultError from 'ui/base/DefaultError';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {
    buttons: {
        '& > *': {
            marginRight: 15,
        },
    },
};

class PlayerCardPending extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleAcceptClick = (event) => {
        this.props.onAcceptClick && this.props.onAcceptClick(event);
    };

    handleRejectClick = (event) => {
        this.props.onRejectClick && this.props.onRejectClick(event);
    };

    preventClick = (event) => event.preventDefault();

    /**
     * Render component
     */
    render() {
        const { classes, player, error, hideActions, disableActions } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Card style={{ display: 'flex', alignItems: 'center' }} onClick={this.preventClick}>
                        <div>
                            <CardHeader
                                title={getTranslation("player_card_pending_accept_to_be_a_member", player.display_name)}
                                avatar={<PlayerAvatar player={player} />}
                            />
                            {error && (
                                <DefaultError>
                                    {error}
                                    <br />
                                </DefaultError>
                            )}
                        </div>
                        <div style={{ flex: 1 }} />
                        {!hideActions && (
                            <div className={classes.buttons}>
                                <Button
                                    variant="contained"
                                    color={'secondary'}
                                    onClick={this.handleAcceptClick}
                                    style={{ marginBottom: 5 }}
                                    disabled={disableActions}>
                                    {getTranslation("yes")}
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ marginBottom: 5 }}
                                    onClick={this.handleRejectClick}
                                    disabled={disableActions}>
                                    {getTranslation("no")}
                                </Button>
                            </div>
                        )}
                    </Card>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PlayerCardPending);
