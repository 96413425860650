import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import validator from 'validator';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import MentiaLogo from 'images/MentiaLogo2024.jpg';
import Link from 'ui/base/Link';
import { userJoin } from 'reducers/users';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { push } from 'connected-react-router';
import { responseDataMap } from 'reducers/createRequestReducer';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import DefaultError from 'ui/base/DefaultError';
import { ContentComponent } from 'containers/ContentComponents';
import { LocaleConsumer } from 'containers/locale/LocaleProvider';
import PasswordField from "ui/base/PasswordField";

const styles = {
    row: {
        height: '100%',
        width: '100%',
        margin: 0,
        textAlign: 'left',
        overflowY: 'auto',
    },
    column: {
        margin: 'auto',
        paddingTop: 20,
        paddingBottom: 20,
    },
};

class JoinPage extends React.Component {
    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        const { submitting, classes, error, handleSubmit, confirmationKey } = this.props;
        if (!confirmationKey) return <Redirect to={'/'} />;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Row className={classes.row} center="xs">
                        <Col xs={12} sm={11} md={10} lg={6} className={classes.column}>
                            <div>
                                <Card>
                                    <CardContent>
                                        <div className={classes.form}>
                                            <Typography align="center">
                                                <img src={MentiaLogo} height={50} alt="" />
                                            </Typography>
                                            <br />
                                            <Typography variant="h6" gutterBottom>
                                                {getTranslation("signup_greetings_intro")}
                                            </Typography>
                                            <br />
                                            <Typography>
                                                {getTranslation("signup_greetings_desc")}
                                            </Typography>
                                            <br />
                                            <Typography>
                                                {getTranslation("signup_greetings_outro")}
                                            </Typography>
                                            {error && <DefaultError>{error}</DefaultError>}
                                            <br />
                                            <Field
                                                type="text"
                                                name="email"
                                                label={getTranslation("email")}
                                                component={ValidatedTextField}
                                                disabled={!!confirmationKey}
                                                autoFocus
                                                required
                                                fullWidth
                                            />
                                            <br />
                                            <Field
                                                type="text"
                                                name="first_name"
                                                label={getTranslation("first_name")}
                                                component={ValidatedTextField}
                                                required
                                                fullWidth
                                            />
                                            <br />
                                            <Field
                                                type="text"
                                                name="last_name"
                                                label={getTranslation("last_name")}
                                                component={ValidatedTextField}
                                                required
                                                fullWidth
                                            />
                                            <br />
                                            <Field
                                                name="password"
                                                label={getTranslation("password")}
                                                component={PasswordField}
                                                required
                                                fullWidth
                                                progress
                                            />
                                            <Field
                                                name="password_confirm"
                                                label={getTranslation("user_confirm_password")}
                                                component={PasswordField}
                                                required
                                                fullWidth
                                            />
                                        </div>
                                    </CardContent>
                                    <CardContent>
                                        <ContentComponent componentName="Auth.AcceptTermsOfService" />
                                    </CardContent>
                                    <CardActions>
                                        <div style={{ flex: 1 }} />
                                        <Button color="secondary" onClick={handleSubmit} disabled={submitting}>
                                            {getTranslation("signup")}
                                        </Button>
                                    </CardActions>
                                </Card>
                                <Typography style={{ textAlign: 'center', marginTop: 15 }}>
                                    {getTranslation("signup_copyright")}{' '}
                                    <Link styled to="http://mentia.me">
                                        Mentia
                                    </Link>
                                </Typography>
                            </div>
                        </Col>
                    </Row>
                )}
            </LocaleConsumer>
        );
    }

    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const query = new URLSearchParams(ownProps.location.search);
    const confirmationKey = query.get('key');
    const hideWelcome = !!Number(query.get('neutral'));
    const defaultEmail = confirmationKey ? jwtDecode(confirmationKey).email : '';

    return {
        confirmationKey,
        hideWelcome,
        initialValues: {
            email: defaultEmail,
        },
    };
};

export default withStyles(styles)(
    withRouter(
        connect(mapStateToProps)(
            reduxForm({
                form: 'join',
                validate: (values) => {
                    const errors = {};

                    if (!validator.isEmail(values.email || '')) {
                        errors.email = 'Invalid email';
                    }

                    if (!values.first_name) {
                        errors.first_name = 'Required';
                    }

                    if (!values.last_name) {
                        errors.last_name = 'Required';
                    }

                    if (!values.password) {
                        errors.password = 'Required';
                    } else if (!validator.isLength(values.password, { min: 6 })) {
                        errors.password = 'Password must be at least 6 characters long';
                    }

                    if (!values.password_confirm) {
                        errors.password_confirm = 'Required';
                    } else if (values.password !== values.password_confirm) {
                        errors.password_confirm = 'Your password and confirmation password do not match';
                    }

                    return errors;
                },
                onSubmit: (values, dispatch, ownProps) => {
                    const query = new URLSearchParams(ownProps.location.search);
                    const _key = query.get('key');

                    return dispatch(userJoin({ _key, ...values }))
                        .then((response) => {
                            const data = responseDataMap(response);
                            const params = {
                                email: values.email,
                                isAfterRegistration: true,
                                isAlreadyActivated: data.isAlreadyActivated,
                            };

                            if (data.isAlreadyActivated) {
                                dispatch(push('/login', params));
                            } else {
                                dispatch(push('/join-success', params));
                            }
                        })
                        .catch((error) => {
                            if (error.isException(Exceptions.ValidationException)) {
                                const errors = validationErrorToSubmissionError(error);
                                throw new SubmissionError(errors);
                            }
                            return Promise.reject(error);
                        });
                },
            })(JoinPage)
        )
    )
);
