import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Link from 'ui/base/Link';
import { Typography } from '@material-ui/core';
import TermsOfUseLink from 'containers/contents/TermsOfUseLink';
import PrivacyPolicyLink from 'containers/contents/PrivacyPolicyLink';
import MainConfig from 'constants/MainConfig';
import JulieHead from 'images/julie_head.png';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const components = {
    'UserAlerts.CantPlayAlert': (props) => {
        return (
            <span>
                <ErrorIcon style={{ verticalAlign: 'middle' }} />
                Oops, please update your payment settings on
                <b>
                    {' '}
                    <Link to={`/account`}>My Account</Link>{' '}
                </b>
                to continue playing
            </span>
        );
    },
    'Home.MyPlayersText': () => <span>Here is where you manage your players.</span>,
    'Home.SharedPlayersText': () => <span>Players shared with you by other users.</span>,
    'TransfertsList.TransfertsListText': () => <span>Transferts</span>,
    'User.CancelAccount': ({ players }) => (
        <Typography>
            Are you sure ? If you cancel, all your players ({players.map((player) => player.display_name).join(', ')})
            won't be able to play anymore. Maybe you just want to unsubscribe a particular player or handover their
            subscription to someone else. Go to your Player Profile to manage this.
        </Typography>
    ),
    'User.CancelSubscription': ({ players }) => (
        <Typography>
            If you cancel your subscription, {players.map((player) => player.display_name).join(', ')} won’t be able to
            play anymore. <br />
            <br />
            You can always handover them to another person of the care circle one by one.
        </Typography>
    ),
    'User.ReactivateSubscription': () => <Typography>Do you want to activate your subscription ?</Typography>,
    'Player.AcceptTermsOfService': () => (
        <Typography>
            By pressing "Create", you accept our <TermsOfUseLink />, and you agree to pay{' '}
            <b>${MainConfig.pricePerPlayer} per month</b>.
        </Typography>
    ),
    'Auth.AcceptTermsOfService': () => (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Typography>
                    {getTranslation("signup_term_of_use")} <TermsOfUseLink /> {getTranslation("and")} <PrivacyPolicyLink />
                </Typography>
            )}
        </LocaleConsumer>
    ),
    'Auth.VillageWelcome': () => (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Typography>
                    <b>{getTranslation("signup_title")}</b>
                    <br />
                    <Typography color="primary">{getTranslation("signup_no_card")}</Typography>
                    {getTranslation("signup_responding_invitation")}<b>{getTranslation("signup_responding_invitation_always")}</b>{getTranslation("signup_responding_invitation_free")}
                </Typography>
            )}
        </LocaleConsumer>
    ),
    'Auth.Welcome': () => (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <Typography>{getTranslation("login_welcome")}</Typography>
                    <Typography align="center">
                        <img src={JulieHead} height={48} alt="" style={{ marginTop: 10 }} />
                    </Typography>
                </React.Fragment>
            )}
        </LocaleConsumer>
    ),
    'Auth.LoginMessage': () => (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Typography>
                    <a className="link" href="https://www.mentia.me">
                        {getTranslation("login_tell_me_more")}
                    </a>
                    <br />
                    <br />
                    {window.location.hostname !== 'betadeva.mentia.me' && (
                        <span>
                            <a
                                className="link"
                                href="https://itunes.apple.com/us/app/deva-dementia-friendly-world/id1241083987">
                                {getTranslation("login_download_ipad")}
                            </a>
                            <br />
                            <a className="link" href="https://play.google.com/store/apps/details?id=me.mentia.deva">
                                {getTranslation("login_download_android")}
                            </a>
                        </span>
                    )}
                </Typography>
            )}
        </LocaleConsumer>
    ),
    'Home.CardMissing': () => (
        <Typography component="span" style={{ display: 'inline' }} color="inherit">
            A card is required to accept a player. Add one in{' '}
            <Link className="link" to={`/account`}>
                your account
            </Link>
        </Typography>
    ),
};

export const ContentComponent = (props) => {
    const { componentName, ...otherProps } = props;
    const Component = components[componentName];
    return Component ? <Component {...otherProps} /> : null;
};

export default {
    getComponent: (name) => {
        return components[name];
    },
};
