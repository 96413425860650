import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PlayerAvatar from 'containers/players/PlayerAvatar';
import { reduxForm, SubmissionError } from 'redux-form';
import { updatePlayer } from 'reducers/players';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import DeletePlayerButton from 'containers/players/DeletePlayerButton';
import { usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import { pick } from 'lodash-es';
import PlayerPhotoEditor from 'containers/players/PlayerPhotoEditor';
import PlayerEditDisplayRForm from 'containers/players/PlayerEditDisplayRForm';
import { Typography } from '@material-ui/core';
import PlayerUsersList from 'containers/users/PlayerUsersList';
import { Col, Row } from 'react-flexbox-grid';
import PlayerStoryDisplayRForm from 'containers/players/PlayerStoryDisplayRForm';
import ExitCareCircleButton from 'containers/players/ExitCareCircleButton';
import PlayerAddCaregiverModal from 'containers/players/PlayerAddCaregiverModal';
import { Button, CardContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'
import moment from "moment";

const styles = {
    profile: {
        '& > *': {
            marginBottom: 25,
        },
    },
};

const PlayerEditForm = reduxForm({
    form: 'editPlayer',
    /**
     * Allow to get updated values from the store from initialValues
     */
    enableReinitialize: true,
    onSubmit: (values, dispatch) => {
        values = pick(values, [
            'id',
            'nickname',
            'first_name',
            'last_name',
            'middle_name',
            'gender',
            'birthday',
        ]);

        return dispatch(updatePlayer(values)).catch((error) => {
            if (error.isException(Exceptions.ValidationException)) {
                const errors = validationErrorToSubmissionError(error.getData());
                throw new SubmissionError(errors);
            }
            throw new SubmissionError({ _error: error.getMessage() });
        });
    },
})(PlayerEditDisplayRForm);

const PlayerStoryForm = reduxForm({
    form: 'editPlayerStory',
    /**
     * Allow to get updated values from the store from initialValues
     */
    enableReinitialize: true,
    onSubmit: (values, dispatch) => {
        values = pick(values, [
            'id',
            'life_story_family',
            'life_story_working',
            'life_story_community',
            'life_story_pleasures',
            'life_story_beliefs',
            'life_story_medias'
        ]);
        return dispatch(updatePlayer(values)).catch((error) => {
            if (error.isException(Exceptions.ValidationException)) {
                const errors = validationErrorToSubmissionError(error.getData());
                throw new SubmissionError(errors);
            }
            throw new SubmissionError({ _error: error.getMessage() });
        });
    },
})(PlayerStoryDisplayRForm);

class PlayerOverviewPage extends React.Component {
    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
    /**
     * Component state
     */
    state = {
        addCaregiverModalOpen: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderPlayerProfileAndUsers() {
        const { classes, player, currentUser, playerUpdatedByUser } = this.props;
        const { addCaregiverModalOpen } = this.state;
        const currentUserIsMainUser = player.main_user_id === currentUser.id;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.profile}>
                        <Typography variant="h6">{getTranslation("player_overview_about", player.nickname)}</Typography>
                        <div>
                            <Row middle="xs">
                                <Col>
                                    <PlayerAvatar player={player} style={{ width: 100, height: 100 }} />
                                </Col>
                                <Col style={{ padding: '0 20px' }}>
                                    <PlayerPhotoEditor player={player} />
                                </Col>
                            </Row>
                        </div>
                        <PlayerEditForm initialValues={player} />
                        <div style={{ marginBottom: 25 }}>
                            {!!playerUpdatedByUser && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: 125 }}>
                                        <Typography>
                                            <b>{getTranslation("player_overview_last_updated_by")}</b>
                                        </Typography>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography>{playerUpdatedByUser.display_name}</Typography>
                                    </div>
                                </div>
                            )}
                            {!!player.updated_at && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: 125 }}>
                                        <Typography>
                                            <b>{getTranslation("player_overview_last_updated_on")}</b>
                                        </Typography>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography>
                                            {moment(player.updated_at).format("LL")}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: 125 }}>
                                    <Typography>
                                        <b>{getTranslation("player_overview_created_on")}</b>
                                    </Typography>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Typography>
                                        {moment(player.created_at).format("LL")}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Typography variant="h6" gutterBottom>
                            {getTranslation("player_overview_care_circle_members")}
                            {currentUser.is_institution && currentUserIsMainUser && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    color={'secondary'}
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => this.setState({ addCaregiverModalOpen: true })}>
                                    <AddIcon /> {getTranslation("player_overview_add_care_staff")}
                                </Button>
                            )}
                            <PlayerAddCaregiverModal
                                open={addCaregiverModalOpen}
                                onRequestClose={() => this.setState({ addCaregiverModalOpen: false })}
                                institutionId={currentUser.id}
                                playerId={player.id}
                            />
                        </Typography>
                        <div>
                            <PlayerUsersList playerId={player.id} />
                        </div>
                        {currentUserIsMainUser && (
                            <div>
                                <DeletePlayerButton playerId={player.id} />
                            </div>
                        )}
                        {!currentUserIsMainUser && (
                            <div>
                                <ExitCareCircleButton user={currentUser} player={player} isCurrentUser={true} />
                            </div>
                        )}
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    renderStory() {
        const { player } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Typography style={{ padding: '0 20px' }} variant="h6">
                            {getTranslation("player_overview_story_title", player.nickname)}
                        </Typography>
                        <Typography style={{ padding: 20 }}>
                            {getTranslation("player_overview_story_desc")}
                        </Typography>
                        <PlayerStoryForm initialValues={player} />
                    </div>
                    )}
            </LocaleConsumer>

        );
    }

    /**
     * Render component
     */
    render() {
        return (
            <CardContent>
                <Row>
                    <Col style={{ padding: '0 15px', width: 400 }}>{this.renderPlayerProfileAndUsers()}</Col>
                    <Col sm={12} md>
                        {this.renderStory()}
                    </Col>
                </Row>
            </CardContent>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: usersSelector.getCurrentUser(state),
        playerUpdatedByUser: usersSelector.getUser(state, ownProps.player.updated_by),
    };
};

export default withStyles(styles)(connect(mapStateToProps)(PlayerOverviewPage));
