import { applyMiddleware, compose, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import rootReducer, { rootEpic } from '../reducers';

export const history = createBrowserHistory();

/**
 *
 * @param initialState
 * @returns {Store<any>}
 */
function configureStoreProd(initialState) {
    const reactRouterMiddleware = routerMiddleware(history);
    const epicMiddleware = createEpicMiddleware();

    const middlewares = [
        // Add other middleware on this line...

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
        thunk,
        reactRouterMiddleware,
        epicMiddleware,
    ];

    const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)));
    epicMiddleware.run(rootEpic);

    return store;
}

/**
 *
 * @param initialState
 * @returns {Store<any>}
 */
function configureStoreDev(initialState) {
    const reactRouterMiddleware = routerMiddleware(history);
    const epicMiddleware = createEpicMiddleware();

    const middlewares = [
        // Add other middleware on this line...

        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or
        // between dispatches.
        reduxImmutableStateInvariant({
            isImmutable: (value) => {
                if (value instanceof File) return true;
                return typeof value !== 'object' || value === null || typeof value === 'undefined';
            },
        }),

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
        thunk,
        reactRouterMiddleware,
        epicMiddleware,
    ];

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    epicMiddleware.run(rootEpic);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        });
    }

    return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
