import axios from 'axios';

export const getEmbedInfo = (url) => {
    return axios
        .get(`${IFRAMELY_URL}iframely?uri=${encodeURIComponent(url)}`)
        .then((v) => {
            return v.data;
        })
        .catch(() => null);
};

export const extractThumbnailUrl = (data) =>
    data.links?.thumbnail?.sort((v1, v2) => v1?.media?.width > v2?.media?.width)?.[0]?.href;
export const extractMeta = (data) => ({
    title: data?.meta?.title,
    description: data?.meta?.description || '',
    site: data?.meta?.site,
    author: data?.meta?.author,
});
