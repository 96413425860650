import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import axiosInstance from 'utils/axiosInstance';
import userPlayer from 'reducers/schemas/userPlayer';

export const userPlayersSelector = {
    getUserPlayer: (state, id) => NormalizedSelector.getById(state.userPlayers.userPlayers, id),
    getUserPlayerDeleteState: (state) => state.userPlayers.userPlayerDelete,
};

const USERPLAYER_UPDATE = 'USERPLAYER_UPDATE';
export const USERPLAYER_UPDATE_SUCCESS = 'USERPLAYER_UPDATE_SUCCESS';
const USERPLAYER_UPDATE_ERROR = 'USERPLAYER_UPDATE_ERROR';

export const updateUserPlayer = (data, userPlayerId) => (dispatch) => {
    userPlayerId = userPlayerId || data.id;

    dispatch({ type: USERPLAYER_UPDATE });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/users_players/${userPlayerId}`, data),
        userPlayer,
        [USERPLAYER_UPDATE_SUCCESS, USERPLAYER_UPDATE_ERROR],
        dispatch
    );
};

const USERPLAYER_DELETE = 'USERPLAYER_DELETE';
export const USERPLAYER_DELETE_SUCCESS = 'USERPLAYER_DELETE_SUCCESS';
const USERPLAYER_DELETE_ERROR = 'USERPLAYER_DELETE_ERROR';

export const deleteUserPlayer = (userPlayerId) => (dispatch) => {
    dispatch({ type: USERPLAYER_DELETE });
    return dispatchNormalizedPromise(
        axiosInstance.delete(`/users_players/${userPlayerId}`),
        null,
        [USERPLAYER_DELETE_SUCCESS, USERPLAYER_DELETE_ERROR],
        dispatch
    );
};

export default combineReducers({
    userPlayers: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('userPlayers'),
        defaultStrategy: Strategies.merge,
    }),
    userPlayerDelete: createRequestReducer([USERPLAYER_DELETE, USERPLAYER_DELETE_SUCCESS, USERPLAYER_DELETE_ERROR], {
        ignoreData: true,
    }),
});
