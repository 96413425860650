import React, { Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { resetPassword } from 'reducers/users';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { confirmNewEmail } from 'reducers/users';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RequestStateHelper } from '../../../reducers/createRequestReducer';
import { usersSelector } from '../../../reducers/users';

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};

class ConfirmNewEmailPage extends React.Component {
    /**
     * Component state
     */
    state = {
        failed: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const key = new URLSearchParams(this.props.location.search).get('key');

        if (!key) {
            this.setState({ failed: true });
        } else {
            this.props.confirmNewEmail(id, key).catch(() => this.setState({ failed: true }));
        }
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <Row className={classes.row} center="xs" middle="xs">
                <Col xs={12} sm={6} lg={4}>
                    <div className={classes.cardContainer}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardContent style={{ textAlign: 'center' }}>
                                <Typography variant="h6" gutterBottom>
                                    New email confirmation
                                </Typography>
                                {isLoading ? (
                                    <FullWidthLoader />
                                ) : (
                                    <Fragment>
                                        <Typography color="textSecondary">
                                            {this.state.failed
                                                ? 'The new email confirmation failed'
                                                : 'Your new email has been confirmed !'}
                                        </Typography>
                                        <br />
                                        <Link to={`/`}>
                                            <Button className={classes.backButton}>
                                                <KeyboardArrowLeft /> Go to DEVA home
                                            </Button>
                                        </Link>
                                    </Fragment>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const usersState = usersSelector.getConfirmNewEmailState(state);

    return {
        isLoading: RequestStateHelper.isLoading(usersState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirmNewEmail: (id, key) => {
            return dispatch(confirmNewEmail(id, key)).then((response) => response);
        },
    };
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ConfirmNewEmailPage));
