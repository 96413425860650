import React from 'react';

/**
 * Creates a component class that renders the given Material UI component
 *
 * @param MaterialUIComponent The material ui component to render
 * @param mapProps A mapping of props provided by redux-form to the props the Material UI
 * component needs
 */
export default function createComponent(MaterialUIComponent, mapProps) {
    class InputComponent extends React.Component {
        constructor(props) {
            super(props);

            this.ref = React.createRef();
        }

        getRenderedComponent() {
            return this.ref.current;
        }

        render() {
            return React.createElement(MaterialUIComponent, {
                ...mapProps(this.props),
                ref: this.ref,
            });
        }
    }
    InputComponent.displayName = `ReduxFormMaterialUI${MaterialUIComponent.name}`;
    return InputComponent;
}
