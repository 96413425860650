class Cookie {
    set(name, value, options = {}) {
        let cookie = `${name}=${value}`;

        if (options.expires) {
            let expires = null;

            if (options.expires instanceof Date) {
                expires = options.expires;
            }
            else {
                switch (typeof options.expires) {
                    case 'string':
                        expires = new Date(options.expires);
                        break;
                    case 'number':
                        expires = new Date();
                        expires.setTime(expires.getTime() + options.expires);
                        break;
                }
            }

            if (expires) {
                cookie += `; expires=${expires.toUTCString()}`;
            }
        }

        if (!options.path) {
            options.path = '/';
        }

        for (const param in options) {
            if (param !== 'expires') {
                const option = options[param];

                if (typeof option === 'string' || typeof option === 'number') {
                    cookie += `; ${param}=${option}`;
                }
                else {
                    cookie += `; ${param}`;
                }
            }
        }

        document.cookie = cookie;
    }

    getAll() {
        const cookies = [];

        if (document.cookie) {
            const decodedCookies = decodeURIComponent(document.cookie).split(';');

            for (const decodedCookie of decodedCookies) {
                const cookieSeparatorIndex = decodedCookie.indexOf('=');
                const cookieName = decodedCookie.substr(0, cookieSeparatorIndex).trimStart();
                const cookieValue = decodedCookie.substr(cookieSeparatorIndex + 1);

                cookies[cookieName] = cookieValue;
            }
        }

        return cookies;
    }

    get(name) {
        return this.getAll()[name] || null;
    }

    delete(name) {
        this.set(name, '', { expires: '01-01-1970'});
    }

}

export default new Cookie();
