/*
Authors: Niklaas Cotta
Last Modified: 11/7/2023
Description: Main music asset page
*/

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { truncate } from 'lodash-es';

import { LocaleConsumer } from 'containers/locale/LocaleProvider'

import { RequestStateHelper } from 'reducers/createRequestReducer';
import { fetchMusicTracks, musicTrackSelector, deletePlayerMusicTrack } from 'reducers/musicTracks';

import FullWidthLoader from 'ui/base/FullWidthLoader';
import {
    List, ListItem, ListItemText, ListItemIcon,
    Dialog, DialogTitle, DialogActions, DialogContent,
    Button, IconButton,
    Checkbox, Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


const radioTitleStyles = {
    width: "fit-content"
}

const checkboxStyles = {
    marginRight: 85
}


function MusicTrackSelect(
    {
        maxRecords = 4,
        initialMusicTrackIds = [],
        isFetching,
        deletePlayerMusicTrack,
        musicTracks,
        fetchObjects,
        onConfirm,
        playerId
    }
) {
    // State-related
    const [open, setOpen] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [musicTracksToAdd, setMusicTracksToAdd] = useState([]);

    useEffect(() => {
        if (!open) return;
        fetchObjects(playerId);
        setMusicTracksToAdd(initialMusicTrackIds);
    }, [open, removing]);


    // Helper functions
    const handleCheckboxChange = (event, data) => {
        if (event.target.checked) {
            setMusicTracksToAdd(musicTracksToAdd.concat([data]))
        } else {
            setMusicTracksToAdd(musicTracksToAdd.filter(function(e) {
                if (e.music_track_id === data.music_track_id) {
                    if (e.music_location === data.music_location) {
                        return false;
                    } 
                }  
                return true
            }));
        }
    }

    const handleMusicTrackRemove = (musicTrackId) => {
        setRemoving(true);
        deletePlayerMusicTrack(musicTrackId)
            .finally(() => setRemoving(false));
    }


    // React Component
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <Typography style={{marginLeft: 2, marginTop: 15}}>
                        {getTranslation("music_track_library_add")}
                    </Typography>
                    <Button onClick={() => setOpen(true)} variant="contained" color="secondary" style={{marginBottom: 20, marginTop: 10}}>
                        {getTranslation("music_track_select")}
                    </Button>
                    <Typography style={{marginLeft: 2, marginTop: 15, marginBottom: 5}}>
                        {getTranslation("music_track_search")}
                    </Typography>
                    <Dialog fullWidth maxWidth="lg" onClose={() => setOpen(false)} open={open}>
                        <DialogTitle>{getTranslation("music_track_select_up_to")}</DialogTitle>
                        {isFetching && !musicTracks.length && (
                            <DialogContent>
                                <FullWidthLoader />
                            </DialogContent>
                        )}
                        <List style={{ display: 'flex', flexDirection: 'row', position: "absolute", right: "3%" }}>
                            <ListItem style={radioTitleStyles}>
                                <ListItemText primary={getTranslation("music_tracks_location_record_player")} />
                            </ListItem>
                            <ListItem style={radioTitleStyles}>
                                <ListItemText primary={getTranslation("music_tracks_location_radio_garden")}/>    
                            </ListItem>
                            <ListItem style={radioTitleStyles}>
                                <ListItemText primary={getTranslation("music_tracks_location_radio_bedroom")}/>
                            </ListItem>
                            <ListItem style={radioTitleStyles}>
                                <ListItemText primary={getTranslation("music_tracks_location_radio_bathroom")}/>
                            </ListItem>
                        </List>
                        <List>
                            {musicTracks.map((musicTrack) => {
                                const checked0 = musicTracksToAdd.filter(e => (e.music_track_id === musicTrack.id) && (e.music_location === 0)).length;
                                const checked1 = musicTracksToAdd.filter(e => (e.music_track_id === musicTrack.id) && (e.music_location === 1)).length;
                                const checked2 = musicTracksToAdd.filter(e => (e.music_track_id === musicTrack.id) && (e.music_location === 2)).length;
                                const checked3 = musicTracksToAdd.filter(e => (e.music_track_id === musicTrack.id) && (e.music_location === 3)).length;
                                
                                return (
                                    <ListItem key={musicTrack.id} style={{ paddingBottom: 20 }}>
                                        <ListItemIcon>
                                            <IconButton
                                                disabled={removing}
                                                onClick={() => handleMusicTrackRemove(musicTrack.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <img style={{ height: 90, maxWidth: 90, objectFit: "cover" }} src={musicTrack.thumbnail}/>
                                        </ListItemIcon>
                                        <ListItemText primary={truncate(musicTrack.title, { length: 50 })} secondary={truncate(musicTrack.description, { length: 50 })} style={{ paddingLeft: 8, width: '45%' }}/>
                                        <Checkbox 
                                            style={checkboxStyles} 
                                            onChange={e => handleCheckboxChange(e, {music_track_id: musicTrack.id, music_location: 0, type: 'music_track'})}
                                            checked={checked0}
                                            disabled={!checked0 && musicTracksToAdd.filter((v) => v.music_location === 0).length >= maxRecords}
                                            disableRipple >
                                        </Checkbox>
                                        <Checkbox 
                                            style={checkboxStyles} 
                                            onChange={e => handleCheckboxChange(e, {music_track_id: musicTrack.id, music_location: 1, type: 'music_track'})}
                                            checked={checked1}
                                            disableRipple >
                                        </Checkbox>
                                        <Checkbox 
                                            style={checkboxStyles} 
                                            onChange={e => handleCheckboxChange(e, {music_track_id: musicTrack.id, music_location: 2, type: 'music_track'})}
                                            checked={checked2}
                                            disableRipple >
                                        </Checkbox>
                                        <Checkbox 
                                            style={checkboxStyles} 
                                            onChange={e => handleCheckboxChange(e, {music_track_id: musicTrack.id, music_location: 3, type: 'music_track'})}
                                            checked={checked3}
                                            disableRipple >
                                        </Checkbox>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <DialogActions>
                            <Button autoFocus onClick={() => setOpen(false)}>
                                CANCEL
                            </Button>
                            <Button
                                onClick={() => {
                                    onConfirm(musicTracksToAdd);
                                    setOpen(false);
                                }}
                                variant="contained"
                                color="secondary"
                                autoFocus>
                                CONFIRM
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}



// Reducer Logic
MusicTrackSelect.propTypes = {};
MusicTrackSelect.defaultProps = {};

const mapStateToProps = (state) => {
    const musicTrackState = musicTrackSelector.getMusicTrackState(state);

    return {
        isFetching: RequestStateHelper.isLoading(musicTrackState),
        musicTracks: musicTrackSelector.getAll(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchObjects: (playerId) => dispatch(fetchMusicTracks(playerId)),
        deletePlayerMusicTrack: (musicTrackId) => dispatch(deletePlayerMusicTrack(musicTrackId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MusicTrackSelect);