import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Typography } from '@material-ui/core';
import PlayerCard from 'containers/players/PlayerCard';
import { connect } from 'react-redux';
import { fetchUserPlayers, playersSelector, updatePlayer } from 'reducers/players';
import { groupBy, get } from 'lodash-es';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import PageHeader from 'ui/base/PageHeader';
import Link from 'ui/base/Link';
import AddIcon from '@material-ui/icons/Add';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import PlayerAddModal from 'containers/players/PlayerAddModal';
import { usersSelector } from 'reducers/users';
import HomeCurrentUserTransfertsList from 'containers/playerTransferts/HomeCurrentUserTransfertsList';
import UserAlerts from 'containers/users/UserAlerts';
import CurrentUserPlayersAlert from 'containers/players/CurrentUserPlayersAlert';
import { Elements } from 'react-stripe-elements';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {
    main: {
        '& > *': {
            marginBottom: 20,
        },
    },
    playerCard: {
        marginBottom: 15,
    },
};

class HomePage extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            currentUser: { id },
        } = this.props;
        this.props.fetchPlayer(id);
    }

    getGroupedPlayers() {
        return groupBy(this.props.players, (player) => {
            //Ignore type coercion
            return player.main_user_id == this.props.currentUser.id ? 'own' : 'shared';
        });
    }

    renderPlayersList(playerArray) {
        const { classes } = this.props;

        if (!playerArray){

            return(
                <LocaleConsumer>
                    {({ getTranslation }) => (<Typography color="textSecondary">{getTranslation("home_no_players_yet")}</Typography>)}
                </LocaleConsumer>
            )
        }

        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {playerArray.map((player) => {
                    return (
                        <Link key={player.id} to={`/player/${player.id}/update-profile`}>
                            <div style={{ marginRight: 20, marginBottom: 20 }}>
                                <PlayerCard playerId={player.id} className={classes.playerCard} />
                            </div>
                        </Link>
                    );
                })}
            </div>
        );
    }

    handleAddPlayerButtonClick = () => {
        this.setState({ open: true });
    };

    handleAddPlayerRequestClose = () => {
        this.setState({ open: false });
    };

    renderAddPlayer() {
        const { currentUser } = this.props;
        const playersCount = get(this.getGroupedPlayers(), ['own', 'length']) || 0;
        const cantAdd = (currentUser.max_players && playersCount >= currentUser.max_players) || !currentUser.is_free;
        return (
            !cantAdd && (
                <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Button variant="contained" color="secondary" onClick={this.handleAddPlayerButtonClick}>
                            <AddIcon style={{ paddingRight: 5 }} /> {getTranslation("home_new_player")}
                        </Button>
                        <Elements>
                            <PlayerAddModal
                                open={this.state.open}
                                user={this.props.currentUser}
                                currentPlayersCount={currentUser.players_count}
                                onRequestClose={this.handleAddPlayerRequestClose}
                            />
                        </Elements>
                    </div>)}
                </LocaleConsumer>
            )
        );
    }

    renderPlayers() {
        const { classes, currentUser } = this.props;
        const { own, shared } = this.getGroupedPlayers();

        let sharedList = shared && (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <PageHeader title={getTranslation("home_players_shared_with_me")} />
                        <br />
                        <div>{this.renderPlayersList(shared)}</div>
                    </div>
                )}
            </LocaleConsumer>
        );

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.main}>
                        {!currentUser.is_institution && (
                            <div>
                                <PageHeader title={getTranslation("home_title")} />
                            </div>
                        )}
                        {!currentUser.is_institution && <div>{this.renderAddPlayer()}</div>}
                        {!currentUser.is_institution && <div>{this.renderPlayersList(own)}</div>}
                        {sharedList}
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { isFetching, currentUser } = this.props;

        return (
            <div>
                <UserAlerts>{!currentUser.is_institution && <CurrentUserPlayersAlert />}</UserAlerts>
                {isFetching && <FullWidthLoader />}
                {!isFetching && this.renderPlayers()}
                <HomeCurrentUserTransfertsList />
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const playerState = playersSelector.getUserPlayersState(state);
    const players = playersSelector.getUserPlayers(state);
    return {
        currentUser: usersSelector.getCurrentUser(state),
        isFetching: !players.length && RequestStateHelper.isLoading(playerState),
        players,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePlayer: (data) => dispatch(updatePlayer(data)),
        fetchPlayer: (userId) => dispatch(fetchUserPlayers(userId)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(HomePage));
