import React, { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import { fetchPlayerVideoChannels, videoChannelsSelector } from 'reducers/videoChannels';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import VideoChannelLinksDialog from 'containers/videoChannels/VideoChannelLinksDialog';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

function VideoPersonalChannelItems({ isFetching, playerId, fetchPlayerVideoChannels, videoChannels }) {
    const [currentChannelId, setCurrentChannelId] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    useEffect(() => {
        playerId && open && fetchPlayerVideoChannels(playerId);
    }, [playerId]);

    return (
        <React.Fragment>
            {currentChannelId && (
                <VideoChannelLinksDialog
                    editable
                    open={!!currentChannelId}
                    channelId={currentChannelId}
                    onClose={() => setCurrentChannelId(null)}
                />
            )}
            <Paper style={{ margin: '20px 0' }}>
                <Dialog maxWidth="lg" fullWidth onClose={() => setInfoOpen(false)} open={infoOpen}>
                    <DialogContent>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <iframe
                                src="https://player.vimeo.com/video/409618970"
                                width="640"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                            />
                        </div>
                    </DialogContent>
                </Dialog>
                <List>
                    {videoChannels.map((videoChannel) => {
                        return (
                            <LocaleConsumer>
                                {({ getTranslation }) => (
                                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }} key={videoChannel.id}>
                                        <StarIcon color="primary" />
                                        <div style={{ flex: 1 }}>
                                            <Typography>{videoChannel.title}</Typography>
                                        </div>
                                        <div>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => setCurrentChannelId(videoChannel.id)}>
                                                {getTranslation("customize")}
                                            </Button>
                                            <IconButton onClick={() => setInfoOpen(true)}>
                                                <InfoIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </LocaleConsumer>
                        );
                    })}
                </List>
            </Paper>
        </React.Fragment>
    );
}

VideoPersonalChannelItems.propTypes = {};
VideoPersonalChannelItems.defaultProps = {};
const mapStateToProps = (state) => {
    const videoChannelsState = videoChannelsSelector.getCurrentPlayerVideoChannelsState(state);
    return {
        isFetching: RequestStateHelper.isLoading(videoChannelsState),
        videoChannels: videoChannelsSelector.getCurrentPlayerVideoChannels(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlayerVideoChannels: (playerId) => dispatch(fetchPlayerVideoChannels(playerId)),
    };
};
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(VideoPersonalChannelItems);
