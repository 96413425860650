export default class AuthTokenStorage {
    static set(userId, token) {
        localStorage.setItem('userId', userId);
        localStorage.setItem('userToken', token);
    }

    static remove() {
        localStorage.removeItem('userId');
        localStorage.removeItem('userToken');
    }

    static getUserId() {
        return localStorage.getItem('userId');
    }

    static getToken() {
        return localStorage.getItem('userToken');
    }
}
