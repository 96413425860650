import axios from 'axios';
import { get } from 'lodash-es';
import { store } from '../index';
import { logout } from 'reducers/users';
import qs from 'qs';

const servers = {
    'deva-fr.mentia.me': BASE_URL_FR,
    'deva-au.mentia.me': BASE_URL_AU,
};
// Quick fix
// eslint-disable-next-line no-undef
export const apiUrl = servers[window.location.hostname] || BASE_URL;
const instance = axios.create({
    baseURL: apiUrl,
    headers: {
        Accept: 'application/json',
    },
    paramsSerializer: function (params) {
        return qs.stringify(params);
    },
});

export class RequestError {
    constructor(error) {
        this.error = error.response.data;
        this.originalError = error;
    }

    isException(name) {
        return this.error.exception === name;
    }

    getData() {
        return this.error.data || null;
    }

    getValidationError(fieldName, error) {
        return get(this.error.data, [fieldName, error]);
    }

    getException() {
        return this.error.exception;
    }

    getMessage() {
        return this.error.message;
    }

    getOriginalError() {
        return this.error;
    }

    toObject() {
        return {
            message: this.getMessage(),
            data: this.getData(),
            exception: this.getException(),
        };
    }
}

instance.interceptors.request.use(
    function (config) {
        let token = localStorage.getItem('userToken');
        config.headers['Authorization'] = token ? `Bearer ${token}` : null;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const requestError = new RequestError(error);
        if (requestError.isException('ExpiredException') || requestError.isException('AuthenticationFailedException')) {
            store.dispatch(logout());
        }

        return Promise.reject(requestError);
    }
);

export default instance;
