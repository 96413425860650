import React, {useEffect} from 'react';

import Cookie from '../../utils/Cookie';
import frenchTranslations from '../../ui/translation/fr-FR.json'
import englishTranslations from '../../ui/translation/en-GB.json'
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import { IntlProvider } from 'react-intl';

export const localeCookieName = '__locale';

const defaultLanguage = ((navigator.language || navigator.userLanguage)) || "en";
const defaultLocale = (Cookie.get(localeCookieName)) ?? defaultLanguage;

export const defaultState = {
    locale: defaultLocale,
};

moment.lang('en-gb', {
    longDateFormat : {
        LT: "hh:mm A",
        L: "MM/DD/YYYY",
    }
});

moment.lang('fr', {
    longDateFormat : {
        LT: "HH:mm",
        L: "DD/MM/YYYY",
    }
});

export const LocaleContext = React.createContext({
    ...defaultState,

    setLocale: () => {},

    getTranslation: () => '',
});

LocaleContext.displayName = 'Locale';

export const LocaleConsumer = LocaleContext.Consumer;

const LocaleProvider = ({
    renderKeyIfNotFound = process.env.NODE_ENV === 'development',
    children
}) => {
    const [locale, setLocaleState] = React.useState(defaultLocale);
    const [translations, setTranslations] = React.useState(locale.substring(0, 2) === "fr" ? frenchTranslations : englishTranslations);
    Cookie.set(localeCookieName, locale);

    const getTranslation = React.useCallback((key, name = "") => {
        let text = translations[key]
        if (!text) {
            console.warn(`missing locale key : ${key}`)
            return renderKeyIfNotFound ? key : "";
        }
        if (name !== "") {
            text = text.replace('${name}', name)
        }
        return text
    }, [locale, renderKeyIfNotFound]);

    useEffect(() => {
        if (locale.substring(0, 2) === "fr") {
            moment.locale('fr')
        } else {
            moment.locale('en-gb')
        }
    }, [locale])

    const localeContext = React.useMemo(() => ({
        locale,
        setLocale: newLocale => {
            if (newLocale.substring(0, 2) === "fr") {
                setTranslations(frenchTranslations)
            } else {
                setTranslations(englishTranslations)
            }
            Cookie.set(localeCookieName, newLocale);
            setLocaleState(newLocale);
        },

        getTranslation

    }), [locale, getTranslation]);

    return (
        <IntlProvider locale={locale}>
            <LocaleContext.Provider value={localeContext}>
                {children}
            </LocaleContext.Provider>
        </IntlProvider>
    );
};

export default LocaleProvider;
