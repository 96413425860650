import React from 'react';
import VideoChannelSelect from 'containers/videoChannels/VideoChannelSelect';
import { replacePlayerObjectAsset } from 'reducers/assets';
import { connect } from 'react-redux';
import PaintingSelect from "./PaintingSelect";

function PaintingUpdateContainer({
    initialPaintingIds ,
    replacePlayerObjectAsset,
    player: { id: playerId },
    object: { unity_id: unityId },
}) {
    const handleConfirm = (ids) => {
        const assets = ids.map((id) => ({ type: 'painting', painting_id: id }));
        replacePlayerObjectAsset(playerId, unityId, assets);
    };
    return <PaintingSelect playerId={playerId} initialPaintingIds={initialPaintingIds} onConfirm={handleConfirm} />;
}

PaintingUpdateContainer.propTypes = {};
PaintingUpdateContainer.defaultProps = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        replacePlayerObjectAsset: (playerId, unityId, assets) => {
            dispatch(replacePlayerObjectAsset(playerId, unityId, assets));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaintingUpdateContainer);
