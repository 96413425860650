import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { createPlayerTransfert } from 'reducers/playerTransferts';
import DefaultError from 'ui/base/DefaultError';

const styles = {
    dialog: {
        width: '80%',
    },
};

class PlayerTransfertModal extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleRequestClose = () => {
        this.props.reset();
        this.props.onRequestClose();
    };

    render() {
        const { classes, open, handleSubmit, error, submitting, player, ...other } = this.props;

        return (
            <Dialog
                onClose={this.handleRequestClose}
                open={open}
                classes={{
                    paper: classes.dialog,
                }}>
                <DialogTitle>Handover</DialogTitle>
                <DialogContent>
                    <Typography>
                        Handover {player.display_name}'s subscription to someone in your care circle. Once they accept,
                        you will no longer pay for {player.display_name}.
                    </Typography>
                    {error && <DefaultError>{error}</DefaultError>}
                    <br />
                    <Field
                        type="email"
                        name="email"
                        label="Email"
                        component={ValidatedTextField}
                        autoFocus
                        required
                        fullWidth
                    />
                    <br />
                    <br />
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} />
                        <Button onClick={this.handleRequestClose} disabled={submitting}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleSubmit} disabled={submitting} style={{ marginLeft: 5 }}>
                            Send
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(
    reduxForm({
        form: 'transfertPlayer',
        onSubmit(values, dispatch, props) {
            return dispatch(createPlayerTransfert({ ...values, player_id: props.player.id }))
                .then((result) => {
                    props.onRequestClose();
                    props.reset();
                    return result;
                })
                .catch((error) => {
                    const { user_id: _error } = validationErrorToSubmissionError(error);
                    throw new SubmissionError({ _error });
                });
        },
    })(PlayerTransfertModal)
);
