import React, { useState } from 'react';
import UploadButton from 'ui/base/UploadButton';
import { uploadVideo } from 'reducers/videoLinks';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

function VideoUploadInput({ handleUploaded }) {
    const [isLoading, setLoading] = useState(false);
    const handleFileChange = (event, fileList) => {
        if (fileList[0]) {
            setLoading(true);
            uploadVideo(fileList[0])
                .then((data) => {
                    handleUploaded(data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <UploadButton
                        disabled={isLoading}
                        onChange={handleFileChange}
                        variant="outlined"
                        color="secondary"
                        inputProps={{ accept: 'video/*' }}>
                        {getTranslation("asset_editor_video_channel_upload_select_video")}
                    </UploadButton>
                    {isLoading && <FullWidthLoader />}
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}

VideoUploadInput.propTypes = {};

VideoUploadInput.defaultProps = {};
export default VideoUploadInput;
