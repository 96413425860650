import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, Fields } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button, Typography } from '@material-ui/core';
import TokenFields from 'ui/payment/TokenFields';
import LockIcon from '@material-ui/icons/Lock';
import moment from 'moment/moment';
import { FormattedDate } from 'react-intl';
import MainConfig from 'constants/MainConfig';
import validator from 'validator';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'
import PasswordField from "ui/base/PasswordField";

const styles = {
    form: {
        '& > *': {
            marginBottom: 20,
        },
    },
};

class UserRForm extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleChangePasswordClick = () => {
        this.setState({ password: true });
    };

    renderSubscriptionMessage() {
        const { initialValues } = this.props;

        if (initialValues.subscription_is_trialing && !initialValues.is_free) {
            const duration = moment.duration(moment(initialValues.subscription_trial_end).diff(moment()));
            const remainingTime = `${duration.days()}`;
            return (
                <Typography>You are currently in the trial period. You have {remainingTime} days remaining.</Typography>
            );
        }
        if (
            !initialValues.subscription_is_trialing &&
            !initialValues.is_free &&
            !initialValues.subscription_canceled &&
            initialValues.players_count > 0
        ) {
            return (
                <Typography>
                    You are currently paying ${initialValues.players_count * MainConfig.pricePerPlayer} a month for{' '}
                    {initialValues.players_count} players. Your next payment is due{' '}
                    <FormattedDate value={initialValues.subscription_end} />
                </Typography>
            );
        }
    }

    /**
     * Render component
     */
    render() {
        const { classes, buttonText, handleSubmit, submitting, paymentForm, initialValues } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.form}>
                        <Field
                            type="text"
                            name="email"
                            label={getTranslation("email")}
                            component={ValidatedTextField}
                            autoFocus
                            required
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="first_name"
                            label={getTranslation("first_name")}
                            component={ValidatedTextField}
                            required
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="last_name"
                            label={getTranslation("last_name")}
                            component={ValidatedTextField}
                            required
                            fullWidth
                        />
                        {!this.state.password && (
                            <Button color="secondary" onClick={this.handleChangePasswordClick}>
                                {getTranslation("user_change_password")}
                            </Button>
                        )}
                        {/*<div>{this.renderSubscriptionMessage()}</div>*/}
                        {this.state.password && (
                            <div>
                                <Typography variant="h6">{getTranslation("user_change_password")}</Typography>
                                <br />
                                <Field
                                    name="password_current"
                                    label={getTranslation("user_current_password")}
                                    component={PasswordField}
                                    fullWidth
                                />
                                <br />
                                <br />
                                <Field
                                    name="password"
                                    label={getTranslation("password")}
                                    component={PasswordField}
                                    fullWidth
                                />
                                <br />
                                <br />
                                <Field
                                    name="password_confirm"
                                    label={getTranslation("password_confirm")}
                                    component={PasswordField}
                                    fullWidth
                                />
                            </div>
                        )}
                        {paymentForm && (
                            <div>
                                <Fields
                                    icon={<LockIcon />}
                                    names={['card_token', 'card']}
                                    component={TokenFields}
                                    label={(initialValues.has_card ? getTranslation("update") : getTranslation("add")) + getTranslation("user_your_credit_card")}
                                    email={initialValues.email}
                                />
                            </div>
                        )}
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} />
                            <Button color="primary" onClick={handleSubmit} disabled={submitting}>
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
    };
}

UserRForm = withStyles(styles)(UserRForm);

UserRForm.validate = (values) => {
    const errors = {};

    if (!validator.isEmail(values.email || '')) {
        errors.email = 'Invalid email';
    }

    if (!values.first_name) {
        errors.first_name = 'Required';
    }

    if (!values.last_name) {
        errors.last_name = 'Required';
    }

    if (values.password && !validator.isLength(values.password, { min: 6 })) {
        errors.password = 'Password must be at least 6 characters long';
    }

    if (values.password && !values.password_confirm) {
        errors.password_confirm = 'Required';
    } else if (values.password && values.password !== values.password_confirm) {
        errors.password_confirm = 'Your password and confirmation password do not match';
    }

    return errors;
};

export default UserRForm;
