import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import session from 'reducers/schemas/session';

/**
 *  Selectors
 */

export const sessionsSelector = {
    getCurrentPlayerSessions: (state) =>
        NormalizedSelector.getByIds(state.sessions.sessions, state.sessions.currentPlayerSessions),
    getCurrentPlayerSessionState: (state) => state.sessions.currentPlayerSessions,
    getCurrentUserSessions: (state) =>
        NormalizedSelector.getByIds(state.sessions.sessions, state.sessions.currentUserSessions),
    getCurrentUserSessionState: (state) => state.sessions.currentUserSessions,
};

/**
 * Actions
 */

const CURRENT_PLAYER_SESSIONS = 'CURRENT_PLAYER_SESSIONS';
const CURRENT_PLAYER_SESSIONS_SUCCESS = 'CURRENT_PLAYER_SESSIONS_SUCCESS';
const CURRENT_PLAYER_SESSIONS_ERROR = 'CURRENT_PLAYER_SESSIONS_ERROR';

const CURRENT_USER_SESSIONS = 'CURRENT_USER_SESSIONS';
const CURRENT_USER_SESSIONS_SUCCESS = 'CURRENT_USER_SESSIONS_SUCCESS';
const CURRENT_USER_SESSIONS_ERROR = 'CURRENT_USER_SESSIONS_ERROR';

const UPDATE_SESSION = 'UPDATE_SESSION';
const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
const UPDATE_SESSION_ERROR = 'UPDATE_SESSION_ERROR';

/**
 * Action creators
 */

export const fetchPlayerSessions = (playerId) => (dispatch) => {
    dispatch({ type: CURRENT_PLAYER_SESSIONS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/players/${playerId}/sessions`),
        [session],
        [CURRENT_PLAYER_SESSIONS_SUCCESS, CURRENT_PLAYER_SESSIONS_ERROR],
        dispatch
    );
};

export const fetchUserSessions = (userId, institutionId) => (dispatch) => {
    dispatch({ type: CURRENT_USER_SESSIONS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/users/${userId}/sessions`, {
            params: {
                institutionId,
            },
        }),
        [session],
        [CURRENT_USER_SESSIONS_SUCCESS, CURRENT_USER_SESSIONS_ERROR],
        dispatch
    );
};

export const updateUserSession = (sessionId, data) => (dispatch) => {
    dispatch({ type: UPDATE_SESSION });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/sessions/${sessionId}`, data),
        session,
        [UPDATE_SESSION_SUCCESS, UPDATE_SESSION_ERROR],
        dispatch
    );
};

export default combineReducers({
    sessions: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('sessions'),
        defaultStrategy: Strategies.merge,
    }),
    currentPlayerSessions: createRequestReducer(
        [CURRENT_PLAYER_SESSIONS, CURRENT_PLAYER_SESSIONS_SUCCESS, CURRENT_PLAYER_SESSIONS_ERROR],
        { mapResponse: NormalizedActionMap.result }
    ),
    currentUserSessions: createRequestReducer(
        [CURRENT_USER_SESSIONS, CURRENT_USER_SESSIONS_SUCCESS, CURRENT_USER_SESSIONS_ERROR],
        { mapResponse: NormalizedActionMap.result }
    ),
});
