import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Card, CardContent, CardHeader, CardMedia, Typography } from '@material-ui/core';
import PlayerAvatar from 'containers/players/PlayerAvatar';
import Star from '@material-ui/icons/Star';
import SessionDuration from 'containers/sessions/SessionDuration';
import {LocaleConsumer} from "containers/locale/LocaleProvider";
import moment from "moment";

const styles = (theme) => ({
    button: {
        width: '100%',
        justifyContent: 'initial',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
        padding: 15,
        '&:last-child': {
            paddingBottom: 15,
        },
    },
    cardHeaderRoot: {
        padding: 0,
        textAlign: 'left',
        textTransform: 'initial',
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    content: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    favoriteIcon: {
        color: theme.palette.primary[400],
    },
});

class PlayerCardDisplay extends React.Component {
    /**
     * Component state
     */
    state = {};

/**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderFavorite() {
        const {
            classes,
            userPlayer: { is_favorite },
        } = this.props;

        return is_favorite ? <Star style={{ verticalAlign: -3 }} className={classes.favoriteIcon} /> : null;
    }

    renderSubHeader() {
        const {
            player: { last_session_at, last_session_start_at, last_session_duration },
        } = this.props;

        if (!last_session_at || !last_session_start_at) return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        {getTranslation("player_card_no_session")}
                    </div>
                )}
            </LocaleConsumer>
        );

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        {getTranslation("player_card_last_session")}
                        {moment(last_session_at).fromNow()}
                        {getTranslation("player_card_last_session_lasted")}
                        <SessionDuration start={last_session_start_at} end={last_session_at} duration={last_session_duration} />
                        {' '}{getTranslation("minutes")}
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    renderList() {
        const {
            classes,
            player,
            player: { display_name },
            userPlayer,
            ...otherProps
        } = this.props;

        return (
            <Button
                classes={{
                    root: classes.button,
                }}
                {...otherProps}>
                <div className={classes.content}>
                    <CardHeader
                        title={display_name}
                        subheader={this.renderSubHeader()}
                        avatar={<PlayerAvatar player={player} />}
                        classes={{
                            root: classes.cardHeaderRoot,
                        }}
                    />
                    <div style={{ flex: 1 }} />
                    <div>{this.renderFavorite()}</div>
                </div>
            </Button>
        );
    }

    renderDefault() {
        const {
            classes,
            player,
            player: { display_name },
            userPlayer,
            ...otherProps
        } = this.props;

        return (
            <Card style={{ minHeight: 375, overflow: 'hidden' }}>
                <CardMedia style={{ height: 250, width: '100%' }} image={player.photo_url} />
                <CardContent>
                    <Typography style={{ fontSize: '1.2rem' }} type="headline" gutterBottom>
                        {display_name} {this.renderFavorite()}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        <small>{this.renderSubHeader()}</small>
                    </Typography>
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                        {/*<Link to={getDeeplinkUrl(player.id)} onClick={(e) => e.stopPropagation()}>*/}
                        {/*<Button color="secondary" variant="contained">*/}
                        {/*Play*/}
                        {/*</Button>*/}
                        {/*</Link>*/}
                    </div>
                </CardContent>
            </Card>
        );
    }

    /**
     * Render component
     */
    render() {
        return this.renderDefault();
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PlayerCardDisplay);
