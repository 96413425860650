import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import {
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import ValidatedSelectField from 'ui/base/ValidatedSelectField';
import DefaultError from 'ui/base/DefaultError';
import PlayerGender from 'containers/players/PlayerGender';
import CancelIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { Col, Row } from 'react-flexbox-grid';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'
import FemaleImage from '../../images/female.jpg'
import MaleImage from '../../images/male.jpg'

const styles = {
    form: {},
    title: {
        marginTop: 20,
    },
};

const radioButton = ({ input, ...rest }) => (
    <FormControl>
        <RadioGroup {...input} {...rest}>
            <div>
                <FormControlLabel value="2" control={<Radio />} label={<img style={{height: 40}} src={FemaleImage} alt="female" />} />
                <FormControlLabel value="1" control={<Radio />} label={<img style={{height: 40}} src={MaleImage} alt="male" />} />
            </div>
        </RadioGroup>
    </FormControl>
)

class PlayerNextSessionDisplayRForm extends React.Component {
    /**
     * Component state
     */
    state = {
        isEditing: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleEditClick = () => {
        const { reset } = this.props;
        reset();
        this.setState({ isEditing: true });
    };

    handleCancelClick = () => {
        const { reset } = this.props;
        reset();
        this.setState({ isEditing: false });
    };

    handleConfirmClick = (event) => {
        const { handleSubmit, pristine } = this.props;
        if (pristine) {
            this.setState({ isEditing: false });
        } else {
            handleSubmit && handleSubmit(event);
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.pristine === false && this.props.pristine === true) {
            this.setState({ isEditing: false });
        }
    }

    renderUpdate() {
        const { submitting } = this.props;
        const { isEditing } = this.state;

        return (
            <LocaleConsumer>
                {({ getTranslation}) => (
                    <Row>
                        <Col xs>
                            <Typography style={{"padding-top": "12px"}}>{getTranslation("player_next_session_avatar")}</Typography>
                        </Col>
                        <Col>
                            {isEditing ? (
                                <div>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        disabled={submitting}
                                        onClick={this.handleConfirmClick}>
                                        {getTranslation("save")}
                                    </Button>
                                    <IconButton disabled={submitting} onClick={this.handleCancelClick}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ) : (
                                <div>
                                    <IconButton disabled={submitting} onClick={this.handleEditClick}>
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </LocaleConsumer>
        );
    }

    renderTextField(name, displayName, field, previewCallback) {
        const { initialValues } = this.props;
        const { isEditing } = this.state;
        previewCallback =
            previewCallback ||
            ((value) => <Typography color={value ? 'default' : 'textSecondary'}>{value || <i>-</i>}</Typography>);
        return (
            <Row middle="xs" style={{ margin: '10px 0' }}>
                <Col style={{ width: 125 }}>
                    <Typography>
                        <b>{displayName}</b>
                    </Typography>
                </Col>
                <Col xs>{isEditing ? field : previewCallback(initialValues[name])}</Col>
            </Row>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, error, submitting } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div className={classes.form}>
                            <DefaultError>{error}</DefaultError>
                            {this.renderUpdate()}
                            {/*{this.renderTextField(*/}
                            {/*    'avatar_gender',*/}
                            {/*    getTranslation("player_next_session_avatar_gender"),*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <Field*/}
                            {/*            type="radio"*/}
                            {/*            name="avatar_gender"*/}
                            {/*            disabled={submitting}*/}
                            {/*            component={radioButton}*/}
                            {/*        />*/}
                            {/*    </FormControl>,*/}
                            {/*    (value) => (*/}
                            {/*        <PlayerGender gender={value} />*/}
                            {/*    )*/}
                            {/*)}*/}
                            {this.renderTextField(
                                'lang',
                                getTranslation("player_next_session_language"),
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="lang">{getTranslation("player_next_session_language")}</InputLabel>
                                    <Field
                                        type="text"
                                        name="lang"
                                        label={getTranslation("player_next_session_language")}
                                        disabled={submitting}
                                        component={ValidatedSelectField}
                                        muiInput={<Input id="lang" />}>
                                        <MenuItem value={0}>English</MenuItem>
                                        <MenuItem value={1}>Greek</MenuItem>
                                        <MenuItem value={2}>Français</MenuItem>
                                    </Field>
                                </FormControl>,
                                (value) => {
                                    switch (value) {
                                        case 0:
                                            return <Typography>English</Typography>;
                                        case 1:
                                            return <Typography>Greek</Typography>;
                                        case 2:
                                            return <Typography>Français</Typography>;
                                    }
                                }
                            )}
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initialization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
        paymentForm: false,
    };
}

export default withStyles(styles)(PlayerNextSessionDisplayRForm);
