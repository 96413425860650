import * as actions from 'redux-form';
import { reduxForm, SubmissionError } from 'redux-form';
import AssetRForm from 'containers/assets/AssetRForm';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { createPlayerObjectAsset } from 'reducers/assets';

export default reduxForm({
    form: 'asset',
    validate: (values) => {
        const errors = {};

        if (!values.file) {
            errors.file = 'File is required';
        }

        return errors;
    },
    onSubmit: (values, dispatch, ownProps) => {
        const {
            player: { id: playerId },
            object: { unity_id: unityId },
        } = ownProps;

        return dispatch(createPlayerObjectAsset(playerId, unityId, { type: 'file', ...values }))
            .then((result) => {
                dispatch(actions.reset('asset'));
                return result;
            })
            .catch((error) => {
                if (error.isException(Exceptions.ValidationException)) {
                    const errors = validationErrorToSubmissionError(error.getData());
                    throw new SubmissionError(errors);
                }
                throw new SubmissionError({ _error: error.getMessage() });
            });
    },
})(AssetRForm);
