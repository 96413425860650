/*
Authors: Hugo Lavergne, Niklaas Cotta
Last Modified: 8/06/2023
Description: Main painting asset page
*/

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { truncate } from 'lodash-es';

import { LocaleConsumer } from 'containers/locale/LocaleProvider';

import { RequestStateHelper } from 'reducers/createRequestReducer';
import {fetchPlayerPainting, deletePlayerPainting, paintingSelector} from "reducers/paintings";

import FullWidthLoader from 'ui/base/FullWidthLoader';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    List, ListItem, ListItemText, ListItemIcon,
    Checkbox, Button, IconButton,
    Dialog, DialogTitle, DialogActions, DialogContent,
    Typography,
    TextField,
    InputAdornment
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';


// CSS styles in JSX format
const styles = {
    list: {
        maxHeight: 400,
        overflow: 'auto',
    },
};

function PaintingSelect({
    initialPaintingIds = [],
    maxItems = 4,
    classes,
    isFetching,
    deletePlayerPainting,
    paintings,
    fetchObjects,
    onConfirm,
    playerId
}) {
    // State and other variables
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [search, setSearch] = useState('');
    const [removing, setRemoving] = useState(false);

    const hasUnchecked = selectedIds.length !== paintings.length;

    useEffect(() => {
        if (!open) return;
        fetchObjects(playerId);
        setSelectedIds(initialPaintingIds);
    }, [open, removing]);

    // Helper functions
    const handlePaintingClick = (paintingId) => {
        selectedIds.includes(paintingId)
            ? setSelectedIds(selectedIds.filter((v) => v !== paintingId))
            : selectedIds.length < maxItems && setSelectedIds(selectedIds.concat([paintingId]));
    };
    const handlePaintingRemove = (paintingId) => {
        setRemoving(true);
        deletePlayerPainting(paintingId).finally(() => setRemoving(false));
    }

    const transform = (v) => v.toLowerCase();
    const includes = (v, search) => transform(v).includes(transform(search));
    const searchFilter = (painting) => {
        if (!search) return true;
        return includes(painting.description, search);
    };
    
    const handleCheckAll = () => {
        setSelectedIds(hasUnchecked ? paintings.map((v) => v.id) : []);
    };
    
    // React Component
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <Typography style={{marginLeft: 2, marginTop: 15}}>
                        {getTranslation("assets_editor_painting_library_add")}
                    </Typography>
                    <Button onClick={() => setOpen(true)} variant="contained" color="secondary" style={{marginBottom: 20, marginTop: 10}}>
                        {getTranslation("painting_select")}
                    </Button>
                    <Typography style={{marginLeft: 2, marginTop: 15, marginBottom: 5}}>
                        {getTranslation("assets_editor_painting_upload")}
                    </Typography>
                    <Dialog fullWidth maxWidth="sm" onClose={() => setOpen(false)} open={open}>
                        <DialogTitle>{getTranslation("painting_select_up_to", maxItems)}</DialogTitle>
                        {isFetching && !paintings.length && (
                            <DialogContent>
                                <FullWidthLoader />
                            </DialogContent>
                        )}
                        <DialogContent>
                            <TextField
                                placeholder={getTranslation("search")}
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button onClick={handleCheckAll}>{hasUnchecked ? getTranslation("asset_editor_painting_tick_all") : getTranslation("asset_editor_painting_untick_all")}</Button>
                        </DialogContent>
                        <List className={classes.list}>
                            {paintings.filter(searchFilter).map((painting) => {
                                const checked = selectedIds.includes(painting.id);
                                return (
                                    <ListItem key={painting.id} button onClick={() => handlePaintingClick(painting.id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                disabled={!checked && selectedIds.length >= maxItems}
                                                checked={checked}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <img style={{ height: 50, maxWidth: 50 }} src={painting.file_url} alt="" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={painting.description}
                                            secondary={truncate(painting.description, { length: 50 })}
                                        />
                                        <ListItemIcon>
                                            <IconButton
                                                disabled={removing}
                                                onClick={() => handlePaintingRemove(painting.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <DialogActions>
                            <Button autoFocus onClick={() => setOpen(false)}>
                                {getTranslation("cancel")}
                            </Button>
                            <Button
                                onClick={() => {
                                    onConfirm(selectedIds);
                                    setOpen(false);
                                }}
                                variant="contained"
                                color="secondary"
                                autoFocus>
                                {getTranslation("confirm")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}



// Reducer logic
PaintingSelect.propTypes = {};
PaintingSelect.defaultProps = {};

const mapStateToProps = (state) => {
    const paintingState = paintingSelector.getPaintingState(state);
    return {
        isFetching: RequestStateHelper.isLoading(paintingState),
        paintings: paintingSelector.getAll(state),
    };
};

const mapDispatchToProps = (dispatch) => {

    return {
        fetchObjects: (playerId) => dispatch(fetchPlayerPainting(playerId)),
        deletePlayerPainting: (paintingId) => dispatch(deletePlayerPainting(paintingId))
    };
};



export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(PaintingSelect);
