import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { playersSelector } from 'reducers/players';
import { usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import SnackbarContent from '@material-ui/core/Snackbar';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import MainConfig from 'constants/MainConfig';

const styles = {};

class CurrentUserPlayersAlert extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderMessage() {
        const { players, currentUser } = this.props;

        let remainingTime = null;
        if (currentUser.subscription_is_trialing) {
            const duration = moment.duration(moment(currentUser.subscription_trial_end).diff(moment()));
            remainingTime = `${duration.days()}`;
        }

        if (!currentUser.is_invited && !currentUser.is_institution && !currentUser.is_free) {
            if (currentUser.subscription_is_trialing && !players.length) {
                return (
                    <div>
                        <InfoOutlineIcon style={{ verticalAlign: 'middle' }} />
                        Add your players and customize their content. You have {remainingTime} free days remaining, then
                        you will be charged ${MainConfig.pricePerPlayer} per month per player.
                    </div>
                );
            }
            if (currentUser.subscription_is_trialing && players.length >= 1) {
                return (
                    <div>
                        <InfoOutlineIcon style={{ verticalAlign: 'middle' }} />
                        You can still play {remainingTime} days for free. You can add any new player during this period
                        without being charged.
                    </div>
                );
            }
        }

        if (!currentUser.is_invited && currentUser.is_institution) {
            return (
                <div>
                    <InfoOutlineIcon style={{ verticalAlign: 'middle' }} />
                    Add new players and customize their content.
                </div>
            );
        }
        return null;
    }

    /**
     * Render component
     */
    render() {
        const { isFetching } = this.props;

        if (isFetching) return null;

        const message = this.renderMessage();
        return message ? <SnackbarContent message={message} /> : null;
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const playerState = playersSelector.getUserPlayersState(state);
    const players = playersSelector.getUserPlayers(state);
    return {
        currentUser: usersSelector.getCurrentUser(state),
        isFetching: !players.length && RequestStateHelper.isLoading(playerState),
        players,
    };
};

export default withStyles(styles)(connect(mapStateToProps, null)(CurrentUserPlayersAlert));
