import React from 'react';
import { connect } from 'react-redux';
import { usersSelector } from 'reducers/users';
import { SnackbarContent, withStyles } from '@material-ui/core';
import { ContentComponent } from 'containers/ContentComponents';

const styles = {
    content: {
        '& > *': {
            marginBottom: 15,
        },
        '& > *:last-child': {
            marginBottom: 50,
        },
    },
};

class UserAlerts extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderCantPlayMessage() {
        return <SnackbarContent message={<ContentComponent componentName="UserAlerts.CantPlayAlert" />} />;
    }

    /**
     * Render component
     */
    render() {
        const { classes, currentUser, children } = this.props;
        return (
            <div className={classes.content}>
                {!currentUser.can_play && this.renderCantPlayMessage()}
                {children}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    return {
        currentUser: usersSelector.getCurrentUser(state),
    };
};

export default withStyles(styles)(connect(mapStateToProps)(UserAlerts));
