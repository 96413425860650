import React from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class QuickStartPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            <Link to={`/`}>
                                <Button>
                                    <KeyboardArrowLeft /> {getTranslation("home")}
                                </Button>
                            </Link>
                        </div>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                    {getTranslation("quick_start_title")}
                                </Typography>
                                <Typography>
                                    <ol>
                                        <li>{getTranslation("quick_start_1")}</li>
                                        <li>
                                            {getTranslation("quick_start_2")}
                                            <div>
                                                <a
                                                    href="https://itunes.apple.com/us/app/deva-dementia-friendly-world/id1241083987"
                                                    target="_blank">
                                                    <img
                                                        width="160"
                                                        src="https://www.mentia.me/wp-content/uploads/2017/11/Apple-App-Store-button-557x168px.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=me.mentia.deva"
                                                    target="_blank">
                                                    <img
                                                        width="150"
                                                        src="https://www.mentia.me/wp-content/uploads/2017/11/Google-Play-Store-button-669x168px.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            {getTranslation("quick_start_3")}
                                        </li>
                                        <li>{getTranslation("quick_start_4")}</li>
                                    </ol>
                                    <br />
                                    {getTranslation("quick_start_final")}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default QuickStartPage;
