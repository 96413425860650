import React, { useEffect, useState } from 'react';
import { fetchVideoChannels, videoChannelsSelector } from 'reducers/videoChannels';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    ListItemIcon,
    Checkbox,
    ListItemText,
    ListItem,
    DialogContent,
    Button,
    Dialog,
    DialogTitle,
    List,
    DialogActions,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { truncate } from 'lodash-es';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    list: {
        maxHeight: 400,
        overflow: 'auto',
    },
};

function VideoChannelSelect({
    initialChannelIds = [],
    maxItems = 11,
    classes,
    isFetching,
    videoChannels,
    fetchObjects,
    onConfirm,
}) {
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (!open) return;
        fetchObjects();
        setSelectedIds(initialChannelIds);
    }, [open]);
    const handleChannelClick = (channelId) => {
        selectedIds.includes(channelId)
            ? setSelectedIds(selectedIds.filter((v) => v !== channelId))
            : selectedIds.length < maxItems && setSelectedIds(selectedIds.concat([channelId]));
    };
    const transform = (v) => v.toLowerCase();
    const includes = (v, search) => transform(v).includes(transform(search));
    const searchFilter = (videoChannel) => {
        if (!search) return true;
        return includes(videoChannel.description, search) || includes(videoChannel.title, search);
    };
    const hasUnchecked = selectedIds.length !== videoChannels.length;
    const handleCheckAll = () => {
        setSelectedIds(hasUnchecked ? videoChannels.map((v) => v.id) : []);
    };

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <Button onClick={() => setOpen(true)} variant="contained" color="secondary">
                        {getTranslation("asset_editor_video_channel_select")}
                    </Button>
                    <Dialog fullWidth maxWidth="sm" onClose={() => setOpen(false)} open={open}>
                        <DialogTitle>{getTranslation("asset_editor_video_channel_select_up_to", maxItems)}</DialogTitle>
                        {isFetching && !videoChannels.length && (
                            <DialogContent>
                                <FullWidthLoader />
                            </DialogContent>
                        )}
                        <DialogContent>
                            <TextField
                                placeholder={getTranslation("search")}
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button onClick={handleCheckAll}>{hasUnchecked ? getTranslation("asset_editor_video_channel_tick_all") : getTranslation("asset_editor_video_channel_untick_all")}</Button>
                        </DialogContent>
                        <List className={classes.list}>
                            {videoChannels.filter(searchFilter).map((videoChannel) => {
                                const checked = selectedIds.includes(videoChannel.id);
                                return (
                                    <ListItem key={videoChannel.id} button onClick={() => handleChannelClick(videoChannel.id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                disabled={!checked && selectedIds.length >= maxItems}
                                                checked={checked}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <img style={{ height: 50, maxWidth: 100 }} src={videoChannel.cover_url} alt="" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={videoChannel.title}
                                            secondary={truncate(videoChannel.description, { length: 50 })}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <DialogActions>
                            <Button autoFocus onClick={() => setOpen(false)}>
                                {getTranslation("cancel")}
                            </Button>
                            <Button
                                onClick={() => {
                                    onConfirm(selectedIds);
                                    setOpen(false);
                                }}
                                variant="contained"
                                color="secondary"
                                autoFocus>
                                {getTranslation("confirm")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}

VideoChannelSelect.propTypes = {};
VideoChannelSelect.defaultProps = {};

const mapStateToProps = (state) => {
    const videoChannelsState = videoChannelsSelector.getAllState(state);
    return {
        isFetching: RequestStateHelper.isLoading(videoChannelsState),
        videoChannels: videoChannelsSelector.getAll(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchObjects: () => dispatch(fetchVideoChannels()),
    };
};
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(VideoChannelSelect);
