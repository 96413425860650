import React from 'react';
import MorningIcon from 'images/morning.png';
import AfternoonIcon from 'images/afternoon.png';
import EveningIcon from 'images/evening.png';
import { FormattedNumber } from 'react-intl';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
    base: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '0 2px',
    },
};

class SessionsPartOfDayMinistat extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { morningCount, afternoonCount, eveningCount, total, classes } = this.props;
        return (
            <div className={classes.base}>
                <div className={classes.column}>
                    <div>
                        <img src={MorningIcon} alt="" style={{ verticalAlign: 'middle' }} />
                    </div>
                    <div>
                        <FormattedNumber maximumFractionDigits={1} value={(morningCount / total) * 100} />%
                    </div>
                </div>
                <div className={classes.column}>
                    <div>
                        <img src={AfternoonIcon} alt="" style={{ verticalAlign: 'middle' }} />
                    </div>
                    <div>
                        <FormattedNumber maximumFractionDigits={1} value={(afternoonCount / total) * 100} />%
                    </div>
                </div>
                <div className={classes.column}>
                    <div>
                        <img src={EveningIcon} alt="" style={{ verticalAlign: 'middle' }} />
                    </div>
                    <div>
                        <FormattedNumber maximumFractionDigits={1} value={(eveningCount / total) * 100} />%
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        morningCount: PropTypes.number,
        afternoonCount: PropTypes.number,
        eveningCount: PropTypes.number,
        total: PropTypes.number,
    };
    static defaultProps = {};
}

export default withStyles(styles)(SessionsPartOfDayMinistat);
