import React from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class PlayDevaVideoPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            <Link to={`/`}>
                                <Button>
                                    <KeyboardArrowLeft /> {getTranslation("home")}
                                </Button>
                            </Link>
                        </div>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                    {getTranslation("howto_videos_play")}
                                </Typography>
                                <div className="embed-container">
                                    <iframe
                                        style={{ width: '100%' }}
                                        src="https://player.vimeo.com/video/275349224?title=0&byline=0&portrait=0"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default PlayDevaVideoPage;
