import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, CardContent, Typography } from '@material-ui/core';
import { usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import InstitutionPlayersList from 'containers/players/InstitutionPlayersList';
import AddIcon from '@material-ui/icons/Add';
import PlayerAddModal from 'containers/players/PlayerAddModal';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    main: {
        '& > *': {
            marginBottom: 20,
        },
    },
};

class ManageInstitutionPlayersPage extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleAddPlayerButtonClick = () => {
        this.setState({ open: true });
    };

    handleAddPlayerRequestClose = () => {
        this.setState({ open: false });
    };

    renderAddPlayer() {
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Button variant="contained" color="secondary" onClick={this.handleAddPlayerButtonClick}>
                            <AddIcon style={{ paddingRight: 5 }} /> {getTranslation("manager_institution_player_new")}
                        </Button>
                        <PlayerAddModal
                            open={this.state.open}
                            user={this.props.user}
                            onRequestClose={this.handleAddPlayerRequestClose}
                        />
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, user } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <CardContent>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h5" gutterBottom>
                                {getTranslation("manager_institution_players_title")}
                            </Typography>
                            <div style={{ flex: 1 }} />
                            {this.renderAddPlayer()}
                        </div>
                        <br />
                        <InstitutionPlayersList institutionId={user.id} />
                    </CardContent>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default withStyles(styles)(connect(mapStateTopProps)(ManageInstitutionPlayersPage));
