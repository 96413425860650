/* 
Authors: Gaetan Senelle, Niklaas Cotta
Last modified: 9/14/2023
Desc: Add Content base navigation bar layout
*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';

import { map, get, values } from 'lodash-es';

import { LocaleConsumer } from 'containers/locale/LocaleProvider';
import ObjectTypeIcon from 'containers/assets/ObjectTypeIcon';
import AssetsEditor from 'containers/assets/AssetsEditor';

import { fetchPlayerObjects, objectSelector } from 'reducers/objects';
import { RequestStateHelper } from 'reducers/createRequestReducer';

import FullWidthLoader from 'ui/base/FullWidthLoader';
import Link from 'ui/base/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import { 
    CardContent, 
    List, ListItem, ListItemIcon, ListItemText, 
    Typography 
} from '@material-ui/core';



// CSS styles in JSX format
const styles = (theme) => ({
    appFrame: {
        display: 'flex',
        width: '100%',
    },
    content: {
        flex: 1,
    },
    rightMenu: {
        borderRight: '1px solid #f0f0f0',
        flexBasis: '250px',
    },
});



// Main class logic
class AssetsEditorLayout extends React.Component {
    /**
     * Component state
     */

    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchObjects(this.props.player.id);
    }

    getCurrentObject() {
        const { objectsById } = this.props;
        const objectId = this.props.match.params.objectId;

        if (objectId) return objectsById[objectId];
        return null;
    }

    /**
     * Render navigation bar
     */
    renderListItems() {
        const { objectsById, player } = this.props;
        const currentObject = this.getCurrentObject();

        return map(objectsById, (object) => {
            const url = `/player/${player.id}/assets/${object.id}`;
            // const description = truncate(object.description, { length: 40 });

            switch(object.description) {
                case 'Photo Album':
                    return (
                        <LocaleConsumer>
                            {({ getTranslation }) => (
                                <Link to={url} key={object.id}>
                                    <ListItem selected={currentObject === object} button>
                                        <ListItemIcon>
                                            <ObjectTypeIcon type={object.type} />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontSize: '16px' }} primary={getTranslation("asset_editor_photos_title")} />
                                    </ListItem>
                                </Link>
                            )}
                        </LocaleConsumer>
                    ); 
                case 'Videos':
                    return (
                        <LocaleConsumer>
                            {({ getTranslation }) => (
                                <Link to={url} key={object.id}>
                                    <ListItem selected={currentObject === object} button>
                                        <ListItemIcon>
                                            <ObjectTypeIcon type={object.type} />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontSize: '16px' }} primary={getTranslation("asset_editor_videos_title")} />
                                    </ListItem>
                                </Link>
                            )}
                        </LocaleConsumer>
                    );
                case 'Books':
                    return (
                        <LocaleConsumer>
                            {({ getTranslation }) => (
                                <Link to={url} key={object.id}>
                                    <ListItem selected={currentObject === object} button>
                                        <ListItemIcon>
                                            <ObjectTypeIcon type={object.type} />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontSize: '16px' }} primary={getTranslation("asset_editor_books_title")} />
                                    </ListItem>
                                </Link>
                            )}
                        </LocaleConsumer>
                    );
                case 'Paintings':
                    return (
                        <LocaleConsumer>
                            {({ getTranslation }) => (
                                <Link to={url} key={object.id}>
                                    <ListItem selected={currentObject === object} button>
                                        <ListItemIcon>
                                            <ObjectTypeIcon type={object.type} />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontSize: '16px' }} primary={getTranslation("asset_editor_paintings_title")} />
                                    </ListItem>
                                </Link>
                            )}
                        </LocaleConsumer>
                    );
                case 'Music Tracks':
                    return (
                        <LocaleConsumer>
                            {({ getTranslation }) => (
                                <Link to={url} key={object.id}>
                                    <ListItem selected={currentObject === object} button>
                                        <ListItemIcon>
                                            <ObjectTypeIcon type={object.type} />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontSize: '16px' }} primary={getTranslation("asset_editor_music_tracks_title")} />
                                    </ListItem>
                                </Link>
                            )}
                        </LocaleConsumer>
                    );
                default:
                    return (
                        <React.Fragment>
                            <Typography>
                                NULL
                            </Typography>
                        </React.Fragment>
                    )
            }
        });
    }

    // Redirect to main assets
    renderRedirectFirstObject() {
        const { player, objectsById } = this.props;
        const objectId = get(values(objectsById), '[0].id');
        if (objectId) {
            return <Redirect to={`/player/${player.id}/assets/${objectId}`} />;
        }
    }

    renderLayout() {
        const { classes, player } = this.props;

        const currentObject = this.getCurrentObject();
        if (!currentObject) return this.renderRedirectFirstObject();

        return (
            <div className={classes.appFrame}>
                <div className={classes.rightMenu}>
                    <List>{this.renderListItems()}</List>
                </div>
                <CardContent className={classes.content}>
                    <AssetsEditor player={player} object={currentObject} />
                </CardContent>
            </div>
        );
    }

    /**
     * Render component
     */
    render() {
        const { isFetching } = this.props;
        return (
            <div>
                {isFetching && <FullWidthLoader />}
                {!isFetching && this.renderLayout()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}



// Reducer logic
const mapStateToProps = (state) => {
    const objectsState = objectSelector.getNormalizedState(state);
    return {
        isFetching: RequestStateHelper.isLoading(objectsState),
        objectsById: RequestStateHelper.getData(objectsState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchObjects: (playerId) => dispatch(fetchPlayerObjects(playerId)),
    };
};



export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsEditorLayout)));