/*
Authors: Niklaas Cotta
Last Modified: 10/2/2023
Description: Music search box
*/

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { LocaleConsumer } from 'containers/locale/LocaleProvider';
import { connect } from 'react-redux';

import { replacePlayerObjectAsset } from 'reducers/assets';
import { fetchMusicTracks, createPlayerMusicTrack, musicTrackSelector } from 'reducers/musicTracks';

import { 
    TextField, InputAdornment, Button, Typography,
    List, ListItem, ListItemText, ListItemIcon,
    Dialog, DialogTitle, DialogContent, Checkbox
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { truncate } from 'lodash-es';



function SearchBar({ 
    musicTracks,
    fetchObjects,
    createPlayerMusicTrack,
    playerId
}) {
    const [searchTerm, setSearchTerm ] = useState('');
    const [searchResults, setSearchResults ] = useState([]);
    const [open, setOpen] = useState(false);
    const [musicTracksToAdd, setMusicTracksToAdd] = useState([]);

    useEffect(() => {
        fetchObjects(playerId);
    }, [open]);

    //! REMOVE ON PROD
    const apikey = "AIzaSyBiM3pyhqq7QSlPkRbsC0hFwstG1ebS4hQ";
    const maxResults = 10;

    const handleConfirm = (ids) => {
        const assets = ids.map((id) => ({ type: 'music_tracks', music_track_id: id }));
        replacePlayerObjectAsset(playerId, "music_tracks", assets);
    };

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = async () => { 
        // add all musicTracks that were checked
        for (const musicTrack of musicTracksToAdd) {
            createPlayerMusicTrack(playerId, {
                title: musicTrack.snippet.title,
                description: musicTrack.snippet.description,
                url: `https://www.youtube.com/watch?v=${musicTrack.id.videoId}`,
                thumbnail: musicTrack.snippet.thumbnails.medium.url,
            });
        }

        handleConfirm(musicTracks.map((v) => v.id));
        setMusicTracksToAdd([]);
        setOpen(false);
    }

    const handleSearch = async (event) => {
        // prevent url redirect
        event.preventDefault();
        // if search is empty, return nothing
        if (searchTerm.trim() === '') return;
        // otherwise await for youtube search results
        const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/search?key=${apikey}&type=video&part=snippet&maxResults=${maxResults}&q=${searchTerm}`
        );

        setSearchResults(response.data.items);
        handleDialogOpen();
    }

    const handleCheckboxChange = (event, data) => {
        if (event.target.checked) {
            setMusicTracksToAdd(musicTracksToAdd.concat([data]))
        } else {
            const dataIndex = musicTracksToAdd.indexOf(data)
            musicTracksToAdd.splice(dataIndex, 1);
        }
    }


    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <React.Fragment>
                    <TextField
                        placeholder={getTranslation("search")}
                        type="text"
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button onClick={handleSearch}>{getTranslation("submit")}</Button>
                    <Dialog fullWidth maxWidth="md" open={open} onClose={handleDialogClose}>
                        <DialogTitle>{getTranslation("search_results")}</DialogTitle>
                        <List>
                            {searchResults.map((result) => {
                                return (
                                    <ListItem>
                                        <ListItemIcon>
                                            <Checkbox style={{ right: "8%" }} edge="start" disableRipple onChange={(e) => handleCheckboxChange(e, result)}/>
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <img style={{ height: 90, maxWidth: 90, objectFit: "cover" }} src={result.snippet.thumbnails.medium.url}/>
                                        </ListItemIcon>
                                        <ListItemText style={{ paddingLeft: 8, width: '45%' }} primary={truncate(result.snippet.title, { length: 50 })} secondary={truncate(result.snippet.description, { length: 50 })}/>
                                    </ListItem>
                                  );
                            })}
                        </List>
                    </Dialog>
                </React.Fragment>
            )}
        </LocaleConsumer>
    );
}

const mapStateToProps = (state) => {
    return {
        musicTracks: musicTrackSelector.getAll(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createPlayerMusicTrack: (playerId, { title, description, url, thumbnail, position }) => dispatch(createPlayerMusicTrack(playerId, { title, description, url, thumbnail, position })),
        fetchObjects: (playerId) => dispatch(fetchMusicTracks(playerId)),
        replacePlayerObjectAsset: (playerId, unityId, assets) => {
            dispatch(replacePlayerObjectAsset(playerId, unityId, assets));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);