import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, { NormalizedActionMap, Strategies } from 'reducers/createRequestReducer';
import objectSchema from 'reducers/schemas/object';
import { normalize } from 'normalizr';

export const objectSelector = {
    getNormalizedState: (state) => state.objects.objects,
};

const PLAYER_OBJECTS = 'PLAYER_OBJECTS';
const PLAYER_OBJECTS_SUCCESS = 'PLAYER_OBJECTS_SUCCESS';
const PLAYER_OBJECTS_ERROR = 'PLAYER_OBJECTS_ERROR';

export const fetchPlayerObjects = (playerId) => (dispatch) => {
    dispatch({ type: PLAYER_OBJECTS });
    return axiosInstance
        .get(`/players/${playerId}/unity_objects`)
        .then(function (response) {
            const normalized = normalize(response.data.data, [objectSchema]);
            dispatch({ type: PLAYER_OBJECTS_SUCCESS, normalized });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: PLAYER_OBJECTS_ERROR, error: error.toObject() });
            return Promise.reject(error);
        });
};

export default combineReducers({
    objects: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('objects'),
        defaultStrategy: Strategies.merge,
    }),
});
