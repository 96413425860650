import { mapValues, values, forEach } from 'lodash-es';
import { RequestError } from 'utils/axiosInstance';

export const validationErrorToSubmissionError = (dataOrRequestError) => {
    let data = dataOrRequestError;
    let _error = '';

    if (dataOrRequestError instanceof RequestError) {
        data = dataOrRequestError.getData();
        _error = dataOrRequestError.getMessage();
    }

    const result = mapValues(data, (v) => {
        return values(v)[0];
    });

    result._error = _error;
    return result;
};

export const objectToFormData = (plainObject) => {
    const formData = new FormData();
    forEach(plainObject, (value, key) => {
        if (value instanceof File || value instanceof Blob || typeof value === 'string') {
            formData.append(key, value);
        } else if (typeof value.toJSON === 'function') {
            formData.append(key, value.toJSON());
        } else {
            formData.append(key, JSON.stringify(value));
        }
    });
    return formData;
};
