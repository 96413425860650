/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from 'containers/App';
import AuthContainer from 'containers/auth/AuthContainer';
import newTheme from 'ui/themes/new';
import { StripeProvider } from 'react-stripe-elements';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import LocaleProvider from 'containers/locale/LocaleProvider'

export default class Root extends Component {
    render() {
        const { store, history } = this.props;
        const StrictMode = process.env.NODE_ENV === 'production' ? React.Fragment : React.StrictMode;

        return (
            <Provider store={store}>
                    <LocaleProvider>
                        <ConnectedRouter history={history}>
                            <MuiThemeProvider theme={newTheme}>
                                <StripeProvider apiKey={STRIPE_KEY}>
                                    <StrictMode>
                                        <AuthContainer>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <App />
                                            </MuiPickersUtilsProvider>
                                        </AuthContainer>
                                    </StrictMode>
                                </StripeProvider>
                            </MuiThemeProvider>
                        </ConnectedRouter>
                    </LocaleProvider>
            </Provider>
        );
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
