import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchUserSessions, sessionsSelector } from 'reducers/sessions';
import { connect } from 'react-redux';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import SessionDuration from 'containers/sessions/SessionDuration';
import moment from 'moment';
import SessionIcon from 'containers/sessions/SessionIcon';
import { Typography } from '@material-ui/core';
import PlayerAvatar from 'containers/players/PlayerAvatar';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {};

class UserSessionsList extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { userId, institutionId } = this.props;
        this.props.fetchSessions(userId, institutionId);
    }

    renderSessions() {
        const { classes, sessions } = this.props;
        if (!sessions.length) {
            return (
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <div>
                            <Typography align="center">{getTranslation("user_sessions_empty")}</Typography>
                        </div>
                    )}
                </LocaleConsumer>
            );
        }
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Table className={classes.table}>
                        <colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="40%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{getTranslation("player_sessions_date")}</TableCell>
                                <TableCell>{getTranslation("player_sessions_duration")}</TableCell>
                                <TableCell>{getTranslation("player_sessions_with")}</TableCell>
                                <TableCell>{getTranslation("player_sessions_comment")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sessions.map((session) => {
                                return (
                                    <TableRow key={session.id}>
                                        <TableCell>
                                            {moment.parseZone(session.local_end).format('L')}
                                            {getTranslation("player_sessions_at")}
                                            {moment.parseZone(session.local_end).format('LT')}
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <PlayerAvatar player={session.player} />
                                                <div>{session.player.display_name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <SessionDuration duration={session.duration} /> {getTranslation("minutes")}
                                        </TableCell>
                                        <TableCell style={{ width: 150 }}>
                                            <SessionIcon name={session.rating} /> {session.comment}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>
                {isLoading && <FullWidthLoader />}
                {!isLoading && this.renderSessions()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        userId: PropTypes.number.isRequired,
        institutionId: PropTypes.number.isRequired,
    };
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const sessionsState = sessionsSelector.getCurrentUserSessionState(state);
    return {
        sessions: sessionsSelector.getCurrentUserSessions(state),
        isLoading: RequestStateHelper.isLoading(sessionsState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSessions: (userId, institutionId) => {
            dispatch(fetchUserSessions(userId, institutionId));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserSessionsList));
