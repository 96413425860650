import React from 'react';
import { Card, Tab, Tabs } from '@material-ui/core';
import { matchPath, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import InstitutionPage from 'containers/pages/InstitutionPage';
import ManageInstitutionPlayersPage from 'containers/pages/ManageInstitutionPlayersPage';
import ManageInstitutionCaregiversPage from 'containers/pages/ManageInstitutionCaregiversPage';
import InstitutionStatsPage from 'containers/pages/InstitutionStatsPage';
import { history } from 'store/configureStore';
import { usersSelector } from 'reducers/users';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

class InstitutionLayout extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleTabChange = (event, value) => {
        history.push(`/institution/${value}`);
    };

    getCurrentPage() {
        const match = matchPath(this.props.location.pathname, { path: '/institution/:page' });
        return match.params.page;
    }

    /**
     * Render component
     */
    render() {
        const { user } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        {user.original_institution_logo_url && (
                            <img src={user.original_institution_logo_url} style={{ maxWidth: 250, marginBottom: 20 }} alt="" />
                        )}
                        <Card>
                            <Tabs
                                onChange={this.handleTabChange}
                                value={this.getCurrentPage()}
                                indicatorColor="primary"
                                textColor="primary"
                                centered>
                                <Tab label={getTranslation("institution_layout_player")} value="players" />
                                <Tab label={getTranslation("institution_layout_care_team")} value="caregivers" />
                                <Tab label={getTranslation("institution_layout_sessions")} value="stats" />
                                <Tab label={getTranslation("institution_layout_organisation")} value="address" />
                            </Tabs>
                            <div>
                                <Route path={`/institution/players`} component={ManageInstitutionPlayersPage} />
                                <Route path={`/institution/caregivers`} component={ManageInstitutionCaregiversPage} />
                                <Route path={`/institution/stats`} component={InstitutionStatsPage} />
                                <Route path={`/institution/address`} component={InstitutionPage} />
                            </div>
                        </Card>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}
const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default compose(withRouter, connect(mapStateTopProps))(InstitutionLayout);
