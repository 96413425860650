/*
Authors: Niklaas Cotta
Last modified: 10/3/2023
Description: obtain music tracks from backend
| type   | column          | description |
|--------|-----------------| ----------- |
| int    | `id`            |             |
| string | `title`         |             |
| string | `description`   |             |
| string | `url`           |             |
| string | `thumbnail`     |             |
*/

import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';

import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import musicTrackSchema from 'reducers/schemas/musicTrack';

import { normalize } from 'normalizr';

import { map, values, sortBy, concat, filter } from 'lodash-es';

const PLAYER_MUSIC_TRACK = 'PLAYER_MUSIC_TRACK';
const PLAYER_MUSIC_TRACK_SUCCESS = 'PLAYER_MUSIC_TRACK_SUCCESS';
const PLAYER_MUSIC_TRACK_ERROR = 'PLAYER_MUSIC_TRACK_ERROR';

const REPLACE_PLAYER_MUSIC_TRACK = 'REPLACE_PLAYER_MUSIC_TRACK';
const REPLACE_PLAYER_MUSIC_TRACK_SUCCESS = 'REPLACE_PLAYER_MUSIC_TRACK_SUCCESS';
const REPLACE_PLAYER_MUSIC_TRACK_ERROR = 'REPLACE_PLAYER_MUSIC_TRACK_ERROR';

const CREATE_PLAYER_MUSIC_TRACK = 'CREATE_PLAYER_MUSIC_TRACK';
const CREATE_PLAYER_MUSIC_TRACK_SUCCESS = 'CREATE_PLAYER_MUSIC_TRACK';
const CREATE_PLAYER_MUSIC_TRACK_ERROR = 'CREATE_PLAYER_MUSIC_TRACK';

const DELETE_PLAYER_OBJECT_ASSET = 'DELETE_PLAYER_OBJECT_ASSET';
const DELETE_PLAYER_OBJECT_ASSET_SUCCESS = 'DELETE_PLAYER_OBJECT_ASSET_SUCCESS';
const DELETE_PLAYER_OBJECT_ASSET_ERROR = 'DELETE_PLAYER_OBJECT_ASSET_ERROR';

const SORT_PLAYER_OBJECT_ASSET = 'SORT_PLAYER_OBJECT_ASSET';
const SORT_PLAYER_OBJECT_ASSET_SUCCESS = 'SORT_PLAYER_OBJECT_ASSET_SUCCESS';
const SORT_PLAYER_OBJECT_ASSET_ERROR = 'SORT_PLAYER_OBJECT_ASSET_ERROR';


let currentId = 0;



export const musicTrackSelector = {
    getMusicTrackState: (state) => state.assets.playerAssets,
    getAll: (state) => NormalizedSelector.getByIds(state.musicTracks.musicTracks, state.musicTracks.all)
};


export const fetchMusicTracks = (playerId) => (dispatch) => {
    let operationId = currentId++;
    dispatch({ operationId, type: PLAYER_MUSIC_TRACK });

    return axiosInstance
        .get(`/players/${playerId}/music_tracks`)
        .then(function (response) {
            dispatch({
                operationId,
                type: PLAYER_MUSIC_TRACK_SUCCESS,
                normalized: normalize(response.data.data, [musicTrackSchema])
            });

            return response;
        })
        .catch(function (error) {
            dispatch({ 
                operationId, 
                type: PLAYER_MUSIC_TRACK_ERROR, 
                error: error.toObject() 
            });

            return Promise.reject(error);
        })
};



export const createPlayerMusicTrack = (playerId, { title, description, url, thumbnail }) => (dispatch) => {
    dispatch({ type: CREATE_PLAYER_MUSIC_TRACK });

    return axiosInstance
        .post(`/players/${playerId}/music_tracks`, {
            title, 
            description,
            url,
            thumbnail,
        })
        .then(function (response) {
            const normalized = normalize(response.data.data, musicTrackSchema)
            dispatch({
                type: CREATE_PLAYER_MUSIC_TRACK_SUCCESS,
                normalized,
                id: normalized.result
            });

            return response;
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_PLAYER_MUSIC_TRACK_ERROR,
                error: error.toObject()
            });

            return Promise.reject(error);
        })
};



export const replacePlayerMusicTrack = (musicTrackId, values) => (dispatch) => {
    dispatch({ type: REPLACE_PLAYER_MUSIC_TRACK});
    console.log("REPLACING")
    return dispatchNormalizedPromise(
        axiosInstance.put(`music_tracks/${musicTrackId}`, values),
        musicTrackShema,
        [REPLACE_PLAYER_MUSIC_TRACK_SUCCESS, REPLACE_PLAYER_MUSIC_TRACK_ERROR],
        dispatch,
        { musictrackId }
    );
}



export const deletePlayerMusicTrack = (musicTrackId) => (dispatch) => {
    dispatch({ type: DELETE_PLAYER_OBJECT_ASSET });

    return dispatchNormalizedPromise(
        axiosInstance.delete(`/music_tracks/${musicTrackId}`),
        null,
        [DELETE_PLAYER_OBJECT_ASSET_SUCCESS, DELETE_PLAYER_OBJECT_ASSET_ERROR],
        dispatch,
        { musicTrackId }
    );
};



export default combineReducers({
    musicTracks: createRequestReducer([null, '*', null], { 
        mapResponse: NormalizedActionMap.entities('musicTracks'), 
        defaultStrategy: Strategies.merge 
    }),
    playerAssets: createRequestReducer(
        [
            PLAYER_MUSIC_TRACK,
            [
                PLAYER_MUSIC_TRACK_SUCCESS, 
                { 
                    type: DELETE_PLAYER_OBJECT_ASSET_SUCCESS, 
                    strategy: Strategies.remove, 
                    mapResponse: (action) => action.musicTrackId
                }
            ], 
            PLAYER_MUSIC_TRACK_ERROR
        ],
        { ignoreData: true, useOperationId: true }
    ),
    all: createRequestReducer([PLAYER_MUSIC_TRACK, PLAYER_MUSIC_TRACK_SUCCESS, PLAYER_MUSIC_TRACK_ERROR], {
        mapResponse: NormalizedActionMap.result 
    }),
    playerAssetsSort: ( 
        state = { 
            current: null, 
            optimistic: null, 
            isUpdating: false
        }, 
        action 
    ) => {
        const getOrdered = (entitiesByKey) => {
            return values(map(sortBy(values(entitiesByKey || {}), 'position'), 'id'));
        };

        switch (action.type) {
            case PLAYER_MUSIC_TRACK: {
                return { 
                    current: [], 
                    optimistic: null, 
                    isUpdating: false 
                };
            }

            case PLAYER_MUSIC_TRACK_SUCCESS: {
                return { 
                    current: getOrdered(action.normalized.entities.assets), 
                    optimistic: null,
                    isUpdating: false
                };
            }   

            case CREATE_PLAYER_MUSIC_TRACK_SUCCESS: {
                return { 
                    current: concat(state.current, action.id), 
                    optimistic: null, 
                    isUpdating: false 
                };
            } 

            case SORT_PLAYER_OBJECT_ASSET: {
                return { 
                    ...state, 
                    isUpdating: true,
                    optimistic: action.data.newOrder, 
                };
            }

            case REPLACE_PLAYER_MUSIC_TRACK: {
                return {
                    current: action.normalized.result,
                    optimistic: null,
                    isUpdating: false
                };
            }

            case DELETE_PLAYER_OBJECT_ASSET: {
                return {
                    ...state,
                    isUpdating: true,
                    optimistic: filter(state.current, (value) => value !== action.id),
                };
            }

            case DELETE_PLAYER_OBJECT_ASSET_SUCCESS:
            case SORT_PLAYER_OBJECT_ASSET_SUCCESS: {
                return {
                    ...state,
                    current: state.optimistic || state.current,
                    isUpdating: false,
                    optimistic: null,
                };
            }

            case DELETE_PLAYER_OBJECT_ASSET_ERROR:
            case SORT_PLAYER_OBJECT_ASSET_ERROR: {
                return {
                    ...state,
                    isUpdating: false,
                    optimistic: null,
                };
            }
        }

        return state;
    }
});