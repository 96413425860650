import React, { useEffect, useState } from 'react';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { createVideoLink, deleteLink, fetchChannelLinks, videoLinksSelector } from 'reducers/videoLinks';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, Typography } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import VideoLinkInput from 'containers/videoChannels/VideoLinkInput';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { truncate } from 'lodash-es';
import VideoUploadInput from 'containers/videoChannels/VideoUploadInput';
import fallbackImage from 'images/no_preview.png'
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    image: {
        marginRight: 10,
        height: 50,
        maxWidth: 100,
    },
};

function VideoChannelLinksDialog({
    classes,
    isFetching,
    open,
    onClose,
    channelId,
    fetchVideoLinks,
    createVideoLink,
    deleteLink,
    videoLinks,
    editable,
    title = 'My Channel',
}) {
    useEffect(() => {
        channelId && fetchVideoLinks(channelId);
    }, [channelId]);
    const [url, setUrl] = useState('');
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [creating, setCreating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const isCurrentUrlContainedInLoadedInLinks = videoLinks.some((videoLink) => videoLink.url === url);

    const handleLinkAdd = ({ title, description, thumbnailUrl }) => {
        setCreating(true);
        setInvalidUrl(false);
        createVideoLink(channelId, { title, description, url, thumbnailUrl })
            .then(() => setUrl(null))
            .catch(() => setInvalidUrl(true))
            .finally(() => setCreating(false));
    };
    const handleCustomVideoAdd = ({ videoId, thumbnailUrl }) => {
        setCreating(true);
        createVideoLink(channelId, {
            title: new Date().toString(),
            customVideoId: videoId,
            description: '',
            thumbnailUrl,
        })
            .then(() => setUrl(null))
            .finally(() => setCreating(false));
    };
    const handleLinkRemove = (linkId) => {
        setRemoving(true);
        deleteLink(linkId).finally(() => setRemoving(false));
    };
    const handleUrlChange = (v) => {
        setInvalidUrl(false);
        setUrl(v);
    };

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
                    <DialogTitle>{title}</DialogTitle>
                    {isFetching && <FullWidthLoader />}
                    {!isFetching && (
                        <DialogContent>
                            {!videoLinks.length && (
                                <Typography align="center" color="textSecondary">
                                    {getTranslation("asset_editor_video_channel_empty")}
                                </Typography>
                            )}
                            <List>
                                {videoLinks.map((videoLink) => {
                                    return (
                                        <ListItem key={videoLink.id}>
                                            <ListItemIcon>
                                                <img className={classes.image} src={videoLink.cover_url || fallbackImage} alt="" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={videoLink.title}
                                                secondary={truncate(videoLink.description, { length: 50 })}
                                            />
                                            {editable && (
                                                <ListItemIcon>
                                                    <IconButton
                                                        disabled={removing}
                                                        onClick={() => handleLinkRemove(videoLink.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            )}
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </DialogContent>
                    )}
                    {creating && (
                        <DialogContent>
                            <FullWidthLoader />
                        </DialogContent>
                    )}
                    {editable && !creating && (
                        <React.Fragment>
                            <DialogTitle>{getTranslation("asset_editor_video_channel_link_add_youtube")}</DialogTitle>
                            <DialogContent>
                                {!creating && (
                                    <VideoLinkInput
                                        value={url}
                                        onChange={handleUrlChange}
                                        onConfirm={handleLinkAdd}
                                        error={
                                            (isCurrentUrlContainedInLoadedInLinks && 'This video is already in the channel') ||
                                            (invalidUrl && "This video website isn't supported")
                                        }
                                    />
                                )}
                            </DialogContent>
                            <DialogTitle>{getTranslation("asset_editor_video_channel_upload_title")}</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    {getTranslation("asset_editor_video_channel_upload_desc")}
                                </Typography>
                            </DialogContent>
                            <DialogContent>
                                <VideoUploadInput handleUploaded={handleCustomVideoAdd} />
                            </DialogContent>
                        </React.Fragment>
                    )}
                </Dialog>
            )}
        </LocaleConsumer>
    );
}

VideoChannelLinksDialog.propTypes = {};
VideoChannelLinksDialog.defaultProps = {};

const mapStateToProps = (state) => {
    const videoLinksState = videoLinksSelector.getCurrentChannelVideoLinksState(state);
    return {
        isFetching: RequestStateHelper.isLoading(videoLinksState),
        videoLinks: videoLinksSelector.getCurrentChannelVideoLinks(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideoLinks: (videoChannelId) => dispatch(fetchChannelLinks(videoChannelId)),
        createVideoLink: (videoChannelId, data) => dispatch(createVideoLink(videoChannelId, data)),
        deleteLink: (linkId) => dispatch(deleteLink(linkId)),
    };
};
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(VideoChannelLinksDialog);
