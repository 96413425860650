import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const styles = {
    card: {
        color: green['500'],
    },
    icon: {
        '& > *:first-child': {
            verticalAlign: 'middle',
        },
    },
};

class PaymentInfos extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, card, icon } = this.props;

        return (
            <Typography className={classes.card}>
                <span className={classes.icon}>{icon}</span> ({card.brand}) *** *** *** {card.last4}
                <br />
                <b>Expires on</b> {card.exp_month}/{card.exp_year}
            </Typography>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PaymentInfos);
