/* 
Author: Niklaas Cotta
Last modified: 7/31/2023
Description: obtain languages

| type   | column | description |
|--------|--------| ----------- |
| int    | `id`   | unique id
| string | `code` | three-letter abbreviation (ENG, FRA, GER, etc)
| string | `name` | full name of language (English, French, German, etc)
*/

import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import languageSchema from 'reducers/schemas/language';



// Helper functions/constants

// Get state of and languages of books
export const languagesSelector = {
    getCurrentLanguagesState: (state) => state.languages.currentLanguages,
    getCurrentLanguages: (state) => NormalizedSelector.getByIds(state.languages.languages, state.languages.currentLanguages),
};

const CURRENT_LANGUAGES = 'CURRENT_LANGUAGES';
const CURRENT_LANGUAGES_SUCCESS = 'CURRENT_LANGUAGES_SUCCESS';
const CURRENT_LANGUAGES_ERROR = 'CURRENT_LANGUAGES_ERROR';

// Connect to backend with axios and obtain language objects
export const fetchLanguages = () => (dispatch) => {
    dispatch({ type: CURRENT_LANGUAGES });

    return dispatchNormalizedPromise(
        axiosInstance.get(`/language`),
        [languageSchema],
        [CURRENT_LANGUAGES_SUCCESS, CURRENT_LANGUAGES_ERROR],
        dispatch
    );
};


// Combine reducers and export
export default combineReducers({
    languages: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('languages'),
        defaultStrategy: Strategies.merge,
    }),
    currentLanguages: createRequestReducer([CURRENT_LANGUAGES, [CURRENT_LANGUAGES_SUCCESS], CURRENT_LANGUAGES_ERROR], {
        mapResponse: NormalizedActionMap.result
    })
});