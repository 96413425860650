import React from 'react';
import { TextField } from '@material-ui/core';
import mapError from 'ui/base/mapError';

class ValidatedTextField extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { input, meta, ...otherProps } = this.props;

        const errorProps = mapError(this.props);

        return <TextField {...input} {...otherProps} {...errorProps} />;
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default ValidatedTextField;
