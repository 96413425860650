import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import { usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {};

class InstitutionStatsSummary extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, user } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Typography>
                            <b>{getTranslation("institution_stats_summary_total_players")}</b>: {user.players_count}
                            <br />
                            <b>{getTranslation("institution_stats_summary_total_caregivers")}</b>: {user.caregivers_count}
                            <br />
                            <b>{getTranslation("institution_stats_summary_total_sessions")}</b>: {user.total_sessions_count}
                            <br />
                        </Typography>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default withStyles(styles)(connect(mapStateTopProps)(InstitutionStatsSummary));
