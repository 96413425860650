import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import Header from 'containers/pages/layouts/Header';
import HomePage from 'containers/pages/HomePage';
import PlayerLayout from 'containers/pages/layouts/PlayerLayout';
import AccountPage from 'containers/pages/AccountPage';
import HintsPage from 'containers/pages/HintsPage';
import IntroductionVideoPage from 'containers/pages/IntroductionVideoPage';
import QuickStartPage from 'containers/pages/QuickStartPage';
import SetupPlayerVideoPage from 'containers/pages/videos/SetupPlayerVideoPage';
import StartSessionVideoPage from 'containers/pages/videos/StartSessionVideoPage';
import PlayDevaVideoPage from 'containers/pages/videos/PlayDevaVideoPage';
import EndSessionVideoPage from 'containers/pages/videos/EndSessionVideoPage';
import InstitutionLayout from 'containers/pages/layouts/InstitutionLayout';
import InstitutionRedirect from 'containers/users/InstitutionRedirect';

const styles = {
    mainContent: {
        padding: '0px 15px',
    },
    mainContainer: {
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    row: {
        textAlign: 'inherit',
        padding: '25px 0px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
};

class MainLayout extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Header />
                <InstitutionRedirect/>
                <div className={classes.mainContainer}>
                    <Row className={classes.row} center="xs">
                        <Col xs={12} md={10}>
                            <div className={classes.mainContent}>
                                <Switch>
                                    <Route exact path="/" component={HomePage} />
                                    <Route path="/quick-start" component={QuickStartPage} />
                                    <Route path="/account" component={AccountPage} />
                                    <Route path="/institution" component={InstitutionLayout} />
                                    <Route path="/tips" component={HintsPage} />
                                    <Route path="/intro-video" component={IntroductionVideoPage} />
                                    <Route path="/video/setup-player" component={SetupPlayerVideoPage} />
                                    <Route path="/video/start-session" component={StartSessionVideoPage} />
                                    <Route path="/video/play-deva" component={PlayDevaVideoPage} />
                                    <Route path="/video/end-session" component={EndSessionVideoPage} />
                                    <Route path="/player/:playerId" component={PlayerLayout} />
                                </Switch>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(MainLayout);
