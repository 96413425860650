import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { usersSelector } from 'reducers/users';

class AuthRoute extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { component: Component, requireUnauthenticated, ...rest } = this.props;

        const isAuthenticated = this.props.isAuthenticated;

        return (
            <Route
                {...rest}
                render={(props) => {
                    // const introViewed = _.get(this.props, 'user.is_intro_viewed');
                    // if (isAuthenticated && props.location.pathname !== '/intro' && !introViewed) {
                    //     return <Redirect to={{ pathname: '/intro' }} />;
                    // }
                    // if (isAuthenticated && props.location.pathname === '/intro' && introViewed) {
                    //     return <Redirect to={{ pathname: '/' }} />;
                    // }

                    if (requireUnauthenticated && isAuthenticated) {
                        if (this.props.user.is_institution) {
                            return <Redirect to={{ pathname: '/institution/players' }} />;
                        }
                        return <Redirect to={{ pathname: '/' }} />;
                    }

                    if (isAuthenticated || (requireUnauthenticated && !isAuthenticated)) {
                        return <Component {...props} />;
                    }

                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }}
            />
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: usersSelector.getCurrentUser(state),
        isAuthenticated: usersSelector.isCurrentUserAuthenticated(state),
    };
};

export default connect(mapStateToProps, null)(AuthRoute);
