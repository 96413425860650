import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchInstitutionUsersWithStats, usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import SessionsRatingMinistat from 'containers/sessions/SessionsRatingMinistat';
import SessionsPartOfDayMinistat from 'containers/sessions/SessionsPartOfDayMinistat';
import moment from 'moment';
import TimerIcon from '@material-ui/icons/Timer';
import { some } from 'lodash-es';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import UserSessionsList from 'containers/sessions/UserSessionsList';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {};

class InstitutionUsersStatsList extends React.Component {
    /**
     * Component state
     */
    state = {
        sessionListModalUserId: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchUsers(this.props);
    }

    componentDidUpdate(prevProps) {
        const { institutionId, from, to } = prevProps;
        const { institutionId: nextinstitutionId, from: nextFrom, to: nextTo } = this.props;
        if (institutionId !== nextinstitutionId || from !== nextFrom || to !== nextTo) {
            this.props.fetchUsers(this.props);
        }
    }

    handleUserSessionsClick = (userId) => {
        this.setState({ sessionListModalUserId: userId });
    };

    handleUserSessionsModalRequestClose = (event) => {
        this.setState({ sessionListModalUserId: null });
    };

    renderList() {
        const { users, institutionId, noDataText, isRefetching } = this.props;
        const { sessionListModalUserId } = this.state;
        if (!users.length) {
            return (
                <div style={{ padding: '20px 0px' }}>
                    <Typography align="center">{noDataText}</Typography>
                </div>
            );
        }
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            onClose={this.handleUserSessionsModalRequestClose}
                            open={sessionListModalUserId !== null}>
                            <DialogTitle>Sessions</DialogTitle>
                            <DialogContent>
                                <UserSessionsList userId={sessionListModalUserId} institutionId={institutionId} />
                            </DialogContent>
                        </Dialog>
                        <Table style={{ opacity: isRefetching ? 0.5 : 1 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{getTranslation("email")}</TableCell>
                                    <TableCell>{getTranslation("institution_stats_caregivers_number")}</TableCell>
                                    <TableCell>{getTranslation("institution_stats_players_how")}</TableCell>
                                    <TableCell>{getTranslation("institution_stats_players_time")}</TableCell>
                                    <TableCell>{getTranslation("institution_stats_players_average_duration")}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => {
                                    if (!user.sessions_stat) {
                                        return (
                                            <TableRow key={user.id}>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>0</TableCell>
                                                <TableCell>-</TableCell>
                                                <TableCell>-</TableCell>
                                                <TableCell>-</TableCell>
                                            </TableRow>
                                        );
                                    }

                                    const durationInMinutes = moment
                                        .duration(user.sessions_stat.average_duration_second, 'seconds')
                                        .asMinutes();
                                    return (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.sessions_stat.count}</TableCell>
                                            <TableCell>
                                                <SessionsRatingMinistat
                                                    r1Count={user.sessions_stat.rating1_count}
                                                    r2Count={user.sessions_stat.rating2_count}
                                                    r3Count={user.sessions_stat.rating3_count}
                                                    total={user.sessions_stat.count}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <SessionsPartOfDayMinistat
                                                    morningCount={user.sessions_stat.morning_count}
                                                    afternoonCount={user.sessions_stat.afternoon_count}
                                                    eveningCount={user.sessions_stat.evening_count}
                                                    total={user.sessions_stat.count}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TimerIcon style={{ verticalAlign: 'middle' }} />
                                                <FormattedNumber maximumFractionDigits={0} value={durationInMinutes} /> {getTranslation("minutes")}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => this.handleUserSessionsClick(user.id)}>{getTranslation("institution_stats_players_details")}</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>
                {isLoading && <FullWidthLoader />}
                {!isLoading && this.renderList()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        noDataText: PropTypes.string,
    };
    static defaultProps = {
        noDataText: 'Institution empty',
    };
}
const getContain = ({ from, to, institutionId }) => {
    return {
        sessions_stat: {
            contain: 'SessionsStat',
            from: from && from.toISOString(),
            to: to && to.toISOString(),
            institutionId,
        },
    };
};
const mapStateToProps = (state, ownProps) => {
    const usersState = usersSelector.getInstitutionUsersState(state);
    const users = usersSelector.getInstitutionUsersWithStats(state, getContain(ownProps));
    return {
        users,
        isRefetching: users.length && !some(users, 'sessions_stat') && RequestStateHelper.isLoading(usersState),
        isLoading: !users.length && RequestStateHelper.isLoading(usersState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: (props) => {
            dispatch(fetchInstitutionUsersWithStats(props.institutionId, getContain(props)));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstitutionUsersStatsList));
