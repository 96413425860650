import React from 'react';
import {TextField, Typography} from '@material-ui/core';
import mapError from 'ui/base/mapError';
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";

class PasswordField extends React.Component {
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            isHide: true,
            value: ""
        };
        this.showHide = this.showHide.bind(this)
        this.updateValue = this.updateValue.bind(this)
    }

    showHide() {
        this.setState(previousState => {
            return {
                ...previousState,
                isHide: !previousState.isHide
            }
        })
    }

    progressToText(value) {
        if (value < 6) {
            return 'Weak';
        } else if (value < 12) {
            return 'Normal';
        } else if (value < 16) {
            return 'Strong';
        } else {
            return 'Very strong';
        }
    }

    updateValue(event) {
        event.preventDefault()
        event.persist()
        if (event.target) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    value: event.target.value
                }
            })
        }
    }

    /**
     * Render component
     */
    render() {
        const { input, meta, fullWidth, progress, ...otherProps } = this.props;

        const errorProps = mapError(this.props);

        const progressValue = Math.min(this.state.value ? this.state.value.length / 24 : 0, 1) * 100;

        return (
            <>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <TextField {...input} {...otherProps} {...errorProps}
                       type={this.state.isHide ? 'password' : 'text'}
                       style={{flexGrow: fullWidth ? 1 : 0}}
                       value={this.state.value}
                       onChange={this.updateValue}
                    />
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.showHide}
                    >
                        {this.state.type === "password" ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </div>
                {progress && progressValue > 0 &&
                    <>
                        <br/>
                        <br/>
                        <div>
                            <LinearProgress variant="determinate" value={progressValue} />
                            <Typography style={{ marginTop: 5 }}>{this.progressToText(this.state.value.length)}</Typography>
                        </div>
                    </>
                }
            </>
        )
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default PasswordField;
