import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Avatar } from '@material-ui/core';

const styles = {
    avatar: {
        backgroundColor: '#e5e5e5',
    },
};

class PlayerAvatar extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const {
            classes,
            player: { initials, photo_url },
            className,
            ...otherProp
        } = this.props;
        const classNames = [classes.avatar, className].join(' ');

        if (photo_url) return <Avatar {...otherProp} src={photo_url} className={classNames} />;

        return (
            <Avatar {...otherProp} className={classNames}>
                {initials}
            </Avatar>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PlayerAvatar);
