import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { reduxForm, SubmissionError } from 'redux-form';
import PlayerEditRForm from 'containers/players/PlayerEditRForm';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { createUserPlayer } from 'reducers/players';
import PlayerPaymentRForm from 'containers/players/PlayerPaymentRForm';
import PlayerPaymentRFormSidebar from 'containers/players/PlayerPaymentRFormSidebar';
import { injectStripe } from 'react-stripe-elements';
import { RequestError } from 'utils/axiosInstance';
import TermsOfUseLink from 'containers/contents/TermsOfUseLink';
import moment from 'moment/moment';
import MainConfig from 'constants/MainConfig';
import { branch, compose } from 'recompose';
import { fetchInstitutionUsers, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'
import { localeCookieName} from "../locale/LocaleProvider";
import Cookie from "../../utils/Cookie";

const styles = {};
const defaultLanguage = ((navigator.language || navigator.userLanguage)) || "en";
const defaultLocale = (Cookie.get(localeCookieName)) ?? defaultLanguage;

const hasPaymentForm = (user) => {
    return !user.is_free && !user.has_card && !user.subscription_is_trialing;
};

const onSubmit = (values, dispatch, props) => {
    const withToken = hasPaymentForm(props.user);
    let mainPromise = withToken
        ? props.stripe.createToken().then(({ token, error }) => {
              if (error) {
                  throw new SubmissionError({ _error: error.message });
              }
              return dispatch(
                  createUserPlayer(props.user.id, {
                      ...values,
                      card_token: token.id,
                  })
              );
          })
        : dispatch(createUserPlayer(props.user.id, values));

    return mainPromise
        .then(() => {
            props.onRequestClose();
        })
        .catch((error) => {
            if (error instanceof RequestError) {
                const errors = validationErrorToSubmissionError(error);
                if (errors.card_token) errors._error = errors.card_token;
                throw new SubmissionError(errors);
            }
            return Promise.reject(error);
        });
};

const AddForm = reduxForm({
    form: 'addPlayer',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};
        if (!values.nickname) {
            errors.nickname = defaultLocale.substring(0, 2) === "fr" ? 'Le petit nom est obligatoire' :  'Informal name is required';
        }
        return errors;
    },
})(PlayerEditRForm);

const PaymentForm = reduxForm({
    form: 'addPlayer',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(PlayerPaymentRForm);

class PlayerAddModal extends React.Component {
    /**
     * Component state
     */
    state = {
        page: 0,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { user } = this.props;
        if (user.is_institution) {
            this.props.fetchInstitutionCaregivers(this.props.user.id);
        }
    }

    componentDidUpdate(prevProps) {
        const { open } = prevProps;
        if (open && !this.props.open) {
            this.props.reset();
        }
        if (!open && this.props.open) {
            this.setState({ page: 0 });
        }
    }

    hasPaymentForm() {
        const { user } = this.props;
        return hasPaymentForm(user);
    }

    handleRequestClose = () => {
        this.props.onRequestClose();
    };

    handleSubmit = () => {
        const { page } = this.state;

        if (page === 0 && this.hasPaymentForm()) {
            return this.setState({ page: 1 });
        }

        this.props.handleSubmit();
    };

    renderTermsOfService() {
        const { user, currentPlayersCount } = this.props;
        const termsOfUse = <TermsOfUseLink />;

        if (user.is_institution) {
            return (
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <Typography>
                            {getTranslation("player_create_add_info", user.institution_name)}
                        </Typography>
                    )}
                </LocaleConsumer>
            );
        }

        if (user.is_free) return null;

        if (!user.is_institution && user.subscription_is_trialing) {
            const duration = moment.duration(moment(user.subscription_trial_end).diff(moment()));
            const remainingTime = `${duration.days()}`;
            if (currentPlayersCount === 0) {
                return (
                    <Typography>
                        By pressing "Create", you accept our {termsOfUse}, and you have {remainingTime} days free. After
                        this period, you will be charged <b>${MainConfig.pricePerPlayer} per month</b>. You can always
                        cancel any player you don’t want to play with on the player’s page.
                    </Typography>
                );
            }
            if (currentPlayersCount > 0) {
                return (
                    <Typography>
                        By pressing "Create", you accept our {termsOfUse}, and you have {remainingTime} days free. After
                        this period, you will be charged ${(currentPlayersCount + 1) * MainConfig.pricePerPlayer} per
                        month for {currentPlayersCount + 1} players. You can always cancel your player's subscription at
                        any time.
                    </Typography>
                );
            }
        } else {
            if (currentPlayersCount === 0) {
                return (
                    <Typography>
                        By pressing "Create", you accept our {termsOfUse}, and you will be charged $
                        {MainConfig.pricePerPlayer} per month. You can always cancel any player you don’t want to play
                        with on the player’s page.
                    </Typography>
                );
            }
            if (currentPlayersCount > 0) {
                return (
                    <Typography>
                        By pressing "Create", you accept our {termsOfUse}, and you will be charged $
                        {(currentPlayersCount + 1) * MainConfig.pricePerPlayer} per month for {currentPlayersCount + 1}{' '}
                        players. You can always cancel any player you don’t want to play with on the player’s page.
                    </Typography>
                );
            }
        }

        return null;
    }

    renderAddForm() {
        const { institutionCaregivers, user } = this.props;
        const caregiverIds = institutionCaregivers.map((v) => v.id).filter((v) => v !== user.id);

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <DialogTitle>{getTranslation("player_create_profil")}</DialogTitle>
                        <DialogContent>
                            <AddForm
                                user={this.props.user}
                                initialValues={{ gender: 2, lang: 0, caregivers: caregiverIds }}
                                buttonText={this.hasPaymentForm() ? getTranslation("next") : getTranslation("create")}
                                termsOfService={
                                    <div>
                                        <Typography>
                                            {getTranslation("player_create_enrich_message")}
                                        </Typography>
                                        <br />
                                        {!this.hasPaymentForm() ? this.renderTermsOfService() : null}
                                    </div>
                                }
                                withPhoto
                                onSubmit={this.handleSubmit}
                            />
                        </DialogContent>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    renderPaymentForm() {
        const { user } = this.props;
        return (
            <div>
                <Grid container>
                    <Grid item xs={4}>
                        <PlayerPaymentRFormSidebar form="addPlayer" />
                    </Grid>
                    <Grid item xs={8}>
                        <div>
                            <DialogContent>
                                <Typography component="div">
                                    ${MainConfig.pricePerPlayer}/month which gives you: <br />
                                    <ul>
                                        <li>Unlimited share of your player with your care circle</li>
                                        <li>Opportunity to customize your player's content</li>
                                        <li>History of sessions, including duration times and session reports</li>
                                        <li>Access to new places in Mentia Companion as they come online</li>
                                    </ul>
                                </Typography>
                            </DialogContent>
                            <DialogContent>
                                <PaymentForm
                                    email={user.email}
                                    termsOfService={this.hasPaymentForm() ? this.renderTermsOfService() : null}
                                    buttonText="Create"
                                    onSubmit={this.handleSubmit}
                                />
                            </DialogContent>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        const { page } = this.state;
        const { classes, open } = this.props;
        return (
            <Dialog open={open} onClose={this.handleRequestClose} maxWidth={page ? 'md' : 'sm'}>
                {page === 0 && this.renderAddForm()}
                {page === 1 && this.renderPaymentForm()}
            </Dialog>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state, ownProps) => {
    const institutionCaregivers = usersSelector.getInstitutionUsers(state);
    return {
        institutionCaregivers: institutionCaregivers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInstitutionCaregivers: (institutionId) => dispatch(fetchInstitutionUsers(institutionId)),
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateTopProps, mapDispatchToProps),
    branch((props) => hasPaymentForm(props.user), injectStripe),
    reduxForm({
        form: 'addPlayer',
        onSubmit,
    })
)(PlayerAddModal);
