import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import videoChannelSchema from 'reducers/schemas/videoChannel';

export const videoChannelsSelector = {
    getAllState: (state) => state.videoChannels.all,
    getAll: (state) => NormalizedSelector.getByIds(state.videoChannels.videoChannels, state.videoChannels.all),
    getCurrentPlayerVideoChannelsState: (state) => state.videoChannels.currentPlayerChannels,
    getCurrentPlayerVideoChannels: (state) =>
        NormalizedSelector.getByIds(state.videoChannels.videoChannels, state.videoChannels.currentPlayerChannels),
};

const VIDEO_CHANNELS = 'VIDEO_CHANNELS';
const VIDEO_CHANNELS_SUCCESS = 'VIDEO_CHANNELS_SUCCESS';
const VIDEO_CHANNELS_ERROR = 'VIDEO_CHANNELS_ERROR';

const CURRENT_PLAYER_VIDEO_CHANNELS = 'CURRENT_PLAYER_VIDEO_CHANNELS';
const CURRENT_PLAYER_VIDEO_CHANNELS_SUCCESS = 'CURRENT_PLAYER_VIDEO_CHANNELS_SUCCESS';
const CURRENT_PLAYER_VIDEO_CHANNELS_ERROR = 'CURRENT_PLAYER_VIDEO_CHANNELS_ERROR';

export const fetchVideoChannels = () => (dispatch) => {
    dispatch({ type: VIDEO_CHANNELS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/video_channels`),
        [videoChannelSchema],
        [VIDEO_CHANNELS_SUCCESS, VIDEO_CHANNELS_ERROR],
        dispatch
    );
};

export const fetchPlayerVideoChannels = (playerId) => (dispatch) => {
    dispatch({ type: CURRENT_PLAYER_VIDEO_CHANNELS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/players/${playerId}/video_channels`),
        [videoChannelSchema],
        [CURRENT_PLAYER_VIDEO_CHANNELS_SUCCESS, CURRENT_PLAYER_VIDEO_CHANNELS_ERROR],
        dispatch
    );
};

export default combineReducers({
    videoChannels: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('videoChannels'),
        defaultStrategy: Strategies.merge,
    }),
    all: createRequestReducer([VIDEO_CHANNELS, VIDEO_CHANNELS_SUCCESS, VIDEO_CHANNELS_ERROR], {
        mapResponse: NormalizedActionMap.result,
    }),
    currentPlayerChannels: createRequestReducer(
        [CURRENT_PLAYER_VIDEO_CHANNELS, CURRENT_PLAYER_VIDEO_CHANNELS_SUCCESS, CURRENT_PLAYER_VIDEO_CHANNELS_ERROR],
        {
            mapResponse: NormalizedActionMap.result,
        }
    ),
});
