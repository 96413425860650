import * as React from 'react';
import { map } from 'lodash-es';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import AssetCard from 'containers/assets/AssetCard';

const SortableItem = SortableElement(({ asset, onDelete, onEdit }) => {
    switch (asset.type) {
        case "painting":
            return (
                <div style={{ padding: 20, float: 'left' }}>
                    <AssetCard asset={asset} style={{ width: 375, height: 350 }} onDelete={onDelete} onEdit={onEdit} />
                </div>
            );
        case "music_track":
            return (
                <div style={{ paddingRight: 20, paddingTop: 20, paddingBottom: 20, float: 'left' }}>
                    <AssetCard asset={asset} style={{ width: 110, height: 160 }} onDelete={onDelete} onEdit={onEdit} />
                </div>
            );
        default:
            return (
                <div style={{ padding: 20, float: 'left' }}>
                    <AssetCard asset={asset} style={{ width: 250, height: 350 }} onDelete={onDelete} onEdit={onEdit} />
                </div>
            );
    }
});

export default SortableContainer(({ assets, onDelete, onEdit }) => {
    return (
        <div style={{ overflow: 'hidden' }}>
            {map(assets, (asset, index) => {
                return <SortableItem key={asset.id} index={index} asset={asset} onDelete={onDelete} onEdit={onEdit} />;
            })}
        </div>
    );
});
