import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { deletePlayer, playersSelector } from 'reducers/players';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { LocaleConsumer } from 'containers/locale/LocaleProvider';

const styles = {};

class DeletePlayerButton extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleClick = (event) => {
        this.setState({ open: true });
    };

    handleRequestClose = (event) => {
        if (this.props.isDeleting) return;
        this.setState({ open: false });
    };

    handleAccept = (event) => {
        const { playerId } = this.props;
        this.props.delete(playerId);
    };

    renderDialog() {
        const { isDeleting } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Dialog open={this.state.open} onClose={this.handleRequestClose}>
                        <DialogTitle>{getTranslation("cancel_subscription_title")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography component="span">
                                    {getTranslation("cancel_subscription_desc")}
                                    <br />
                                    <br />
                                    {getTranslation("cancel_subscription_see")}
                                    <a className="link" href="https://www.mentia.me/deva-world-support/">
                                        FAQs
                                    </a>
                                    {getTranslation("cancel_subscription_more_info")}
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleRequestClose} color="primary" disabled={isDeleting}>
                                {getTranslation("cancel_subscription_no")}
                            </Button>
                            <Button onClick={this.handleAccept} color="primary" disabled={isDeleting}>
                                {getTranslation("cancel_subscription_yes")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <span>
                        {this.renderDialog()}
                        <Button onClick={this.handleClick}>{getTranslation("delete_player_button_cancel_subscription")}</Button>
                    </span>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const deleteState = playersSelector.getPlayerDeleteState(state);
    return {
        isDeleting: RequestStateHelper.isLoading(deleteState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        delete: (playerId) => dispatch(deletePlayer(playerId)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DeletePlayerButton));
