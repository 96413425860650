import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Field } from 'redux-form';
import { Button } from '@material-ui/core';
import FileField from 'ui/base/FileField';
import { Col, Row } from 'react-flexbox-grid';
import ObjectTypeTitle from 'containers/assets/ObjectTypeTitle';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class AssetRForm extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { handleSubmit, submitting, object } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Row top="xs" style={{ margin: 0 }}>
                            <Col>
                                <Field
                                    type="file"
                                    name="file"
                                    label={getTranslation("asset_form_select_file")}
                                    component={FileField}
                                    disabled={submitting}
                                />
                            </Col>
                            <Col xs>
                                <div style={{ paddingLeft: '10px' }}>
                                    <Field
                                        variant="outlined"
                                        autoComplete="off"
                                        type="text"
                                        helperText={getTranslation("asset_form_description_helper")}
                                        name="description"
                                        label={getTranslation("description")}
                                        component={ValidatedTextField}
                                        disabled={submitting}
                                        fullWidth
                                        inputProps={{ maxLength: 60 }}
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }} />
                                    <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={submitting}>
                                        {getTranslation("asset_form_button_add")} <ObjectTypeTitle type={object.type} />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(AssetRForm);
