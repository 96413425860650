import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, CardContent, Typography } from '@material-ui/core';
import { usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import InstitutionUsersList from 'containers/users/InstitutionUsersList';
import InstitutionUserAddModal from 'containers/users/InstitutionUserAddModal';
import AddIcon from '@material-ui/icons/Add';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class ManageInstitutionCaregiversPage extends React.Component {
    /**
     * Component state
     */
    state = {
        addCaregiverOpen: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleAddCaregiverClick = (event) => {
        this.setState({ addCaregiverOpen: true });
    };

    handleAddCaregiverModalRequestClose = (event) => {
        this.setState({ addCaregiverOpen: false });
    };

    renderActions() {
        return (
            <div>
                <Button variant="contained" color="secondary" onClick={this.handleAddCaregiverClick}>
                    <AddIcon style={{ paddingRight: 5 }} />
                    <LocaleConsumer>
                        {({ getTranslation }) => (
                            getTranslation("manage_institution_care_givers_new")
                        )}
                    </LocaleConsumer>
                </Button>
            </div>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, user, institutionPlayers } = this.props;
        const { addCaregiverOpen } = this.state;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <CardContent style={{ display: 'flex' }}>
                            <Typography variant="h5" gutterBottom>
                                {getTranslation("manage_institution_care_givers_title")}
                            </Typography>
                            <div style={{ flex: 1 }} />
                            {this.renderActions()}
                        </CardContent>
                        <InstitutionUsersList noDataText={getTranslation("manage_institution_care_givers_empty")} institutionId={user.id} />
                        <InstitutionUserAddModal
                            userId={user.id}
                            open={addCaregiverOpen}
                            institutionPlayers={institutionPlayers}
                            onRequestClose={this.handleAddCaregiverModalRequestClose}
                        />
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default withStyles(styles)(connect(mapStateTopProps)(ManageInstitutionCaregiversPage));
