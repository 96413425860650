import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchPlayerUsers, usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { playersSelector } from 'reducers/players';
import ExitCareCircleButton from '../players/ExitCareCircleButton';
import { LocaleConsumer } from 'containers/locale/LocaleProvider';

const styles = {
    userItem: {
        marginBottom: '6px',
    },
};

class PlayerUsersList extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchPlayerUsers(this.props.playerId);
    }

    renderList() {
        const { classes, users, player, currentUser } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <ul>
                            {users.map((user) => (
                                <li key={user.id} className={classes.userItem}>
                                    <Typography style={{ fontWeight: user.id === player.main_user_id ? 600 : 400 }}>
                                        {user.display_name.length ? user.display_name : user.email}
                                        {user.id === player.main_user_id ? ` (${getTranslation("player_users_list_subscriber")})` : null}
                                    </Typography>
                                    {user.display_name.length > 0 && <Typography variant="caption">{user.email}</Typography>}

                                    {player.main_user_id === currentUser.id && player.main_user_id !== user.id && (
                                        <ExitCareCircleButton user={user} player={player} />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>
                {isLoading && <FullWidthLoader />}
                {!isLoading && this.renderList()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        playerId: PropTypes.number.isRequired,
    };
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const usersState = usersSelector.getCurrentPlayerUsersState(state);
    return {
        users: usersSelector.getCurrentPlayerUsers(state),
        player: playersSelector.getPlayer(state, ownProps.playerId),
        currentUser: usersSelector.getCurrentUser(state),
        isLoading: RequestStateHelper.isLoading(usersState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlayerUsers: (playerId) => {
            dispatch(fetchPlayerUsers(playerId));
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PlayerUsersList));
