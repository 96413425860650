import React from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {};

class HintsPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            <Link to={`/`}>
                                <Button>
                                    <KeyboardArrowLeft /> {getTranslation("home")}
                                </Button>
                            </Link>
                        </div>
                        <Card>
                            <CardContent>
                                <div style={{ padding: 15 }}>
                                    <Typography>
                                        <a href="#1" className="link">
                                            1. {getTranslation("tips_1")}
                                        </a>
                                        <br />
                                        <a href="#2" className="link">
                                            2. {getTranslation("tips_2")}
                                        </a>
                                        <br />
                                        <a href="#3" className="link">
                                            3. {getTranslation("tips_3")}
                                        </a>
                                        <br />
                                        <a href="#4" className="link">
                                            4. {getTranslation("tips_4")}
                                        </a>
                                        <br />
                                        <a href="#5" className="link">
                                            5. {getTranslation("tips_5")}
                                        </a>
                                        <br />
                                        <a href="#6" className="link">
                                            6. {getTranslation("tips_6")}
                                        </a>
                                        <br />
                                        <a href="#7" className="link">
                                            7. {getTranslation("tips_7")}
                                        </a>
                                        <br />
                                        <a href="#8" className="link">
                                            8. {getTranslation("tips_8")}
                                        </a>
                                        <br />
                                    </Typography>
                                    <Typography id="1" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        1. {getTranslation("tips_1")}
                                    </Typography>
                                    <Typography>
                                        {getTranslation("tips_1_content")}
                                    </Typography>
                                    <Typography id="2" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        2. {getTranslation("tips_2")}
                                    </Typography>
                                    <Typography>
                                        {getTranslation("tips_2_content")}
                                    </Typography>
                                    <Typography id="3" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        3. {getTranslation("tips_3")}
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>
                                                {getTranslation("tips_3_content_1")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_2")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_3")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_4")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_5")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_6")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_7")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_3_content_8")}
                                            </li>
                                        </ul>
                                    </Typography>
                                    <Typography id="4" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        4. {getTranslation("tips_4")}
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>
                                                {getTranslation("tips_4_content_1_on_the")}
                                                <a href="/" className="link">
                                                    {getTranslation("tips_4_content_1_dashboard")}
                                                </a>
                                                {getTranslation("tips_4_content_1_you_can")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_4_content_2")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_4_content_3")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_4_content_4")}
                                            </li>
                                        </ul>
                                    </Typography>
                                    <Typography id="5" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        5. {getTranslation("tips_5")}
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>
                                                {getTranslation("tips_5_content_1")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_2")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_3")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_4")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_5")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_6")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_7")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_8")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_9")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_5_content_10")}
                                            </li>
                                        </ul>
                                    </Typography>
                                    <Typography id="6" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        6. {getTranslation("tips_6")}
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>
                                                {getTranslation("tips_6_content_1")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_2")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_3")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_4")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_5")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_6")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_7")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_8")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_9")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_10")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_6_content_11")}
                                            </li>
                                        </ul>
                                    </Typography>
                                    <Typography id="7" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        7. {getTranslation("tips_7")}
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>
                                                {getTranslation("tips_7_content_1")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_7_content_2")}

                                            </li>
                                            <li>
                                                {getTranslation("tips_7_content_3")}

                                            </li>
                                        </ul>
                                    </Typography>
                                    <Typography id="8" variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        8. {getTranslation("tips_8")}
                                    </Typography>
                                    <Typography>
                                        {getTranslation("tips_8_content_first")}
                                        <ul>
                                            <li>
                                                {getTranslation("tips_8_content_first_1")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_8_content_first_2")}
                                            </li>
                                        </ul>
                                        {getTranslation("tips_8_content_second")}
                                        <ul>
                                            <li>
                                                {getTranslation("tips_8_content_second_1")}
                                            </li>
                                            <li>
                                                {getTranslation("tips_8_content_second_2")}
                                            </li>
                                        </ul>
                                        {getTranslation("tips_8_content_third")}
                                        <ul>
                                            <li>
                                                {getTranslation("tips_8_content_third_1")}
                                            </li>
                                        </ul>
                                    </Typography>
                                    <Typography variant="h6" color="primary" style={{ margin: '25px 0' }}>
                                        {getTranslation("tips_need_more")}
                                    </Typography>
                                    <Typography color="secondary">
                                        <a href="https://www.mentia.me/contact/" className="link">
                                            {getTranslation("tips_drop_us_a_note")}
                                        </a>
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default HintsPage;
