import React from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';

const styles = {};

class HintsPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                <div style={{ marginBottom: 20 }}>
                    <Link to={`/`}>
                        <Button>
                            <KeyboardArrowLeft /> Home
                        </Button>
                    </Link>
                </div>
                <Card>
                    <CardContent>
                        <div className="embed-container">
                            <iframe
                                style={{ width: '100%' }}
                                src="https://player.vimeo.com/video/241462316?title=0&byline=0&portrait=0"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default HintsPage;
