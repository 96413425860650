import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import AddTokenButton from 'ui/payment/AddTokenButton';
import PaymentInfos from 'ui/payment/PaymentInfos';
import DefaultError from 'ui/base/DefaultError';

const styles = {};

class TokenField extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleTokenChange = (token) => {
        const {
            card_token: { input: inputToken },
            card: { input: inputCard },
        } = this.props;

        inputToken.onChange(token.id);
        inputCard.onChange(token.card);
    };

    renderToken() {
        const {
            icon,
            card: {
                input: { value: card },
            },
        } = this.props;
        return (
            <span>
                <PaymentInfos icon={icon} card={card} />
                <br />
            </span>
        );
    }

    /**
     * Render component
     */
    render() {
        const {
            label,
            email,
            card_token: {
                meta: { error },
            },
            card: {
                input: { value: card },
            },
        } = this.props;

        return (
            <div>
                <DefaultError>{error}</DefaultError>
                {card && this.renderToken()}
                <AddTokenButton label={label} email={email} token={this.handleTokenChange} />
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(TokenField);
