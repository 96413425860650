import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import {
    fetchUserPlayerPendingTransferts,
    playerTransfertsSelector,
    updatePlayerPendingTransfert,
} from 'reducers/playerTransferts';
import { updateUser, usersSelector } from 'reducers/users';
import { Col, Row } from 'react-flexbox-grid';
import PageHeader from 'ui/base/PageHeader';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import DefaultError from 'ui/base/DefaultError';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { ContentComponent } from 'containers/ContentComponents';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import PlayerPaymentRForm from 'containers/players/PlayerPaymentRForm';
import { reduxForm, SubmissionError } from 'redux-form';
import PlayerCardTransfertPending from 'containers/players/PlayerCardTransfertPending';
import { Elements, injectStripe } from 'react-stripe-elements';
import { compose } from 'recompose';
import { RequestError } from 'utils/axiosInstance';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

const PaymentForm = compose(
    injectStripe,
    reduxForm({
        form: 'transfertPaymentForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        onSubmit: (values, dispatch, props) => {
            return props.stripe
                .createToken()
                .then(({ token, error }) => {
                    if (error) {
                        throw new SubmissionError({ _error: error.message });
                    }
                    return dispatch(updateUser({ id: props.user.id, card_token: token.id })).then(() => {
                        props.onSuccess && props.onSuccess();
                    });
                })
                .catch((error) => {
                    if (error instanceof RequestError) {
                        const errors = validationErrorToSubmissionError(error);
                        if (errors.card_token) errors._error = errors.card_token;
                        throw new SubmissionError(errors);
                    }
                    return Promise.reject(error);
                });
        },
    })
)(PlayerPaymentRForm);

class HomeCurrentUserTransfertsList extends React.Component {
    /**
     * Component state
     */
    state = {
        open: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            currentUser: { id },
        } = this.props;
        this.props.fetchTransferts(id);
    }

    handleAcceptClick = (transfertId) => {
        const {
            currentUser: { has_card, subscription_is_trialing },
        } = this.props;
        this.props.updatePlayerPendingTransfert(transfertId, { accepted: true });
        if (!has_card && !subscription_is_trialing) this.setState({ open: true });
    };

    handleRejectClick = (transfertId) => {
        this.props.updatePlayerPendingTransfert(transfertId, { accepted: false });
    };

    handleRequestClosePayment = () => {
        this.setState({ open: false });
    };

    renderPaymentForm() {
        const { currentUser } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <DialogContent>
                        <Elements>
                            <PaymentForm
                                email={currentUser.email}
                                user={currentUser}
                                displayTermsOfService
                                buttonText={getTranslation("save")}
                                onSuccess={this.handleRequestClosePayment}
                            />
                        </Elements>
                    </DialogContent>
                )}
            </LocaleConsumer>
        );
    }

    renderTransfertsList() {
        const { pendingTransferts, isUpdating } = this.props;

        return (
            <Row>
                {pendingTransferts.map((transfert) => {
                    return (
                        <Col xs={12} md={6} key={transfert.id}>
                            <PlayerCardTransfertPending
                                player={transfert.player}
                                hideActions={isUpdating}
                                onAcceptClick={() => this.handleAcceptClick(transfert.id)}
                                onRejectClick={() => this.handleRejectClick(transfert.id)}
                            />
                        </Col>
                    );
                })}
            </Row>
        );
    }

    /**
     * Render component
     */
    render() {
        const { pendingTransferts, isUpdating, transfertError } = this.props;
        const { open } = this.state;
        if (!pendingTransferts.length) return null;

        return (
            <div>
                <PageHeader
                    title="Transferts"
                    subtitle={<ContentComponent componentName="TransfertsList.TransfertsListText" />}
                />
                <DefaultError>{transfertError}</DefaultError>
                {isUpdating && <FullWidthLoader />}
                <br />
                <div>{this.renderTransfertsList()}</div>
                <Dialog open={open} onClose={this.handleRequestClosePayment}>
                    {this.renderPaymentForm()}
                </Dialog>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const transfertState = playerTransfertsSelector.getPlayerTransfertState(state);
    return {
        currentUser: usersSelector.getCurrentUser(state),
        pendingTransferts: playerTransfertsSelector.getCurrentUserPendingTransferts(state),
        transfertError: RequestStateHelper.getErrorMessage(transfertState),
        isUpdating: RequestStateHelper.isLoading(transfertState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTransferts: (userId) => dispatch(fetchUserPlayerPendingTransferts(userId)),
        updatePlayerPendingTransfert: (transfertId, data) => dispatch(updatePlayerPendingTransfert(transfertId, data)),
    };
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(HomeCurrentUserTransfertsList);
