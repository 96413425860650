import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';

const styles = {};

class PageHeader extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { title, subtitle } = this.props;

        return (
            <div>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="subtitle1" color="textSecondary">
                        {subtitle}
                    </Typography>
                )}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PageHeader);
