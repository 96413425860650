import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { mapValues } from 'lodash-es';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {};

class ResizePhotoModal extends React.Component {
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            crop: props.initialCrop || {
                x: 0,
                y: 0,
            },
            cropPixel: {},
        };
    }

    handleRequestClose = (event) => {
        this.props.onRequestClose && this.props.onRequestClose(event);
    };

    handleResizeChange = (crop, cropPixel) => {
        this.setState({ crop, cropPixel });
    };

    handleSave = (event) => {
        let crop = mapValues(this.state.crop, (value) => Math.round(value));
        crop.pixel = this.state.cropPixel;
        if (crop.height === 0 || crop.width === 0) crop = null;

        this.props.onSave && this.props.onSave(crop);
        this.handleRequestClose();
    };

    renderCrop() {
        const { src } = this.props;
        return (
            <div>
                <ReactCrop src={src} crop={this.state.crop} onChange={this.handleResizeChange} />
            </div>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, open, ...other } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Dialog
                        open={open}
                        onClose={this.handleRequestClose}
                        classes={{
                            paper: classes.dialog,
                        }}>
                        <DialogContent>
                            <Typography>Drag to crop the image</Typography>
                            <br />
                            {this.renderCrop()}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleRequestClose}>{getTranslation("cancel")}</Button>
                            <Button onClick={this.handleSave}>{getTranslation("save")}</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(ResizePhotoModal);
