import React from 'react';
import Link from 'ui/base/Link';

class TermsOfUseLink extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        return (
            <Link styled to="https://www.mentia.me/terms-of-use/" target="_blank">
                Terms Of Use
            </Link>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default TermsOfUseLink;
