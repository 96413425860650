import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SessionSad from 'images/session_sad.svg';
import SessionNeutral from 'images/session_neutral.svg';
import SessionHappy from 'images/session_happy.svg';

const styles = {};

class SessionIcon extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderIcon() {
        let icon = null;

        switch (this.props.name) {
            case 'r1':
                icon = SessionHappy;
                break;
            case 'r2':
                icon = SessionNeutral;
                break;
            case 'r3':
                icon = SessionSad;
                break;
        }

        if (icon) return <img src={icon} style={{ width: 20, verticalAlign: 'middle' }} />;
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return <span>{this.renderIcon()}</span>;
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(SessionIcon);
