import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Card, CardHeader } from '@material-ui/core';
import PlayerAvatar from 'containers/players/PlayerAvatar';
import DefaultError from 'ui/base/DefaultError';
import MainConfig from 'constants/MainConfig';

const styles = {
    buttons: {
        '& > *': {
            marginRight: 15,
        },
    },
};

class PlayerCardTransfertPending extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleAcceptClick = (event) => {
        this.props.onAcceptClick && this.props.onAcceptClick(event);
    };

    handleRejectClick = (event) => {
        this.props.onRejectClick && this.props.onRejectClick(event);
    };

    preventClick = (event) => event.preventDefault();

    /**
     * Render component
     */
    render() {
        const {
            classes,
            player,
            player: { display_name },
            error,
            hideActions,
            disableActions,
        } = this.props;

        return (
            <Card style={{ display: 'flex', alignItems: 'center' }} onClick={this.preventClick}>
                <div>
                    <CardHeader
                        title={display_name}
                        subheader={`I accept to become the subscriber and to pay $${MainConfig.pricePerPlayer} a month for ${display_name}`}
                        avatar={<PlayerAvatar player={player} />}
                    />
                    {error && (
                        <DefaultError>
                            {error}
                            <br />
                        </DefaultError>
                    )}
                </div>
                <div style={{ flex: 1 }} />
                {!hideActions && (
                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            color={'secondary'}
                            onClick={this.handleAcceptClick}
                            style={{ marginBottom: 5 }}
                            disabled={disableActions}>
                            Yes
                        </Button>
                        <Button
                            style={{ marginBottom: 5 }}
                            variant="contained"
                            onClick={this.handleRejectClick}
                            disabled={disableActions}>
                            No
                        </Button>
                    </div>
                )}
            </Card>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(PlayerCardTransfertPending);
