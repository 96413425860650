import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DefaultError from 'ui/base/DefaultError';
import { deleteUser, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import AccountCancelMessage from 'containers/users/AccountCancelMessage';

const styles = {
    dialog: {
        width: '80%',
    },
};

class AccountCancelModal extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleRequestClose = () => {
        this.props.onRequestClose();
    };

    handleCancelClick = () => {
        const {
            user: { id },
        } = this.props;
        this.props.cancel(id);
    };

    render() {
        const { classes, open, error, isLoading, ...other } = this.props;
        return (
            <Dialog
                onClose={this.handleRequestClose}
                open={open}
                classes={{
                    paper: classes.dialog,
                }}>
                <DialogTitle>Cancel account</DialogTitle>
                <DialogContent>
                    <AccountCancelMessage />
                    {error && <DefaultError>{error}</DefaultError>}
                    <br />
                    <br />
                    <div style={{ display: 'flex' }}>
                        <Button style={{ marginLeft: 5 }} disabled={isLoading} onClick={this.handleCancelClick}>
                            I'm sure, cancel my account
                        </Button>
                        <div style={{ flex: 1 }} />
                        <Button color="primary" onClick={this.handleRequestClose} disabled={isLoading}>
                            Keep my account
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    const deleteState = usersSelector.getUserDeleteSate(state);
    return {
        user: usersSelector.getCurrentUser(state),
        isLoading: RequestStateHelper.isLoading(deleteState),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        cancel: (userId) => {
            return dispatch(deleteUser(userId)).then((response) => {
                ownProps.onRequestClose();
                return response;
            });
        },
    };
};

export default withStyles(styles)(connect(mapStateTopProps, mapDispatchToProps)(AccountCancelModal));
