import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { usersSelector } from 'reducers/users';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {};

class WelcomeMessage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, user } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Typography variant="subtitle1" color="inherit">
                        {getTranslation("welcome")}, {user.display_name}
                    </Typography>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default connect(mapStateToProps)(withStyles(styles)(WelcomeMessage));
