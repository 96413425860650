import React from 'react';
import { connect } from 'react-redux';
import { initializeUser, usersSelector } from 'reducers/users';
import { withRouter } from 'react-router-dom';
import AuthTokenStorage from 'utils/AuthTokenStorage';

class AuthContainer extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.initialize();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentUserInitialized && !this.props.currentUserInitialized) {
            this.props.initialize();
        }
    }

    /**
     * Render component
     */
    render() {
        if (!this.props.currentUserInitialized) return <div>Connecting...</div>;

        return this.props.children;
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUserInitialized: usersSelector.isCurrentUserInitialized(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initialize: () => {
            dispatch(initializeUser(AuthTokenStorage.getUserId()));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));
