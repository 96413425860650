import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import DefaultError from 'ui/base/DefaultError';
import { displayAlert } from 'reducers/alerts';
import { addInstitutionCaregiver } from 'reducers/users';
import MultiSelectField from '../../ui/base/MultiSelectField';
import { fetchInstitutionPlayers, playersSelector } from '../../reducers/players';
import { connect } from 'react-redux';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    dialog: {
        width: '80%',
    },
};

class InstitutionUserAddModal extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchInstitutionPlayers(this.props.userId);
    }

    handleRequestClose = () => {
        this.props.reset();
        this.props.onRequestClose();
    };

    render() {
        const { classes, open, handleSubmit, submitting, error, institutionPlayers, ...other } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Dialog
                        onClose={this.handleRequestClose}
                        open={open}
                        classes={{
                            paper: classes.dialog,
                        }}>
                        <DialogTitle>{getTranslation("manage_institution_care_givers_add_title")}</DialogTitle>
                        <DialogContent>
                            {error && <DefaultError>{error}</DefaultError>}
                            <br />
                            <Field
                                type="email"
                                name="email"
                                label={getTranslation("email")}
                                component={ValidatedTextField}
                                autoFocus
                                required
                                fullWidth
                            />
                            <br />
                            <br />
                            <Field
                                name="sharedPlayers"
                                label={getTranslation("players")}
                                component={MultiSelectField}
                                options={institutionPlayers.map((player) => ({
                                    value: player.id,
                                    label: player.display_name,
                                }))}
                            />
                            <br />
                            <br />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} />
                                <Button onClick={this.handleRequestClose} disabled={submitting}>
                                    {getTranslation("cancel")}
                                </Button>
                                <Button color="primary" onClick={handleSubmit} disabled={submitting} style={{ marginLeft: 5 }}>
                                    {getTranslation("send")}
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    const institutionPlayers = playersSelector.getInstitutionPlayers(state);
    return {
        initialValues: {
            sharedPlayers: institutionPlayers.map((p) => p.id),
        },
        institutionPlayers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInstitutionPlayers: (institutionId) => dispatch(fetchInstitutionPlayers(institutionId)),
    };
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        reduxForm({
            form: 'addInstitutionUser',
            enableReinitialize: true,
            onSubmit(values, dispatch, props) {
                return dispatch(addInstitutionCaregiver(props.userId, values.email, values.sharedPlayers))
                    .then((result) => {
                        dispatch(displayAlert(`Caregiver added`));
                        props.onRequestClose();
                        props.reset();
                        return result;
                    })
                    .catch((error) => {
                        throw new SubmissionError(validationErrorToSubmissionError(error));
                    });
            },
        })(InstitutionUserAddModal)
    )
);
