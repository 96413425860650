import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { addInstitutionCaregiver, deleteUser, removeInstitutionCaregiver, usersSelector } from 'reducers/users';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import connect from 'react-redux/es/connect/connect';

class InstitutionUserDeleteButton extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleDeleteClick = (event) => {
        const { institutionId, userId } = this.props;
        this.props.delete(institutionId, userId);
    };

    /**
     * Render component
     */
    render() {
        const { children, isLoading, component: Component } = this.props;
        return (
            <Component onClick={this.handleDeleteClick} disabled={isLoading}>
                {children}
            </Component>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        component: Button,
    };
}
const mapStateToProps = (state, ownProps) => {
    const removeState = usersSelector.getInsitutionUserRemoveSate(state);
    return {
        isLoading: RequestStateHelper.isLoading(removeState),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        delete: (institutionId, userId) => {
            return dispatch(removeInstitutionCaregiver(institutionId, userId)).then((response) => {
                return response;
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionUserDeleteButton);
