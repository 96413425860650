import React from 'react';
import SessionIcon from 'containers/sessions/SessionIcon';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { withStyles } from '@material-ui/core';

const styles = {
    base: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '0 2px',
    },
};

class SessionsRatingMinistat extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { r1Count, r2Count, r3Count, total, classes } = this.props;
        return (
            <div className={classes.base}>
                <div className={classes.column}>
                    <SessionIcon name="r1" />
                    <div>
                        <FormattedNumber maximumFractionDigits={0} value={(r1Count / total) * 100} />%
                    </div>
                </div>
                <div className={classes.column}>
                    <SessionIcon name="r2" />
                    <div>
                        <FormattedNumber maximumFractionDigits={0} value={(r2Count / total) * 100} />%
                    </div>
                </div>
                <div className={classes.column}>
                    <SessionIcon name="r3" />
                    <div>
                        <FormattedNumber maximumFractionDigits={0} value={(r3Count / total) * 100} />%
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        r1Count: PropTypes.number,
        r2count: PropTypes.number,
        r3Count: PropTypes.number,
        total: PropTypes.number,
    };
    static defaultProps = {};
}

export default withStyles(styles)(SessionsRatingMinistat);
