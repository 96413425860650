/* 
Author: Niklaas Cotta
Last Modified: 7/19/2023
Description: View book cover and pages

| type    | column      | description |
|---------|-------------|-------------|
| int     | `id`        |             |
| int     | `book_id`   |             |
| string  | `file`      |             |
| string  | `file_type` |             |
| string  | `file_dir`  |             |
| boolean | `cover`     |             |
| string  | `file_url`  |             |
*/

import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { LocaleConsumer } from 'containers/locale/LocaleProvider';

import {RequestStateHelper } from 'reducers/createRequestReducer';
import { fetchBookPages, bookPagesSelector } from 'reducers/bookPages';

import fallbackImage from 'images/no_preview.png';

import FullWidthLoader from 'ui/base/FullWidthLoader';
import withStyles from '@material-ui/core/styles/withStyles';
import { 
    List, ListItem, ListItemIcon,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';



// CSS styles in JSX format
const styles = {
};

const dialogContentStyles = {
    display: 'table'
}

const listStyles = {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
};

const listItemStyles = {
    // '&>:first-child': 
    // '&>:nth-child(odd)': { 'padding-right': '15px' }
    // gutter: { 'padding-right': '35px'}
};

const listItemIconStyles = {
}

const imageStyles = {
    width: '133px',
    height: 'auto',
    border: '2px solid black',
};



// Main function logic
function BookPagesDialog({
    classes,         // AssetsEditor.props
    isFetching,      // isFetching: RequestStateHelper.isLoading(assetsState)? 
    open,            // open: !!this.state.currentEditingAsset
    onClose,         // onClose: this.handleAssetEdited
    bookId,          // const bookIds = orderedAssets.map((v) => v?.book?.id).filter((v) => !!v);
    fetchBookPages,  // from reducers/BookPages
    bookPages,       // bookPage objects
    title = 'My Book',
}) {
    // Check current bookId and grab its pages when bookId changes
    useEffect(() => { 
        bookId && fetchBookPages(bookId); 
    }, [bookId]);


    // React Component
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
                    <DialogTitle>{title}</DialogTitle>
                    {isFetching && <FullWidthLoader />}
                    {!isFetching && (
                        <DialogContent style={dialogContentStyles}>
                            <List style={listStyles}>
                                {bookPages.map((bookPage) => {
                                    if (!bookPage.cover) {
                                        return (
                                            <ListItem key={bookPage.id} styles={listItemStyles}>
                                                <ListItemIcon styles={listItemIconStyles}>
                                                    <img className={classes.image} src={ bookPage.file_url || fallbackImage } alt="" style={imageStyles}/>
                                                </ListItemIcon>
                                            </ListItem>
                                        );
                                    }
                                })}
                            </List>
                        </DialogContent>
                    )}
                </Dialog>
            )}
        </LocaleConsumer>
    );
}



// Reducer logic
BookPagesDialog.propTypes = {};
BookPagesDialog.defaultProps = {};

const mapStateToProps = (state) => {
    const bookPagesState = bookPagesSelector.getCurrentBookPagesState(state);
    
    return {
        isFetching: RequestStateHelper.isLoading(bookPagesState),
        bookPages: bookPagesSelector.getCurrentBookPages(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBookPages: (bookPageId) => dispatch(fetchBookPages(bookPageId))
    };
};



export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(BookPagesDialog);