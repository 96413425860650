import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import blue from '@material-ui/core/colors/blue';
import { Avatar, Grid, Typography } from '@material-ui/core';

const styles = {
    base: {
        backgroundColor: blue[500],
        height: '100%',
        padding: 20,
        boxSizing: 'border-box',
        color: '#ffffff',
    },
};

class PlayerPaymentRFormSidebar extends React.Component {
    /**
     * Component state
     */
    state = {
        image: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { photo } = this.props.values;
        if (photo) {
            this.setState({
                image: URL.createObjectURL(photo),
            });
        }
    }

    componentWillUnmount() {
        if (this.state.image) {
            URL.revokeObjectURL(this.state.image);
            this.setState({ image: null });
        }
    }

    renderImage() {
        return this.state.image ? <Avatar src={this.state.image} style={{ width: 100, height: 100 }} alt="" /> : null;
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;
        const { nickname } = this.props.values;

        return (
            <div className={classes.base}>
                <Grid container direction="column" alignItems="center">
                    <Typography type="title" color="inherit" gutterBottom style={{ margin: '20px 0' }}>
                        Welcome to Mentia Companion
                    </Typography>
                    <Grid item>{this.renderImage()}</Grid>
                    <Typography color="inherit">{nickname}</Typography>
                </Grid>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {
        form: PropTypes.string.isRequired,
    };
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    return {
        values: getFormValues(ownProps.form)(state),
    };
};

export default withStyles(styles)(connect(mapStateToProps)(PlayerPaymentRFormSidebar));
