import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Typography } from '@material-ui/core';
import DefaultError from 'ui/base/DefaultError';
import { CardElement } from 'react-stripe-elements';
import { Lock } from '@material-ui/icons';
import CreditCardPicture1 from 'images/credit_card/1.png';
import CreditCardPicture2 from 'images/credit_card/2.png';
import CreditCardPicture3 from 'images/credit_card/10.png';
import CreditCardPicture4 from 'images/credit_card/14.png';
import CreditCardPicture5 from 'images/credit_card/16.png';
import CreditCardPicture6 from 'images/credit_card/22.png';

const styles = {
    form: {
        backgroundColor: '#ebebeb',
        borderRadius: 5,
        padding: '10px 10px 20px 10px',
        marginBottom: 20,
    },
    cardIcons: {
        '& > *': {
            marginRight: 5,
        },
    },
    title: {
        marginTop: 20,
    },
};

class PlayerPaymentRForm extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes, submitting, buttonText, handleSubmit, termsOfService, email, message, error } = this.props;
        return (
            <div>
                <div>
                    <DefaultError>{error}</DefaultError>
                    <br />
                    <div className={classes.form}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                            <div className={classes.cardIcons}>
                                <img src={CreditCardPicture1} alt="" height={25} />
                                <img src={CreditCardPicture2} alt="" height={25} />
                                <img src={CreditCardPicture3} alt="" height={25} />
                                <img src={CreditCardPicture4} alt="" height={25} />
                                <img src={CreditCardPicture5} alt="" height={25} />
                                <img src={CreditCardPicture6} alt="" height={25} />
                            </div>
                            <div style={{ flex: 1 }} />
                            <Typography style={{ color: '#1fc81f' }}>Secure Payment</Typography>
                            <Lock style={{ color: '#1fc81f', verticalAlign: 'middle' }} />
                        </div>
                        <CardElement style={{ base: { fontSize: '14px' } }} />
                    </div>
                    {message && (
                        <div>
                            {message}
                            <br />
                        </div>
                    )}
                    {termsOfService && <div>{termsOfService}</div>}
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} />
                        <Button color="secondary" onClick={handleSubmit} disabled={submitting}>
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
    };
}

export default withStyles(styles)(PlayerPaymentRForm);
