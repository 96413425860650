/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import NotFoundPage from 'containers/pages/NotFoundPage';
import MainLayout from 'containers/pages/layouts/MainLayout';
import LoginPage from 'containers/pages/auth/LoginPage';
import AuthRoute from 'containers/auth/AuthRoute';
import JoinPage from 'containers/pages/auth/JoinPage';
import AskPasswordPage from 'containers/pages/auth/AskPasswordPage';
import ResetPasswordPage from 'containers/pages/auth/ResetPasswordPage';
import JoinSuccessPage from 'containers/pages/auth/JoinSuccessPage';
import IntroPage from 'containers/pages/IntroPage';
import Alerts from 'containers/Alerts';
import ConfirmNewEmailPage from 'containers/pages/auth/ConfirmNewEmailPage';
import ThankYouPasswordPage from 'containers/pages/ThankYouPasswordPage';
import ThankYouResetPage from 'containers/pages/ThankYouResetPage';

class App extends React.Component {
    render() {
        return (
            <div style={{ height: '100%' }}>
                <Alerts />
                <Switch>
                    <Route path="/confirm-new-email/:id" component={ConfirmNewEmailPage} />
                    <AuthRoute requireUnauthenticated exact path="/login" component={LoginPage} />
                    <AuthRoute requireUnauthenticated exact path="/join" component={JoinPage} />
                    <AuthRoute requireUnauthenticated exact path="/join-success" component={JoinSuccessPage} />
                    <AuthRoute requireUnauthenticated exact path="/lost_password" component={AskPasswordPage} />
                    <AuthRoute requireUnauthenticated exact path="/thankyou_password" component={ThankYouPasswordPage} />
                    <AuthRoute requireUnauthenticated exact path="/thankyou_reset" component={ThankYouResetPage} />
                    <AuthRoute
                        requireUnauthenticated
                        exact
                        path="/lost_password/:id/:key"
                        component={ResetPasswordPage}
                    />
                    <AuthRoute exact path="/intro" component={IntroPage} />
                    <AuthRoute path="/" component={MainLayout} />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.element,
};

export default App;
