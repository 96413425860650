import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { sharePlayer } from 'reducers/players';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import DefaultError from 'ui/base/DefaultError';
import { displayAlert } from 'reducers/alerts';
import { addInstitutionCaregiver } from 'reducers/users';
import { fetchInstitutionUsers, fetchPlayerUsers, usersSelector } from '../../reducers/users';
import ValidatedSelectField from '../../ui/base/ValidatedSelectField';
import { connect } from 'react-redux';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {
    dialog: {
        width: '60%',
    },
};

class PlayerAddCaregiverModal extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchPlayerUsers(this.props.playerId);
        this.props.fetchInstitutionCaregivers(this.props.institutionId);
    }

    handleRequestClose = () => {
        this.props.reset();
        this.props.onRequestClose();
    };

    render() {
        const { classes, open, handleSubmit, submitting, error, institutionCaregivers, playerUsers } = this.props;
        const caregiversToAdd = institutionCaregivers.filter(
            (caregiver) => !playerUsers.find((v) => v.id === caregiver.id)
        );

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Dialog
                        onClose={this.handleRequestClose}
                        open={open}
                        classes={{
                            paper: classes.dialog,
                        }}>
                        <DialogTitle>{getTranslation("player_modal_share_player_with_a_care_staff")}</DialogTitle>
                        <DialogContent>
                            {error && <DefaultError>{error}</DefaultError>}
                            <FormControl fullWidth>
                                <InputLabel htmlFor="email">{getTranslation("player_modal_select_a_caregiver_from_your_care_team")}</InputLabel>
                                <Field name="email" id="email" required component={ValidatedSelectField}>
                                    {caregiversToAdd.map((user) => (
                                        <MenuItem value={user.email}>{user.email}</MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                            <br />
                            <br />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} />
                                <Button onClick={this.handleRequestClose} disabled={submitting}>
                                    {getTranslation("cancel")}
                                </Button>
                                <Button color="primary" onClick={handleSubmit} disabled={submitting} style={{ marginLeft: 5 }}>
                                    {getTranslation("send")}
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        institutionCaregivers: usersSelector.getInstitutionUsers(state),
        playerUsers: usersSelector.getCurrentPlayerUsers(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInstitutionCaregivers: (institutionId) => dispatch(fetchInstitutionUsers(institutionId)),
        fetchPlayerUsers: (playerId) => dispatch(fetchPlayerUsers(playerId)),
    };
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        reduxForm({
            form: 'sharePlayer',
            onSubmit(values, dispatch, props) {
                return dispatch(sharePlayer(values, props.playerId))
                    .then((result) => {
                        dispatch(displayAlert(`The caregiver ${values.email} has been added to player`));
                        props.onRequestClose();
                        props.reset();
                        return result;
                    })
                    .catch((error) => {
                        throw new SubmissionError(validationErrorToSubmissionError(error));
                    });
            },
        })(PlayerAddCaregiverModal)
    )
);
