import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button } from '@material-ui/core';

const styles = {
    form: {
        '& > *': {
            marginBottom: 20,
        },
    },
};

class SessionRForm extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }
    /**
     * Render component
     */
    render() {
        const { classes, buttonText, handleSubmit, submitting } = this.props;

        return (
            <div className={classes.form}>
                <Field
                    type="text"
                    name="comment"
                    label="Comment"
                    component={ValidatedTextField}
                    autoFocus
                    required
                    fullWidth
                    multiline
                />
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" onClick={handleSubmit} disabled={submitting}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
    };
}

SessionRForm = withStyles(styles)(SessionRForm);

SessionRForm.validate = (values) => {
    const errors = {};

    return errors;
};

export default SessionRForm;
