import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    Strategies,
} from 'reducers/createRequestReducer';
import bookSchema from 'reducers/schemas/book';
import { normalize } from 'normalizr';
import { map, keyBy, values, sortBy, concat, filter } from 'lodash-es';

export const bookSelector = {
    getBookState: (state) => state.assets.playerAssets,
    getAll: (state) => NormalizedSelector.getByIds(state.books.books, state.books.all),
};

const PLAYER_BOOK = 'PLAYER_BOOK';
const PLAYER_BOOK_SUCCESS = 'PLAYER_BOOK_SUCCESS';
const PLAYER_BOOK_ERROR = 'PLAYER_BOOK_ERROR';

const CREATE_PLAYER_BOOK = 'CREATE_PLAYER_BOOK';
const CREATE_PLAYER_BOOK_SUCCESS = 'CREATE_PLAYER_BOOK_SUCCESS';
const CREATE_PLAYER_BOOK_ERROR = 'CREATE_PLAYER_BOOK_ERROR';

const UPDATE_PLAYER_OBJECT_ASSET = 'UPDATE_PLAYER_OBJECT_ASSET';
const UPDATE_PLAYER_OBJECT_ASSET_SUCCESS = 'UPDATE_PLAYER_OBJECT_ASSET_SUCCESS';
const UPDATE_PLAYER_OBJECT_ASSET_ERROR = 'UPDATE_PLAYER_OBJECT_ASSET_ERROR';

const DELETE_PLAYER_OBJECT_ASSET = 'DELETE_PLAYER_OBJECT_ASSET';
const DELETE_PLAYER_OBJECT_ASSET_SUCCESS = 'DELETE_PLAYER_OBJECT_ASSET_SUCCESS';
const DELETE_PLAYER_OBJECT_ASSET_ERROR = 'DELETE_PLAYER_OBJECT_ASSET_ERROR';

const SORT_PLAYER_OBJECT_ASSET = 'SORT_PLAYER_OBJECT_ASSET';
const SORT_PLAYER_OBJECT_ASSET_SUCCESS = 'SORT_PLAYER_OBJECT_ASSET_SUCCESS';
const SORT_PLAYER_OBJECT_ASSET_ERROR = 'SORT_PLAYER_OBJECT_ASSET_ERROR';

let currentId = 0;

export const fetchBooks = () => (dispatch) => {
    let operationId = currentId++;
    dispatch({ operationId, type: PLAYER_BOOK });
    return axiosInstance
        .get(`/books`)
        .then(function (response) {
            dispatch({
                operationId,
                type: PLAYER_BOOK_SUCCESS,
                normalized: normalize(response.data.data, [bookSchema]),
            });
            return response;
        })
        .catch(function (error) {
            dispatch({ operationId, type: PLAYER_BOOK_ERROR, error: error.toObject() });
            return Promise.reject(error);
        });
};

// export const createPlayerPainting = (playerId, values) => (dispatch) => {
//     dispatch({ type: CREATE_PLAYER_BOOK });
//     return axiosInstance
//         .post(`/players/${playerId}/paintings`, objectToFormData(values))
//         .then(function (response) {
//             const normalized = normalize(response.data.data, paintingSchema);
//             dispatch({
//                 type: CREATE_PLAYER_BOOK_SUCCESS,
//                 normalized,
//                 id: normalized.result,
//             });
//             return response;
//         })
//         .catch(function (error) {
//             dispatch({ type: CREATE_PLAYER_BOOK_ERROR, error: error.toObject() });
//             return Promise.reject(error);
//         });
// };

// export const replacePlayerPainting = (playerId, unityId, assets) => (dispatch) => {
//     dispatch({ type: REPLACE_PLAYER_BOOK });
//     return dispatchNormalizedPromise(
//         axiosInstance.put(`/players/${playerId}/unity_objects/${unityId}/assets`, assets),
//         [paintingSchema],
//         [REPLACE_PLAYER_BOOK_SUCCESS, REPLACE_PLAYER_BOOK_ERROR],
//         dispatch
//     );
// };
//
// export const sortPlayerObjectAsset = (playerId, unityId, { movedId, newPosition, newOrder }) => (dispatch) => {
//     dispatch({
//         type: SORT_PLAYER_OBJECT_ASSET,
//         data: {
//             newOrder,
//         },
//     });
//
//     return axiosInstance
//         .put(`/players/${playerId}/unity_objects/${unityId}/assets/${movedId}`, { position: newPosition })
//         .then(function (response) {
//             dispatch({
//                 type: SORT_PLAYER_OBJECT_ASSET_SUCCESS,
//             });
//             return response;
//         })
//         .catch(function (error) {
//             dispatch({ type: SORT_PLAYER_OBJECT_ASSET_ERROR, error: error.toObject() });
//             return Promise.reject(error);
//         });
// };
//
// export const deletePlayerObjectAsset = (playerId, unityId, id) => (dispatch) => {
//     dispatch({
//         type: DELETE_PLAYER_OBJECT_ASSET,
//         id,
//     });
//     return axiosInstance
//         .delete(`/players/${playerId}/unity_objects/${unityId}/assets/${id}`)
//         .then(function (response) {
//             dispatch({
//                 type: DELETE_PLAYER_OBJECT_ASSET_SUCCESS,
//                 id,
//             });
//             return response;
//         })
//         .catch(function (error) {
//             dispatch({ type: DELETE_PLAYER_OBJECT_ASSET_ERROR, id, error: error.toObject() });
//             return Promise.reject(error);
//         });
// };
//
// export const updatePlayerObjectAsset = (playerId, unityId, id, values) => (dispatch) => {
//     dispatch({
//         type: UPDATE_PLAYER_OBJECT_ASSET,
//         id,
//     });
//     return dispatchNormalizedPromise(
//         axiosInstance.put(`/players/${playerId}/unity_objects/${unityId}/assets/${id}`, values),
//         paintingSchema,
//         [UPDATE_PLAYER_OBJECT_ASSET_SUCCESS, UPDATE_PLAYER_OBJECT_ASSET_ERROR],
//         dispatch
//     );
// };

export default combineReducers({
    books: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('books'),
        defaultStrategy: Strategies.merge,
    }),
    playerAssets: createRequestReducer(
        [PLAYER_BOOK, PLAYER_BOOK_SUCCESS, PLAYER_BOOK_ERROR],
        { ignoreData: true, useOperationId: true }
    ),
    all: createRequestReducer([PLAYER_BOOK, PLAYER_BOOK_SUCCESS, PLAYER_BOOK_ERROR], {
        mapResponse: NormalizedActionMap.result,
    }),
    playerAssetsSort: (
        state = {
            current: null,
            optimistic: null,
            isUpdating: false,
        },
        action
    ) => {
        const getOrdered = (entitiesByKey) => {
            return values(map(sortBy(values(entitiesByKey || {}), 'position'), 'id'));
        };

        switch (action.type) {
            case PLAYER_BOOK: {
                return {
                    current: [],
                    optimistic: null,
                    isUpdating: false,
                };
            }
            case PLAYER_BOOK_SUCCESS: {
                return {
                    current: getOrdered(action.normalized.entities.assets),
                    optimistic: null,
                    isUpdating: false,
                };
            }
            case CREATE_PLAYER_BOOK_SUCCESS: {
                return {
                    current: concat(state.current, action.id),
                    optimistic: null,
                    isUpdating: false,
                };
            }
            // case REPLACE_PLAYER_BOOK_SUCCESS: {
            //     return {
            //         current: action.normalized.result,
            //         optimistic: null,
            //         isUpdating: false,
            //     };
            // }
            case DELETE_PLAYER_OBJECT_ASSET: {
                return {
                    ...state,
                    isUpdating: true,
                    optimistic: filter(state.current, (value) => value !== action.id),
                };
            }
            case SORT_PLAYER_OBJECT_ASSET: {
                return {
                    ...state,
                    isUpdating: true,
                    optimistic: action.data.newOrder,
                };
            }
            //COMMIT
            case DELETE_PLAYER_OBJECT_ASSET_SUCCESS:
            case SORT_PLAYER_OBJECT_ASSET_SUCCESS: {
                return {
                    ...state,
                    current: state.optimistic || state.current,
                    isUpdating: false,
                    optimistic: null,
                };
            }
            //REVERT
            case DELETE_PLAYER_OBJECT_ASSET_ERROR:
            case SORT_PLAYER_OBJECT_ASSET_ERROR: {
                return {
                    ...state,
                    isUpdating: false,
                    optimistic: null,
                };
            }
        }

        return state;
    },
});
