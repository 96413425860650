import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { alertsSelector } from 'reducers/alerts';
import { Snackbar } from '@material-ui/core';

class Alerts extends React.Component {
    /**
     * Component state
     */
    state = {
        alert: null,
        open: false,
    };

    componentDidUpdate() {
        if (this.props.currentAlert) {
            if (!this.state.alert || this.state.alert.date !== this.props.currentAlert.date) {
                this.setState({ alert: this.props.currentAlert, open: true });
            }
        }
    }

    handleSnackbarClose = () => {
        this.setState({ open: false });
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { open, alert } = this.state;
        return (
            <Snackbar
                open={open}
                message={alert && alert.message}
                autoHideDuration={6000}
                onClose={this.handleSnackbarClose}
            />
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateTopProps = (state) => {
    return {
        currentAlert: alertsSelector.getCurrent(state),
    };
};

export default connect(mapStateTopProps)(Alerts);
