import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { fetchUserPlayer, playersSelector } from 'reducers/players';
import { matchPath, Route, withRouter } from 'react-router-dom';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { Button, Card, Tab, Tabs, Typography } from '@material-ui/core';
import PlayerAvatar from 'containers/players/PlayerAvatar';
import PlayerOverviewPage from 'containers/pages/player/PlayerOverviewPage';
import { push } from 'connected-react-router';
import PlayerStarButton from 'containers/players/PlayerStarButton';
import AssetsEditorLayout from 'containers/assets/AssetsEditorLayout';
import FullWidthLoader from 'ui/base/FullWidthLoader';
import { usersSelector } from 'reducers/users';
import GroupIcon from '@material-ui/icons/Group';
import PlayerShareModal from 'containers/players/PlayerShareModal';
import PlayerTransfertModal from 'containers/players/PlayerTransfertModal';
import PlayerSessionsList from 'containers/sessions/PlayerSessionsList';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'
import PlayerNextSessionPage from "containers/pages/player/PlayerNextSessionPage";

const styles = {
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 25,
    },
    header: {
        paddingBottom: 7,
    },
    tabs: {
        borderBottom: '1px solid #f0f0f0',
    },
    backButton: {
        marginRight: 10,
    },
    avatar: {
        marginRight: 10,
    },
};

class PlayerLayout extends React.Component {
    /**
     * Component state
     */
    state = {
        shareModalOpen: false,
        transfertModalOpen: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            currentUser: { id },
            match: { params },
        } = this.props;

        this.props.fetchPlayer(id, params.playerId);
    }

    componentDidUpdate(prevProps) {
        const {
            currentUser: { id },
            match: { params },
        } = prevProps;

        if (params.playerId !== this.props.match.params.playerId)
            this.props.fetchPlayer(id, this.props.match.params.playerId);
    }

    getCurrentPage() {
        const match = matchPath(this.props.location.pathname, { path: '/player/:playerId/:page' });
        return match.params.page;
    }

    handleTabChange = (event, value) => {
        const { player } = this.props;
        this.props.redirect(`/player/${player.id}/${value}`);
    };

    renderHeader() {
        const { classes, player, currentUser, history } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.headerContainer}>
                        <PlayerAvatar className={classes.avatar} player={player} style={{ width: 60, height: 60 }} />
                        <Typography className={classes.header} type="title" component="div">
                            {player.nickname}
                        </Typography>
                        <div style={{ flex: 1 }} />
                        <Button variant="contained" color={'secondary'} onClick={this.handleShareClick}>
                            <GroupIcon /> {getTranslation("share")}
                        </Button>
                        <PlayerStarButton userPlayerId={player.related_user} style={{ marginLeft: 5 }} />
                        {/*{player.main_user_id === currentUser.id && (*/}
                        {/*    <Button color={'secondary'} style={{ marginLeft: 5 }} onClick={this.handleTransfertClick}>*/}
                        {/*        Handover*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    renderContent() {
        const { classes, player, currentUser } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Card>
                        <Tabs
                            className={classes.tabs}
                            onChange={this.handleTabChange}
                            value={this.getCurrentPage()}
                            indicatorColor="primary"
                            textColor="primary"
                            centered>
                            <Tab label={getTranslation("player_layout_update_profile")} value="update-profile" />
                            {/*  <Tab label={getTranslation("player_layout_next_session")} value="update-session" /> */}
                            <Tab label={getTranslation("player_layout_add_content")} value="assets" />
                            <Tab label={getTranslation("player_layout_view_session")} value="sessions" />
                        </Tabs>
                        <div>
                            <Route
                                path={`/player/:playerId/update-profile`}
                                render={() => <PlayerOverviewPage player={player} />}
                            />
                            <Route
                                path={`/player/:playerId/update-session`}
                                render={() => <PlayerNextSessionPage player={player} />}
                            />
                            <Route
                                exact
                                path={`/player/:playerId/assets`}
                                render={() => <AssetsEditorLayout player={player} />}
                            />
                            <Route
                                path={`/player/:playerId/assets/:objectId`}
                                render={({ match }) => <AssetsEditorLayout player={player} objectId={match.params.objectId} />}
                            />
                            <Route
                                path={`/player/:playerId/sessions`}
                                render={() => <PlayerSessionsList playerId={player.id} currentUserId={currentUser.id} />}
                            />
                        </div>
                    </Card>
                )}
            </LocaleConsumer>
        );
    }

    handleShareClick = () => {
        this.setState({ shareModalOpen: true });
    };

    handleShareClose = () => {
        this.setState({ shareModalOpen: false });
    };

    handleTransfertClick = () => {
        this.setState({ transfertModalOpen: true });
    };

    handleTransfertClose = () => {
        this.setState({ transfertModalOpen: false });
    };

    renderShareModal() {
        const { player } = this.props;
        return (
            <PlayerShareModal open={this.state.shareModalOpen} onRequestClose={this.handleShareClose} player={player} />
        );
    }

    renderTransfertModal() {
        const { player } = this.props;
        return (
            <PlayerTransfertModal
                open={this.state.transfertModalOpen}
                onRequestClose={this.handleTransfertClose}
                player={player}
            />
        );
    }

    /**
     * Render component
     */
    render() {
        const { isFetching } = this.props;

        return (
            <div>
                {!isFetching && this.renderShareModal()}
                {!isFetching && this.renderTransfertModal()}
                {isFetching && <FullWidthLoader />}
                {!isFetching && this.renderHeader()}
                {!isFetching && this.renderContent()}
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const playerSate = playersSelector.getCurrentPlayerState(state);
    return {
        currentUser: usersSelector.getCurrentUser(state),
        player: playersSelector.getCurrentPlayer(state),
        isFetching: RequestStateHelper.isLoading(playerSate),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (location) => dispatch(push(location)),
        fetchPlayer: (userId, playerId) => dispatch(fetchUserPlayer(userId, playerId)),
    };
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerLayout)));
