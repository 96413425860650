import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button, Card, IconButton, Typography } from '@material-ui/core';
import DefaultError from 'ui/base/DefaultError';
import { Col, Row } from 'react-flexbox-grid';
import CancelIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    form: {},
    title: {
        marginTop: 20,
    },
};

class PlayerStoryDisplayRForm extends React.Component {
    /**
     * Component state
     */
    state = {
        isEditing: null,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleEditClick = () => {
        const { reset } = this.props;
        reset();
        this.setState({ isEditing: true });
    };
    handleCancelClick = () => {
        const { reset } = this.props;
        reset();
        this.setState({ isEditing: false });
    };

    handleConfirmClick = (event) => {
        const { handleSubmit, pristine } = this.props;
        if (pristine) {
            this.setState({ isEditing: false });
        } else {
            handleSubmit && handleSubmit(event);
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.pristine === false && this.props.pristine === true) {
            this.setState({ isEditing: false });
        }
    }

    renderTextField(name, displayName, description, field, previewCallback) {
        const { initialValues } = this.props;
        const { isEditing } = this.state;
        previewCallback =
            previewCallback ||
            ((value) => (
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <Typography
                            onClick={this.handleEditClick}
                            style={{ wordBreak: 'break-word', whiteSpace: 'pre-line', cursor: 'pointer' }}
                            color={value ? 'default' : 'textSecondary'}>
                            {value || <i>{getTranslation("player_story_display_write_key_sentences")}</i>}
                        </Typography>
                    )}
                </LocaleConsumer>
            ));
        return (
            <div style={{ margin: 15 }}>
                <div style={{ paddingBottom: 15 }}>
                    <Typography>
                        <b>{displayName}</b>
                    </Typography>
                    <Typography color="textSecondary">{description}</Typography>
                </div>
                <Card style={{ padding: 15 }}>{isEditing ? field : previewCallback(initialValues[name])}</Card>
            </div>
        );
    }
    renderUpdate() {
        const { submitting } = this.props;
        const { isEditing } = this.state;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Row>
                        <Col xs />
                        <Col>
                            {isEditing ? (
                                <div>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        disabled={submitting}
                                        onClick={this.handleConfirmClick}>
                                        {getTranslation("save")}
                                    </Button>
                                    <IconButton disabled={submitting} onClick={this.handleCancelClick}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ) : (
                                <div>
                                    <IconButton disabled={submitting} onClick={this.handleEditClick}>
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, error, submitting } = this.props;
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <div className={classes.form}>
                            <DefaultError>{error}</DefaultError>
                            {this.renderUpdate()}
                            <Row>
                                <Col xs={12} lg={6}>
                                    {this.renderTextField(
                                        'life_story_family',
                                        getTranslation("player_story_display_family_life"),
                                        getTranslation("player_story_display_family_life_desc"),
                                        <Field
                                            type="text"
                                            name="life_story_family"
                                            label={getTranslation("player_story_display_family_life")}
                                            disabled={submitting}
                                            component={ValidatedTextField}
                                            multiline
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Col>
                                <Col xs={12} lg={6}>
                                    {this.renderTextField(
                                        'life_story_working',
                                        getTranslation("player_story_display_working_life"),
                                        getTranslation("player_story_display_working_life_desc"),
                                        <Field
                                            type="text"
                                            name="life_story_working"
                                            label={getTranslation("player_story_display_working_life")}
                                            disabled={submitting}
                                            component={ValidatedTextField}
                                            multiline
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Col>
                                <Col xs={12} lg={6}>
                                    {this.renderTextField(
                                        'life_story_community',
                                        getTranslation("player_story_display_community_life"),
                                        getTranslation("player_story_display_community_life_desc"),
                                        <Field
                                            type="text"
                                            name="life_story_community"
                                            label={getTranslation("player_story_display_community_life")}
                                            disabled={submitting}
                                            component={ValidatedTextField}
                                            multiline
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Col>
                                <Col xs={12} lg={6}>
                                    {this.renderTextField(
                                        'life_story_pleasures',
                                        getTranslation("player_story_display_pleasure_dislikes"),
                                        getTranslation("player_story_display_pleasure_dislikes_desc"),
                                        <Field
                                            type="text"
                                            name="life_story_pleasures"
                                            label={getTranslation("player_story_display_pleasure_dislikes")}
                                            disabled={submitting}
                                            component={ValidatedTextField}
                                            multiline
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Col>
                                <Col xs={12} lg={6}>
                                    {this.renderTextField(
                                        'life_story_beliefs',
                                        getTranslation("player_story_display_beliefs_values"),
                                        getTranslation("player_story_display_beliefs_values_desc"),
                                        <Field
                                            type="text"
                                            name="life_story_beliefs"
                                            label={getTranslation("player_story_display_beliefs_values")}
                                            disabled={submitting}
                                            component={ValidatedTextField}
                                            multiline
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Col>
                                <Col xs={12} lg={6}>
                                    {this.renderTextField(
                                        'life_story_medias',
                                        getTranslation("player_story_display_medias"),
                                        getTranslation("player_story_display_medias_desc"),
                                        <Field
                                            type="text"
                                            name="life_story_medias"
                                            label={getTranslation("player_story_display_medias")}
                                            disabled={submitting}
                                            component={ValidatedTextField}
                                            multiline
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
        paymentForm: false,
    };
}

export default withStyles(styles)(PlayerStoryDisplayRForm);
