import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { sharePlayer } from 'reducers/players';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import DefaultError from 'ui/base/DefaultError';
import { displayAlert } from 'reducers/alerts';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {
    dialog: {
        width: '80%',
    },
};

class PlayerShareModal extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleRequestClose = () => {
        this.props.reset();
        this.props.onRequestClose();
    };

    render() {
        const { classes, open, handleSubmit, submitting, error, player, ...other } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Dialog
                        onClose={this.handleRequestClose}
                        open={open}
                        classes={{
                            paper: classes.dialog,
                        }}>
                        <DialogTitle>{getTranslation("player_share_title")}</DialogTitle>
                        <DialogContent>
                            <Typography>
                                {getTranslation("player_share_desc", player.display_name)}
                            </Typography>
                            {error && <DefaultError>{error}</DefaultError>}
                            <br />
                            <Field
                                type="email"
                                name="email"
                                label={getTranslation("email")}
                                component={ValidatedTextField}
                                autoFocus
                                required
                                fullWidth
                            />
                            <br />
                            <br />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} />
                                <Button onClick={this.handleRequestClose} disabled={submitting}>
                                    {getTranslation("cancel")}
                                </Button>
                                <Button color="primary" onClick={handleSubmit} disabled={submitting} style={{ marginLeft: 5 }}>
                                    {getTranslation("send")}
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(
    reduxForm({
        form: 'sharePlayer',
        onSubmit(values, dispatch, props) {
            return dispatch(sharePlayer(values, props.player.id))
                .then((result) => {
                    dispatch(
                        displayAlert(`An email to share ${props.player.display_name} has been sent to ${values.email}`)
                    );
                    props.onRequestClose();
                    props.reset();
                    return result;
                })
                .catch((error) => {
                    throw new SubmissionError(validationErrorToSubmissionError(error));
                });
        },
    })(PlayerShareModal)
);
