import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';

const styles = {
    input: {
        display: 'none',
    },
};

class UploadButton extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleChange = (event) => {
        this.props.onChange && this.props.onChange(event, event.target.files);
    };

    /**
     * Render component
     */
    render() {
        const { classes, inputProps, ...otherProps } = this.props;

        return (
            <div>
                <input {...inputProps} onChange={this.handleChange} className={classes.input} id="file" type="file" />
                <label htmlFor="file">
                    <Button {...otherProps} component="span" />
                </label>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(UploadButton);
