import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Card, CardContent, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateUser, usersSelector } from 'reducers/users';

const styles = {
    row: {
        height: '100%',
    },
    cardContainer: {
        padding: 15,
    },
    cardRoot: {
        padding: 20,
        '&:last-child': {
            paddingBottom: 15,
        },
    },
};

class IntroPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleSkipClick = (event) => {
        event.preventDefault();
        this.props.skip(this.props.user.id);
    };

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <Row className={classes.row} center="xs" middle="xs">
                <Col xs={12} sm={8} lg={6}>
                    <div className={classes.cardContainer}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardContent>
                                <Typography>
                                    <a href="/" onClick={this.handleSkipClick} className="link">
                                        Skip intro
                                    </a>
                                </Typography>
                            </CardContent>
                            <CardContent>
                                <div className="embed-container">
                                    <iframe
                                        style={{ width: '100%' }}
                                        src="https://player.vimeo.com/video/241462316?title=0&byline=0&portrait=0"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        skip: (id) => {
            dispatch(
                updateUser({
                    id,
                    is_intro_viewed: true,
                })
            );
        },
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(IntroPage));
