import axiosInstance from 'utils/axiosInstance';
import { combineReducers } from 'redux';
import AuthTokenStorage from 'utils/AuthTokenStorage';
import createRequestReducer, {
    dispatchNormalizedPromise,
    NormalizedActionMap,
    NormalizedSelector,
    responseDataMap,
    Strategies,
} from 'reducers/createRequestReducer';
import user from 'reducers/schemas/user';

export const usersSelector = {
    getCurrentUser: (state, parameterisedContain) =>
        NormalizedSelector.getById(state.users.users, state.users.currentUserId, parameterisedContain),
    getCurrentUserState: (state) => state.users.currentUser,
    getUser: (state, userId) => NormalizedSelector.getById(state.users.users, userId),
    getUserUpdateSate: (state) => state.users.userUpdate,
    getUserDeleteSate: (state) => state.users.userDelete,
    getInsitutionUserRemoveSate: (state) => state.users.institutionUserRemove,
    isCurrentUserInitialized: (state) => state.users.currentUserInitialized,
    isCurrentUserAuthenticated: (state) => !!state.users.currentUserId,

    getCurrentPlayerUsers: (state) => NormalizedSelector.getByIds(state.users.users, state.users.playerUsers),
    getCurrentPlayerUsersState: (state) => state.users.playerUsers,

    getInstitutionUsers: (state, parameterizedContain) =>
        NormalizedSelector.getByIds(state.users.users, state.users.institutionUsers, parameterizedContain),

    getInstitutionUsersState: (state) => state.users.institutionUsers,
    getInstitutionUsersWithStats: (state, parameterizedContain) =>
        NormalizedSelector.getByIds(state.users.users, state.users.institutionUsers, parameterizedContain),
    getConfirmNewEmailState: (state) => state.users.confirmNewEmail,
};

const USER_INITIALIZE_CURRENT = 'USER_INITIALIZE_CURRENT';
const USER_INITIALIZE_CURRENT_SUCCESS = 'USER_INITIALIZE_CURRENT_SUCCESS';
const USER_INITIALIZE_CURRENT_ERROR = 'USER_INITIALIZE_CURRENT_ERROR';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';

const USER_JOIN = 'USER_JOIN';
const USER_JOIN_SUCCESS = 'USER_JOIN_SUCCESS';
const USER_JOIN_ERROR = 'USER_JOIN_ERROR';

const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR';

const FETCH_PLAYER_USERS = 'FETCH_PLAYER_USERS';
const FETCH_PLAYER_USERS_SUCCESS = 'FETCH_PLAYER_USERS_SUCCESS';
const FETCH_PLAYER_USERS_ERROR = 'FETCH_PLAYER_USERS_ERROR';

const FETCH_INSTITUTION_USERS = 'FETCH_INSTITUTION_USERS';
const FETCH_INSTITUTION_USERS_SUCCESS = 'FETCH_INSTITUTION_USERS_SUCCESS';
const FETCH_INSTITUTION_USERS_ERROR = 'FETCH_INSTITUTION_USERS_ERROR';

const FETCH_INSTITUTION_USERS_WITH_STATS = 'FETCH_INSTITUTION_USERS_WITH_STATS';
const FETCH_INSTITUTION_USERS_WITH_STATS_SUCCESS = 'FETCH_INSTITUTION_USERS_WITH_STATS_SUCCESS';
const FETCH_INSTITUTION_USERS_WITH_STATS_ERROR = 'FETCH_INSTITUTION_USERS_WITH_STATS_ERROR';

const ADD_INSTITUTION_USER = 'ADD_INSTITUTION_USER';
export const ADD_INSTITUTION_USER_SUCCESS = 'ADD_INSTITUTION_USER_SUCCESS';
const ADD_INSTITUTION_USER_ERROR = 'ADD_INSTITUTION_USER_ERROR';

const REMOVE_INSTITUTION_USER = 'REMOVE_INSTITUTION_USER';
export const REMOVE_INSTITUTION_USER_SUCCESS = 'REMOVE_INSTITUTION_USER_SUCCESS';
const REMOVE_INSTITUTION_USER_ERROR = 'REMOVE_INSTITUTION_USER_ERROR';

const USER_UPDATE = 'USER_UPDATE';
const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

const DELETE_CURRENT_USER = 'DELETE_CURRENT_USER';
export const DELETE_CURRENT_USER_SUCCESS = 'DELETE_CURRENT_USER_SUCCESS';
const DELETE_CURRENT_USER_ERROR = 'DELETE_CURRENT_USER_ERROR';

const USER_ASK_PASSWORD = 'USER_ASK_PASSWORD';
const USER_ASK_PASSWORD_SUCCESS = 'USER_ASK_PASSWORD_SUCCESS';
const USER_ASK_PASSWORD_ERROR = 'USER_ASK_PASSWORD_ERROR';

const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';

const USER_CONFIRM_NEW_EMAIL = 'USER_CONFIRM_NEW_EMAIL';
export const USER_CONFIRM_NEW_EMAIL_SUCCESS = 'USER_CONFIRM_NEW_EMAIL_SUCCESS';
const USER_CONFIRM_NEW_EMAIL_ERROR = 'USER_CONFIRM_NEW_EMAIL_ERROR';

export const USER_LOGOUT = 'USER_LOGOUT';

export const initializeUser = (userId) => (dispatch) => {
    if (!userId) {
        dispatch({ type: USER_INITIALIZE_CURRENT_ERROR });
        return Promise.reject();
    }

    dispatch({ type: USER_INITIALIZE_CURRENT });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/users/${userId}`),
        user,
        [USER_INITIALIZE_CURRENT_SUCCESS, USER_INITIALIZE_CURRENT_ERROR],
        dispatch
    );
};

export const getUser = (userId, parameterizedContain) => (dispatch) => {
    dispatch({ type: FETCH_CURRENT_USER });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/users/${userId}`, {
            params: {
                parameterizedContain,
            },
        }),
        user,
        [FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_ERROR],
        dispatch,
        {},
        parameterizedContain
    );
};

export const updateUser = (data, userId) => (dispatch) => {
    userId = userId || data.id;

    dispatch({ type: USER_UPDATE });
    return dispatchNormalizedPromise(
        axiosInstance.put(`/users/${userId}`, data),
        user,
        [USER_UPDATE_SUCCESS, USER_UPDATE_ERROR],
        dispatch
    );
};

export const deleteUser = (userId) => (dispatch) => {
    dispatch({ type: DELETE_CURRENT_USER });
    return dispatchNormalizedPromise(
        axiosInstance.delete(`/users/${userId}`),
        null,
        [DELETE_CURRENT_USER_SUCCESS, DELETE_CURRENT_USER_ERROR],
        dispatch
    );
};

export const login = (data) => (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    return axiosInstance
        .post('/auth', data)
        .then(function (response) {
            const data = responseDataMap(response);

            AuthTokenStorage.set(data.id, data.token);

            dispatch({ type: LOGIN_SUCCESS, response });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: LOGIN_ERROR, error: error.getData() });
            return Promise.reject(error);
        });
};

export const resendConfirmation = (email) => (dispatch) => {
    return axiosInstance.post('/auth/resend-confirmation', { email }).catch(function (error) {});
};

export const userJoin = (user) => (dispatch) => {
    dispatch({ type: USER_JOIN });

    return axiosInstance
        .post(`/users`, user)
        .then(function (response) {
            dispatch({ type: USER_JOIN_SUCCESS, response });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: USER_JOIN_ERROR, error: error.getData() });
            return Promise.reject(error);
        });
};

export const askResetPassword = (values) => (dispatch) => {
    dispatch({ type: USER_ASK_PASSWORD });

    return axiosInstance
        .post(`users/reset_password`, values)
        .then(function (response) {
            response = response.data;
            dispatch({ type: USER_ASK_PASSWORD_SUCCESS, response });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: USER_ASK_PASSWORD_ERROR, error: error.getData() });
            return Promise.reject(error);
        });
};

export const resetPassword = (id, key, values) => (dispatch) => {
    dispatch({ type: USER_RESET_PASSWORD });

    return axiosInstance
        .post(`/users/${id}/reset_password/${key}`, values)
        .then(function (response) {
            response = response.data;
            dispatch({ type: USER_RESET_PASSWORD_SUCCESS, response });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: USER_RESET_PASSWORD_ERROR, error: error.getData() });
            return Promise.reject(error);
        });
};

export const confirmNewEmail = (id, key) => (dispatch) => {
    dispatch({ type: USER_CONFIRM_NEW_EMAIL });

    return axiosInstance
        .post(`/users/${id}/confirm_new_email/${key}`)
        .then(function (response) {
            response = response.data;
            dispatch({ type: USER_CONFIRM_NEW_EMAIL_SUCCESS, response });
            return response;
        })
        .catch(function (error) {
            dispatch({ type: USER_CONFIRM_NEW_EMAIL_ERROR, error: error.getData() });
            return Promise.reject(error);
        });
};

export const logout = () => (dispatch) => {
    AuthTokenStorage.remove();
    dispatch({ type: USER_LOGOUT });
};

export const fetchPlayerUsers = (playerId) => (dispatch) => {
    dispatch({ type: FETCH_PLAYER_USERS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/players/${playerId}/users`),
        [user],
        [FETCH_PLAYER_USERS_SUCCESS, FETCH_PLAYER_USERS_ERROR],
        dispatch
    );
};

export const fetchInstitutionUsers = (userId, parameterizedContain) => (dispatch) => {
    dispatch({ type: FETCH_INSTITUTION_USERS });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/institutions/${userId}/caregivers`, {
            params: {
                parameterizedContain,
            },
        }),
        [user],
        [FETCH_INSTITUTION_USERS_SUCCESS, FETCH_INSTITUTION_USERS_ERROR],
        dispatch,
        {},
        parameterizedContain
    );
};

export const fetchInstitutionUsersWithStats = (userId, parameterizedContain) => (dispatch) => {
    const operationId = Math.random();
    dispatch({ type: FETCH_INSTITUTION_USERS_WITH_STATS, operationId });
    return dispatchNormalizedPromise(
        axiosInstance.get(`/institutions/${userId}/caregivers`, {
            params: {
                parameterizedContain,
            },
        }),
        [user],
        [FETCH_INSTITUTION_USERS_WITH_STATS_SUCCESS, FETCH_INSTITUTION_USERS_WITH_STATS_ERROR],
        dispatch,
        { operationId },
        parameterizedContain
    );
};

export const addInstitutionCaregiver = (userId, caregiverEmail, sharedPlayers) => (dispatch) => {
    dispatch({ type: ADD_INSTITUTION_USER });
    return dispatchNormalizedPromise(
        axiosInstance.post(`/institutions/${userId}/caregivers`, {
            email: caregiverEmail,
            sharedPlayers: sharedPlayers,
        }),
        user,
        [ADD_INSTITUTION_USER_SUCCESS, ADD_INSTITUTION_USER_ERROR],
        dispatch
    );
};

export const removeInstitutionCaregiver = (institutionId, caregiverId) => (dispatch) => {
    dispatch({ type: REMOVE_INSTITUTION_USER });
    return dispatchNormalizedPromise(
        axiosInstance.delete(`/institutions/${institutionId}/caregivers/${caregiverId}`),
        null,
        [REMOVE_INSTITUTION_USER_SUCCESS, REMOVE_INSTITUTION_USER_ERROR],
        dispatch
    );
};

export default combineReducers({
    users: createRequestReducer([null, '*', null], {
        mapResponse: NormalizedActionMap.entities('users'),
        defaultStrategy: Strategies.merge,
    }),
    playerUsers: createRequestReducer([FETCH_PLAYER_USERS, FETCH_PLAYER_USERS_SUCCESS, FETCH_PLAYER_USERS_ERROR], {
        mapResponse: NormalizedActionMap.result,
    }),
    institutionUsers: createRequestReducer(
        [
            [FETCH_INSTITUTION_USERS, FETCH_INSTITUTION_USERS_WITH_STATS],
            [FETCH_INSTITUTION_USERS_SUCCESS, FETCH_INSTITUTION_USERS_WITH_STATS_SUCCESS],
            [FETCH_INSTITUTION_USERS_ERROR, FETCH_INSTITUTION_USERS_WITH_STATS_ERROR],
        ],
        {
            mapResponse: NormalizedActionMap.result,
            useOperationId: true,
        }
    ),
    userUpdate: createRequestReducer([USER_UPDATE, USER_UPDATE_SUCCESS, USER_UPDATE_ERROR], { ignoreData: true }),
    currentUser: createRequestReducer([FETCH_CURRENT_USER, FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_ERROR], {
        ignoreData: true,
    }),
    institutionUserRemove: createRequestReducer(
        [REMOVE_INSTITUTION_USER, REMOVE_INSTITUTION_USER_SUCCESS, REMOVE_INSTITUTION_USER_ERROR],
        { ignoreData: true }
    ),
    userDelete: createRequestReducer([DELETE_CURRENT_USER, DELETE_CURRENT_USER_SUCCESS, DELETE_CURRENT_USER_ERROR], {
        ignoreData: true,
    }),
    confirmNewEmail: createRequestReducer(
        [USER_CONFIRM_NEW_EMAIL, USER_CONFIRM_NEW_EMAIL_SUCCESS, USER_CONFIRM_NEW_EMAIL_ERROR],
        { ignoreData: true }
    ),
    /**
     * The logged in state
     * @param state
     * @param action
     * @returns {*}
     */
    currentUserId: (state = null, action) => {
        const actions = [USER_INITIALIZE_CURRENT_SUCCESS];

        if (actions.indexOf(action.type) !== -1) {
            return NormalizedActionMap.result(action);
        }

        if (action.type === USER_LOGOUT) {
            return null;
        }

        return state;
    },
    /**
     * The need to initialize currentUser
     * @param state
     * @param action
     * @returns {boolean}
     */
    currentUserInitialized: (state = false, action) => {
        const actions = [USER_INITIALIZE_CURRENT_SUCCESS, USER_INITIALIZE_CURRENT_ERROR];

        if (actions.indexOf(action.type) !== -1) return true;

        if (action.type === LOGIN_SUCCESS) return false;

        return state;
    },
});
