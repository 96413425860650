import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button } from '@material-ui/core';
import {LocaleConsumer} from "containers/locale/LocaleProvider";

const styles = {
    form: {
        '& > *': {
            marginBottom: 20,
        },
    },
};

class InstitutionRForm extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }
    /**
     * Render component
     */
    render() {
        const { classes, buttonText, handleSubmit, submitting } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.form}>
                        <Field
                            type="text"
                            name="institution_name"
                            label={getTranslation("institution_form_name")}
                            component={ValidatedTextField}
                            autoFocus
                            required
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="institution_address"
                            label={getTranslation("institution_form_address")}
                            component={ValidatedTextField}
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="institution_state"
                            label={getTranslation("institution_form_state")}
                            component={ValidatedTextField}
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="institution_country"
                            label={getTranslation("institution_form_country")}
                            component={ValidatedTextField}
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="institution_city"
                            label={getTranslation("institution_form_city")}
                            component={ValidatedTextField}
                            fullWidth
                        />
                        <Field
                            type="text"
                            name="institution_zipcode"
                            label={getTranslation("institution_form_zipcode")}
                            component={ValidatedTextField}
                            fullWidth
                        />
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} />
                            <Button color="primary" onClick={handleSubmit} disabled={submitting}>
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {
        buttonText: 'Update',
    };
}

InstitutionRForm = withStyles(styles)(InstitutionRForm);

InstitutionRForm.validate = (values) => {
    const errors = {};

    if (!values.institution_name) {
        errors.institution_name = 'Required';
    }

    return errors;
};

export default InstitutionRForm;
