/*
Authors: Niklaas Cotta
Last Modified: 9/18/2023
Description: Container to handle music track updates
*/

import React from 'react';
import { replacePlayerObjectAsset } from 'reducers/assets';
import { connect } from 'react-redux';
import MusicTrackSelect from "./MusicTrackSelect";

function MusicTrackUpdateContainer(
    {
        initialMusicTrackIds,
        replacePlayerObjectAsset,
        player: {id: playerId },
        object: {unity_id: unityId}
    }
) { 
    const handleConfirm = (assets) => {
        const newAssets = assets.map((asset) => ({ type: 'music_track', music_track_id: asset.music_track_id, music_location: asset.music_location }));
        replacePlayerObjectAsset(playerId, unityId, newAssets);
    };
    return <MusicTrackSelect playerId={playerId} initialMusicTrackIds={initialMusicTrackIds} onConfirm={handleConfirm} />
}



MusicTrackUpdateContainer.propTypes = {};
MusicTrackUpdateContainer.defaultProps = {};

const mapStateToProps = (dispatch) => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        replacePlayerObjectAsset: (playerId, unityId, assets) => {
            dispatch(replacePlayerObjectAsset(playerId, unityId, assets));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MusicTrackUpdateContainer);