import React from 'react';
import { usersSelector } from 'reducers/users';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Redirect } from 'react-router';

function InstitutionRedirect(props) {
    if (props.location.pathname === '/' && props.user.is_institution) return <Redirect to={'/institution/players'} />;
    return null;
}

InstitutionRedirect.propTypes = {};
InstitutionRedirect.defaultProps = {};

const mapStateTopProps = (state) => {
    return {
        user: usersSelector.getCurrentUser(state),
    };
};

export default compose(withRouter, connect(mapStateTopProps))(InstitutionRedirect);
