/* 
Authors: Gaetan Senelle, Niklaas Cotta
Last modified: 9/22/2023
Description: main index that conbines all reducers for app
*/

import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { connectRouter } from 'connected-react-router';
import usersReducer, { USER_LOGOUT } from 'reducers/users';
import playersReducer from 'reducers/players';
import objectsReducer from 'reducers/objects';
import assetsReducer from 'reducers/assets';
import paintingsReducer from "reducers/paintings";
import musicTracksReducer from "reducers/musicTracks";
import booksReducer from "reducers/books";
import bookPagesReducer from "reducers/bookPages";
import alertsReducer from 'reducers/alerts';
import userPlayerReducer from 'reducers/userPlayers';
import playerTransfertsReducer from 'reducers/playerTransferts';
import { reducer as formReducer } from 'redux-form';
import epics from 'reducers/epics/epics';
import sessions from 'reducers/sessions';
import videoChannels from 'reducers/videoChannels';
import videoLinks from 'reducers/videoLinks';
import languagesReducer from 'reducers/languages';

const appReducer = combineReducers({
    router: connectRouter(history),
    users: usersReducer,
    alerts: alertsReducer,
    players: playersReducer,
    objects: objectsReducer,
    assets: assetsReducer,
    paintings: paintingsReducer,
    musicTracks: musicTracksReducer,
    books: booksReducer,
    bookPages: bookPagesReducer,
    languages: languagesReducer,
    userPlayers: userPlayerReducer,
    playerTransferts: playerTransfertsReducer,
    sessions: sessions,
    videoChannels: videoChannels,
    videoLinks,
    form: formReducer,
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        const { router } = state;
        state = { router };
    }
    return appReducer(state, action);
};

export const rootEpic = combineEpics(epics);

export default rootReducer;