import React from 'react';

function SessionDuration(props) {
    let duration;
    if (props.duration) {
        duration = Math.round(props.duration / 60);
    } else {
        const start = new Date(props.start),
            end = new Date(props.end);
        duration = Math.round((end - start) / 1000 / 60);
    }

    return <span>{duration}</span>;
}

SessionDuration.propTypes = {};

SessionDuration.defaultProps = {};
export default SessionDuration;
