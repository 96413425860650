import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import MentiaLogo from 'images/MentiaLogo2024.jpg';
import { 
    Button, 
    Card, CardActions, CardContent, 
    Typography 
} from '@material-ui/core';
import { LocaleConsumer } from 'containers/locale/LocaleProvider';
import withstyles from '@material-ui/core/styles/withStyles';

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};


function ThankYouPasswordPage({ classes }) {
	return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Row className={classes.row} center="xs" middle="xs">
                    <Col xs={12} sm={6} lg={4}>
                        <div className={classes.cardContainer}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent>
                                    <Typography align="center">
                                        <img src={MentiaLogo} height={40} alt="" style={{ marginTop: 20, marginBottom: 30 }} />
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                        {getTranslation("thankyou_password")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </Col>
                </Row>
            )}
        </LocaleConsumer>
    );
}

export default withstyles(styles)(ThankYouPasswordPage);