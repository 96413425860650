import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UploadButton from 'ui/base/UploadButton';
import { connect } from 'react-redux';
import { playersSelector, updatePlayer, updatePlayerPhoto } from 'reducers/players';
import { RequestStateHelper } from 'reducers/createRequestReducer';
import { Button, CircularProgress } from '@material-ui/core';
import DefaultError from 'ui/base/DefaultError';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import ResizePhotoModal from 'ui/base/ResizePhotoModal';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    display: 'flex',
    alignItems: 'center',
};

class PlayerPhotoEditor extends React.Component {
    /**
     * Component state
     */
    state = {
        resizing: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handlePhotoChange = (event, fileList) => {
        if (fileList[0]) {
            this.props.upload(fileList[0], this.props.player.id);
        }
    };

    handleResizeClick = (event) => {
        this.setState({ resizing: true });
    };

    handleResizeClose = (event) => {
        this.setState({ resizing: false });
    };

    handleResizeSave = (crop) => {
        const { id } = this.props.player;
        this.props.update({ id, photo_crop: crop });
    };

    /**
     * Render component
     */
    render() {
        const { classes, isUpdating, error, player } = this.props;
        let errors = {};
        if (error && error.exception === 'ValidationException') {
            errors = validationErrorToSubmissionError(error.data);
        }

        if (isUpdating) return <CircularProgress size={36} />;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.container}>
                        <ResizePhotoModal
                            src={player.original_photo_url}
                            initialCrop={{ ...player.photo_crop, aspect: 1 }}
                            open={this.state.resizing}
                            onSave={this.handleResizeSave}
                            onRequestClose={this.handleResizeClose}
                        />
                        <div>
                            <UploadButton onChange={this.handlePhotoChange}>{getTranslation("player_photo_editor_change")}</UploadButton>
                            {player.photo && <Button onClick={this.handleResizeClick}>{getTranslation("player_photo_editor_edit")}</Button>}
                        </div>
                        <DefaultError>{errors['photo']}</DefaultError>
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state) => {
    const playerUpdate = playersSelector.getCurrentPlayerPhotoState(state);

    return {
        isUpdating: RequestStateHelper.isLoading(playerUpdate),
        error: RequestStateHelper.getError(playerUpdate),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        upload: (file, id) => dispatch(updatePlayerPhoto(file, id)),
        update: (data) => dispatch(updatePlayer(data)),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PlayerPhotoEditor));
