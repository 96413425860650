import { get, isObject, merge, castArray, isArray, without, has, forEach, mapValues, mapKeys } from 'lodash-es';
import { normalize } from 'normalizr';

/**
 * Helpers
 */

export const RequestStateHelper = {
    isLoading(state) {
        if (state.ignoreData) return state.loading;
        return !state.response || state.loading;
    },
    getData(state) {
        return state.response;
    },
    getError(state) {
        return state.error;
    },
    getErrorMessage(state) {
        return get(state, 'error.message');
    },
};

export const Strategies = {
    default: (data, nextData) => nextData,
    merge: (data, nextData) => {
        if (data === null) return nextData;
        if (isObject(data) && isObject(nextData)) {
            return merge({}, data, nextData);
        }
        return nextData;
    },
    append: (data, nextData) => {
        nextData = castArray(nextData);
        if (!data) return nextData;
        if (!isArray(data)) throw new Error(`Strategies.append : previous data is not an array.`);

        return data.concat(nextData);
    },
    remove: (data, nextData) => {
        nextData = castArray(nextData);
        return without(data, ...nextData);
    },
};

export const NormalizedActionMap = {
    result: (action) => action.normalized.result,
    entities: (name) => (action) => {
        if (!action.normalized) return {};
        return action.normalized.entities[name] || {};
    },
};

export const NormalizedSelector = {
    getByIds: ({ response: entities }, stateOrIds, parameterizedFields = {}) => {
        if (has(stateOrIds, 'response')) stateOrIds = stateOrIds.response;
        return stateOrIds
            ? replaceParameterizedFields(
                  stateOrIds.map((id) => entities[id]),
                  parameterizedFields
              )
            : [];
    },
    getById: ({ response: entities }, stateOrId, parameterizedFields = {}) => {
        if (has(stateOrId, 'response')) stateOrId = stateOrId.response;
        return stateOrId ? replaceParameterizedFields(entities[stateOrId], parameterizedFields) : null;
    },
};

const replaceParameterizedFields = (valueOrArray, parameterizedFields) => {
    const values = castArray(valueOrArray);
    const results = values.map((entity) => {
        let result = entity;
        forEach(parameterizedFields, (params, key) => {
            const stringifiedParam = stringifyParams(key, params);
            result = {
                ...result,
                [key]: result[stringifiedParam] || null,
            };
        });
        return result;
    });
    return isArray(valueOrArray) ? results : results[0];
};

const stringifyParams = (key, argsObject = {}) => {
    return `${key}:${argsObject.contain || key}(${JSON.stringify(argsObject)})`;
};

const applyParametersToNormalized = (schema, normalized, parameterizedFields = {}) => {
    if (!schema || !normalized) return normalized;
    const schemaKey = isArray(schema) ? schema[0].key : schema.key;
    if (normalized && get(normalized, ['entities', schemaKey])) {
        normalized.entities[schemaKey] = mapValues(normalized.entities[schemaKey], (entity) => {
            return mapKeys(entity, (value, key) => {
                if (parameterizedFields[key]) {
                    return stringifyParams(key, parameterizedFields[key]);
                }
                return key;
            });
        });
    }
    return normalized;
};

export const dispatchNormalizedPromise = (promise, schema, actions, dispatch, other, parameterizedFields = {}) => {
    return promise
        .then(function (response) {
            const data = responseDataMap(response);
            const normalized = schema && data && normalize(data, schema);
            setTimeout(() => {
                dispatch({
                    type: actions[0],
                    normalized: applyParametersToNormalized(schema, normalized, parameterizedFields),
                    ...(other || {}),
                });
            });
            return response;
        })
        .catch(function (error) {
            setTimeout(() => {
                dispatch({
                    type: actions[1],
                    error: error.toObject(),
                    ...(other || {}),
                });
            });
            return Promise.reject(error);
        });
};

export const responseDataMap = (response) => get(response, 'data.data');

/**
 *  Internal utils
 */

const defaultConfig = {
    ignoreData: false,
    mapResponse: (action) => responseDataMap(action.response),
    mapError: (action) => action.error,
    defaultStrategy: Strategies.default,
};

const _isAction = (name, nameOrArray) => {
    /**
     * Dans le cas d'une action simple
     */
    if (name === nameOrArray || nameOrArray === '*') return {};

    /**
     * Dans le cas d'un array d'actions ou avec
     * des objets
     */
    if (isArray(nameOrArray)) {
        for (let nameOrObject of nameOrArray) {
            if (nameOrObject === name || nameOrObject === '*') return {};

            if (isObject(nameOrObject) && nameOrObject.type === name) return nameOrObject;
        }
    }

    return null;
};

const _getStrategy = (config, actionParams) => {
    if (actionParams.strategy) return actionParams.strategy;
    return config.defaultStrategy;
};

const _getTransform = (actionParams) => {
    if (actionParams.transform) return actionParams.transform;
    return (data) => data;
};

const _getMapResponse = (config, actionParams) => {
    if (actionParams.mapResponse) return actionParams.mapResponse;
    return config.mapResponse;
};

export default (filteredAction, config = {}) => {
    config = { ...defaultConfig, ...config };

    let defaultState = {
        loading: false,
        error: null,
        response: null,
    };

    if (config.ignoreData) defaultState.ignoreData = true;

    return (state = defaultState, action) => {
        let actionParams;
        actionParams = _isAction(action.type, filteredAction[0]);

        if (actionParams) {
            return {
                ...state,
                operationId: action.operationId,
                loading: true,
                error: null,
                // response: null (Allows hidden loading)
            };
        }

        const shouldIgnore = action.operationId !== state.operationId && config.useOperationId;
        actionParams = _isAction(action.type, filteredAction[1]);
        if (actionParams) {
            const strategy = _getStrategy(config, actionParams);
            const transform = _getTransform(actionParams);
            const mapResponse = _getMapResponse(config, actionParams);

            return !shouldIgnore
                ? {
                      ...state,
                      loading: false,
                      error: null,
                      response: config.ignoreData ? null : strategy(state.response, transform(mapResponse(action))),
                  }
                : state;
        }

        actionParams = _isAction(action.type, filteredAction[2]);
        if (actionParams) {
            return !shouldIgnore
                ? {
                      ...state,
                      loading: false,
                      error: config.mapError(action),
                      response: null,
                  }
                : state;
        }
        return state;
    };
};
